import React from 'react';

import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Strings } from '../../../../languages/en';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import Partners from '../../../components/partners/partners';
import ListCard from '../../../components/list-card/list-card';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/wireframing-cuate.png`;
const respCol = `${SAPPS_WEB_CDN_FILES_URL}images/responsibility-col.png`;
const datapCol = `${SAPPS_WEB_CDN_FILES_URL}images/data-col.png`;
const userExpCol = `${SAPPS_WEB_CDN_FILES_URL}images/user-experience-col.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/experience`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Solutions | Experience | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Solutions"
            subheading=""
            heading="Experience"
            content="Make your products and services elegant and intuitive by designing them for the user."
            innerImg={bannerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    A memorable user experience not only simplifies the usage
                    but also exhibits the product’s personality.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="6">
                      <p>
                        Today, offering tech solutions has become more than just
                        selling bundles of applications and services.
                        Ease-of-use and simplicity have become game-changing
                        concepts in Design. Organizations are increasingly
                        focusing on the user experience the same way they give
                        importance to the functionalities. The notion of better
                        products and services is never complete without a
                        cohesive user experience, as it not only simplifies the
                        usage of applications but also brings up their unique
                        personalities.
                      </p>
                    </Col>
                    <Col md="6">
                      <p>
                        Snyder Technologies offers you design solutions that
                        enhance your customers’ experience and help you stand
                        out in the business. Our approach toward design has
                        always been user-centric. This way we help you build
                        intuitive applications while creating brand consistency
                        throughout your products and services. As our solutions
                        allow you to build applications that create engagement,
                        they also help you to gain insights into the user
                        experience and constantly refine your process.
                      </p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={respCol}
                        cardTitle="Customer Engagement"
                        cardLink="/solutions/experience/customer-engagement-crm"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={datapCol}
                        cardTitle="Application Development"
                        cardLink="/solutions/experience/application-development"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={userExpCol}
                        cardTitle="User Experience"
                        cardLink="/solutions/experience/user-experience"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                  </Row>

                  <Partners />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
