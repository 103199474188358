import React, { Component } from 'react';
import './header.scss';
import Menu from '../menu/menu';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const logoWhite = `${SAPPS_WEB_CDN_FILES_URL}images/st-logo.svg`;
const logoDark = `${SAPPS_WEB_CDN_FILES_URL}images/logo-colored.svg`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HeaderProps {}

class Header extends Component {
  componentDidMount() {
    this.fixbar();
  }

  fixbar = () => {
    const shrinkHeader = 100;
    const header = document.querySelectorAll('.header')[0];
    window.addEventListener('scroll', (event) => {
      const scroll = getCurrentScroll();
      if (scroll >= shrinkHeader) {
        header.classList.add('shrink');
      } else {
        header.classList.remove('shrink');
      }
    });

    function getCurrentScroll() {
      return window.pageYOffset || document.documentElement.scrollTop;
    }
  };

  render() {
    return (
      <header className="header">
        <div className="container">
          <div className="header-wrap">
            <div className="logo-left">
              <h1>
                <a href="/">
                  <img
                    className="reg-logo"
                    src={logoDark}
                    alt="Snyder Technologies"
                  />
                  <img
                    className="home-logo"
                    src={logoWhite}
                    alt="Snyder Technologies"
                  />
                </a>
              </h1>
            </div>
            <Menu />
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
