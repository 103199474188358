import React, { Component } from 'react';
import './contact.scss';
import './modal-popup.scss';
import { Link } from 'gatsby';
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
  Form,
} from 'react-bootstrap';
import axios from 'axios';
import { validateForm } from '../../assets/js/custom-validation';

const { SAPPS_WEB_API_URL } = process.env;
const { SAPPS_WEB_MAILTO } = process.env;
const { SAPPS_WEB_MARKETING_URL } = process.env;
const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const usa = `${SAPPS_WEB_CDN_FILES_URL}images/usa.png`;
const pakistan = `${SAPPS_WEB_CDN_FILES_URL}images/pakistan.png`;
const indiaNorth = `${SAPPS_WEB_CDN_FILES_URL}images/india_north.png`;
const indiaSouth = `${SAPPS_WEB_CDN_FILES_URL}images/india_south.png`;
const stEmailLogoColored = `${SAPPS_WEB_CDN_FILES_URL}images/logo-email.png`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ContactProps {}

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalId: '',
      companyFirstName: '',
      companyLastName: '',
      companyName: '',
      companyEmail: '',
      companyPhoneNumber: '',
      companyCountry: '',
      companyMessage: '',
      mailSent: false,
      error: null,
      isValidated: false,
      generalName: '',
      generalCountry: '',
      generalPhone: '',
      generalEmail: '',
      generalMessage: '',
      generalMailSent: false,
      generalError: null,
      generalIsValidated: false,
    };
  }

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleShow = (id) => {
    this.setState({ isModalOpen: true, modalId: id as string });
  };

  handleProjectFormSubmit(event) {
    event.preventDefault();
    // Reset State
    this.setState({ error: null, mailSent: false });
    const { formEl } = this;
    const templateData = `
      <!doctype html>
      <html lang='en'>
      <head>
          <meta charset='UTF-8'><meta name='viewport' content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'>
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>New Project Enquiry</title>
          <style>
              .container {width: 100%; max-width: 1000px; margin: 0 auto; padding: 0 15px}
              table { width: 100%; }
              .enrollingTemplate th p {margin: 0; text-align: left; font-weight: normal}
              .enrollingTemplate h2 {margin: 50px 0 25px}
              .enrollingTemplate tbody p {margin: 0; line-height: 23px}
              .enrollingTemplate tbody p+p {margin: 15px 0 0;}
              .enrollingTemplate ul {padding-left: 15px}
              .enrollingTemplate ul li {margin: 10px 0 0}
              .enrollingTemplate ul li ul {padding-left: 25px; list-style: disc}
              .enrollingTemplate ul li ul li {margin: 5px 0 0}
              .d-flex {display: flex; margin: 50px 0 25px; width: 100%}
              .double-img img { margin: 15px; max-width: 40%; max-height: 150px }
              p { padding-bottom: 15px; }
              .vcolor{ color: #28b6ea; }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="enrollingTemplate">
                  <p>Dear Snyder Team,<br><br>You are receiving this because someone has posted a New Project Inquiry from the website. <a href="${SAPPS_WEB_MARKETING_URL}">${SAPPS_WEB_MARKETING_URL}</a></p>
                  <table border=1>
                      <tbody>
                          <tr><td><b>First Name*</b></td><td><span class="vcolor">[FirstName]</span></td></tr>
                          <tr><td><b>Last Name</b></td><td><span class="vcolor">[LastName]</span></td></tr>
                          <tr><td><b>Company Name*</b></td><td><span class="vcolor">[CompanyName]</span></td></tr>
                          <tr><td><b>Company Email Address*</b></td><td><span class="vcolor">[CompanyEmail]</span></td></tr>
                          <tr><td><b>Phone Number</b></td><td><span class="vcolor">[CompanyPhoneNumber]</span></td></tr>
                          <tr><td><b>Country</b></td><td><span class="vcolor">[CompanyCountry]</span></td></tr>
                          <tr><td colspan='2'><b>Tell us about your project*</b></td></tr>
                          <tr><td colspan='2'><span class="vcolor">[Message]</span></td></tr>
                      </tbody>
                  </table>
                  <p>Regards, Snyder Team<br><br><img src="${stEmailLogoColored}" width="15%" alt="Snyder Technologies"/></p>
              </div>
          </div>
      </body>
      </html>`;
    // If form validate
    if (validateForm(formEl)) {
      const html = templateData
        .replace('[FirstName]', this.state.companyFirstName)
        .replace('[LastName]', this.state.companyLastName)
        .replace('[CompanyName]', this.state.companyName)
        .replace('[CompanyEmail]', this.state.companyEmail)
        .replace('[CompanyPhoneNumber]', this.state.companyPhoneNumber)
        .replace('[CompanyCountry]', this.state.companyCountry)
        .replace('[Message]', this.state.companyMessage);

      const data = {
        eSAPPS_WEB_MAILTO: SAPPS_WEB_MAILTO,
        emailSubject: 'New Project Inquiry - Snyder Marketing Website',
        emailTemplate: html,
        parameterDetails: JSON.stringify({
          firstName: this.state.companyFirstName,
          lastName: this.state.companyLastName,
          country: this.state.companyCountry,
          companyName: this.state.companyName,
          phone: this.state.companyPhoneNumber,
          email: this.state.companyEmail,
          message: this.state.companyMessage,
          referralType: 2,
        }),
      };

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${SAPPS_WEB_API_URL}/Referral/SubmitTarget`,
          headers: { 'content-type': 'application/json' },
          data,
        })
          .then((result) => {
            if (result.data.message == 'Email has been sent successfully.') {
              this.setState({
                companyFirstName: '',
                companyLastName: '',
                companyName: '',
                companyEmail: '',
                companyPhoneNumber: '',
                companyCountry: '',
                companyMessage: '',
                mailSent: true,
                isValidated: false,
              });
              window.location.reload();
            } else {
              this.setState({ error: result.data.error });
            }
          })
          .catch((error) => {
            this.setState({ error: error.message });
          });
      });
    }

    this.setState({ isValidated: true });

    return false;
  }

  handleGeneralFormSubmit(event) {
    event.preventDefault();
    // Reset State
    this.setState({ generalError: null, generalMailSent: false });
    const { formE2 } = this;
    const templateData = `
      <!doctype html>
      <html lang='en'>
      <head>
          <meta charset='UTF-8'><meta name='viewport' content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'>
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>New Project Enquiry</title>
          <style>
              .container {width: 100%; max-width: 1000px; margin: 0 auto; padding: 0 15px}
              table { width: 100%; }
              .enrollingTemplate th p {margin: 0; text-align: left; font-weight: normal}
              .enrollingTemplate h2 {margin: 50px 0 25px}
              .enrollingTemplate tbody p {margin: 0; line-height: 23px}
              .enrollingTemplate tbody p+p {margin: 15px 0 0;}
              .enrollingTemplate ul {padding-left: 15px}
              .enrollingTemplate ul li {margin: 10px 0 0}
              .enrollingTemplate ul li ul {padding-left: 25px; list-style: disc}
              .enrollingTemplate ul li ul li {margin: 5px 0 0}
              .d-flex {display: flex; margin: 50px 0 25px; width: 100%}
              .double-img img { margin: 15px; max-width: 40%; max-height: 150px }
              p { padding-bottom: 15px; }
              .vcolor{ color: #28b6ea; }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="enrollingTemplate">
                  <p>Dear Snyder Team,<br><br>You are receiving this because someone has posted a New Contact Inquiry from the website. <a href="${SAPPS_WEB_MARKETING_URL}">${SAPPS_WEB_MARKETING_URL}</a></p>
                  <table border=1>
                      <tbody>
                          <tr><td><b>Name*</b></td><td><span class="vcolor">[Name]</span></td></tr>
                          <tr><td><b>Country*</b></td><td><span class="vcolor">[Country]</span></td></tr>
                          <tr><td><b>Phone</b></td><td><span class="vcolor">[Phone]</span></td></tr>
                          <tr><td><b>Email*</b></td><td><span class="vcolor">[Email]</span></td></tr>
                          <tr><td colspan='2'><b>Type your message*</b></td></tr>
                          <tr><td colspan='2'><span class="vcolor">[GeneralMessage]</span></td></tr>
                      </tbody>
                  </table>
                  <p>Regards, Snyder Team<br><br><img src="${stEmailLogoColored}" width="15%" alt="Snyder Technologies"/></p>
              </div>
          </div>
      </body>
      </html>`;
    // If form validate
    if (validateForm(formE2)) {
      const html = templateData
        .replace('[Name]', this.state.generalName)
        .replace('[Country]', this.state.generalCountry)
        .replace('[Phone]', this.state.generalPhone)
        .replace('[Email]', this.state.generalEmail)
        .replace('[GeneralMessage]', this.state.generalMessage);

      const data = {
        eSAPPS_WEB_MAILTO: SAPPS_WEB_MAILTO,
        emailSubject: 'New Contact Inquiry - Snyder Marketing Website',
        emailTemplate: html,
        parameterDetails: JSON.stringify({
          firstName: this.state.generalName.toString().split(' ')[0],
          lastName: this.state.generalName.toString().split(' ')[1],
          country: this.state.generalCountry,
          phone: this.state.generalPhone,
          email: this.state.generalEmail,
          message: this.state.generalMessage,
          referralType: 1,
        }),
      };

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${SAPPS_WEB_API_URL}/Referral/SubmitTarget`,
          headers: { 'content-type': 'application/json' },
          data,
        })
          .then((result) => {
            if (result.data.message == 'Email has been sent successfully.') {
              this.setState({
                generalName: '',
                generalCountry: '',
                generalPhone: '',
                generalEmail: '',
                generalMessage: '',
                generalMailSent: true,
                generalIsValidated: false,
              });
              window.location.reload();
            } else {
              this.setState({ generalError: result.data.error });
            }
          })
          .catch((error) => {
            this.setState({ generalError: error.message });
          });
      });
    }

    this.setState({ generalIsValidated: true });

    return false;
  }

  render() {
    if (typeof window !== 'undefined') {
      const setActive = (element, active) => {
        const formField = element.parentNode.parentNode;
        if (active) {
          formField.classList.add('form-field--is-active');
        } else {
          formField.classList.remove('form-field--is-active');
          element.value === ''
            ? formField.classList.remove('form-field--is-filled')
            : formField.classList.add('form-field--is-filled');
        }
      };

      Array.prototype.forEach.call(
        document.querySelectorAll('.inputMod'),
        (element) => {
          element.addEventListener('blur', () => {
            setActive(element, false);
          });

          element.addEventListener('focus', () => {
            setActive(element, true);
          });
        }
      );
    }

    const classNames = [];
    if (this.state.isValidated) {
      classNames.push('was-validated');
    }

    if (this.state.generalIsValidated) {
      classNames.push('was-validated');
    }

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <section className="contact-page--page-content">
        <div className="contact-page--start-with-us">
          <Container>
            <Row>
              <Col md="4">
                <h3>New Project?</h3>
                <p>For solutions and strategies.</p>
                <Button
                  variant="transparent"
                  onClick={() => {
                    this.handleShow('start-project');
                  }}
                >
                  Inquiry
                </Button>
              </Col>
              <Col md="4">
                <h3>Work with Us</h3>
                <p>To be part of Snyder Technologies.</p>
                <Button variant="transparent">
                  <Link to="/careers">Join Our Global Team</Link>
                </Button>
              </Col>
              <Col md="4">
                <h3>General</h3>
                <p>For inquiries and information.</p>
                <Button
                  variant="transparent"
                  onClick={() => {
                    this.handleShow('general-contact');
                  }}
                >
                  Say Hello
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="contact-page--tech-border">
          <Container>
            <Row>
              <Col md="12" className="text-center">
                <h2 className="text-white">
                  We take technology beyond the borders.
                </h2>
              </Col>
              <Col md="12">
                <div className="borders-state">
                  <ul>
                    <li>
                      <div className="state">
                        <div className="state-img">
                          <Image src={usa} />
                        </div>
                        <h3>USA</h3>
                        <p>
                          Bethlehem,<br />
                          Pennsylvania<br />
                          USA<br />
                          <a href="tel:14842068160">+1 484 206 8160</a>
                        </p>
                        {/*
                        <a
                          href="https://www.google.com/maps/dir//933+Main+St,+Bethlehem,+PA+18018,+USA"
                          target="_blank"
                          rel="noreferrer"
                        >
                          View on map
                        </a>
                        */}
                      </div>
                    </li>
                    {/*
                    <li>
                      <div className="state">
                        <div className="state-img">
                          <Image src={pakistan} />
                        </div>
                        <h3>Pakistan</h3>
                        <p>
                          208-B, Clifton Centre, Block 5, Clifton, Karachi -
                          75000. +92 96854 86954
                        </p>
                        <a
                          href="https://www.google.com/maps/dir///Clifton+Center,+Block+5+Clifton,+Karachi,+Karachi+City,+Sindh+75500,+Pakistan"
                          target="_blank"
                          rel="noreferrer"
                        >
                          View on map
                        </a>
                      </div>
                    </li>
                    */}
                    <li>
                      <div className="state">
                        <div className="state-img">
                          <Image src={indiaNorth} />
                        </div>
                        <h3>India - North </h3>
                        <p>
                          Noida,<br />
                          Uttar Pradesh<br />
                          India<br />
                          <a href="tel:+918967558493">+91 89675 58493</a>
                          {/*
                          187, Sector 63 Rd Block H, Sector 63, Noida, Uttar
                          Pradesh - 201301. +91 89675 58493
                          */}
                        </p>
                        {/*
                        <a
                          href="https://www.google.com/maps/dir//H-187,+Lohia+Rd,+H+Block,+Sector+63,+Noida,+Uttar+Pradesh+201301"
                          target="_blank"
                          rel="noreferrer"
                        >
                          View on map
                        </a>
                        */}
                      </div>
                    </li>
                    <li>
                      <div className="state">
                        <div className="state-img">
                          <Image src={indiaSouth} />
                        </div>
                        <h3>India - South </h3>
                        <p>
                          Coimbatore,<br />
                          Tamil Nadu<br />
                          India<br />
                          <a href="tel:+919878584930">+91 98785 84930</a>
                          {/*
                          41/2, SRC Sunrise, 2nd floor, Vivekananda Nagar,
                          Singanallur post, Coimbatore - 641005. +91 98785 84930
                          */}
                        </p>
                        {/*
                        <a
                          href="https://www.google.com/maps/dir//Springboard+Digital,+41%2F2,SRC+Sunrise,2nd+Floor,+Post,+Vivekanandha+Nagar,+Singanallur,+Tamil+Nadu+641005"
                          target="_blank"
                          rel="noreferrer"
                        >
                          View on map
                        </a>
                        */}
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          show={this.state.isModalOpen}
          onHide={this.handleClose}
          dialogClassName="modal-90w custom-modal-style"
          centered
        >
          <Modal.Body>
            <div
              className={`modal-form  ${
                this.state.modalId == 'start-project' ? 'd-block' : 'd-none'
              }`}
            >
              <h3 className="title">Let’s have a conversation.</h3>
              <p>Drop us a line by filling up this contact form.</p>
              <Form
                method="post"
                action="#"
                ref={(form) => {
                  this.formEl = form;
                }}
                className={classNames}
                noValidate
              >
                {this.state.mailSent && (
                  <div className="alert-success alert text-center mb-5">
                    Thank you for contacting us.
                  </div>
                )}
                {this.state.error && (
                  <div className="alert-danger error alert text-center  mb-5">
                    {this.state.error}
                  </div>
                )}
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="First Name*"
                        className="form-mod form-control"
                        required
                        value={this.state.companyFirstName}
                        whenChange={(e) => {
                          this.setState({ companyFirstName: e.target.value });
                        }}
                      />
                      <div className="invalid-feedback" />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="form-mod form-control"
                        value={this.state.companyLastName}
                        whenChange={(e) => {
                          this.setState({ companyLastName: e.target.value });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Company Name*"
                        className="form-mod form-control"
                        required
                        value={this.state.companyName}
                        whenChange={(e) => {
                          this.setState({ companyName: e.target.value });
                        }}
                      />
                      <div className="invalid-feedback" />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <input
                        type="email"
                        placeholder="Company Email Address*"
                        className="form-mod form-control"
                        required
                        value={this.state.companyEmail}
                        whenChange={(e) => {
                          this.setState({ companyEmail: e.target.value });
                        }}
                      />
                      <div className="invalid-feedback" />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Phone Number"
                        className="form-mod form-control"
                        value={this.state.companyPhoneNumber}
                        whenChange={(e) => {
                          this.setState({ companyPhoneNumber: e.target.value });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Country"
                        className="form-mod form-control"
                        value={this.state.companyCountry}
                        whenChange={(e) => {
                          this.setState({ companyCountry: e.target.value });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <textarea
                        name=""
                        id=""
                        cols="10"
                        rows="3"
                        placeholder="Tell us about your project*"
                        className="form-mod form-control"
                        required
                        value={this.state.companyMessage}
                        whenChange={(e) => {
                          this.setState({ companyMessage: e.target.value });
                        }}
                      />
                      <div className="invalid-feedback" />
                    </div>
                  </Col>
                  <Col md="12">
                    <button
                      type="submit"
                      className="btn-border"
                      onClick={(e) => this.handleProjectFormSubmit(e)}
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
            <div
              className={`modal-form  ${
                this.state.modalId == 'general-contact' ? 'd-block' : 'd-none'
              }`}
            >
              <h3 className="title">Hey there!</h3>
              <p>Drop us a line by filling up this contact form.</p>
              <Form
                method="post"
                action="#"
                ref={(form) => (this.formE2 = form)}
                className={classNames}
                noValidate
              >
                {this.state.generalMailSent && (
                  <div className="alert-success alert text-center mb-5">
                    Thank you for contacting us.
                  </div>
                )}
                {this.state.generalError && (
                  <div className="alert-danger error alert text-center  mb-5">
                    {this.state.generalError}
                  </div>
                )}
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Name*"
                        className="form-mod form-control"
                        required
                        value={this.state.generalName}
                        whenChange={(e) => {
                          this.setState({ generalName: e.target.value });
                        }}
                      />
                      <div className="invalid-feedback" />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Country*"
                        className="form-mod form-control"
                        required
                        value={this.state.generalCountry}
                        whenChange={(e) => {
                          this.setState({ generalCountry: e.target.value });
                        }}
                      />
                      <div className="invalid-feedback" />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Phone"
                        className="form-mod form-control"
                        value={this.state.generalPhone}
                        whenChange={(e) => {
                          this.setState({ generalPhone: e.target.value });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <input
                        type="email"
                        placeholder="Email Address*"
                        className="form-mod form-control"
                        required
                        value={this.state.generalEmail}
                        whenChange={(e) => {
                          this.setState({ generalEmail: e.target.value });
                        }}
                      />
                      <div className="invalid-feedback" />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <textarea
                        name=""
                        id=""
                        cols="10"
                        rows="3"
                        placeholder="Type your message*"
                        className="form-mod form-control"
                        required
                        value={this.state.generalMessage}
                        whenChange={(e) => {
                          this.setState({ generalMessage: e.target.value });
                        }}
                      />
                      <div className="invalid-feedback" />
                    </div>
                  </Col>
                  <Col md="12">
                    <button
                      type="submit"
                      className="btn-border"
                      onClick={(e) => this.handleGeneralFormSubmit(e)}
                    >
                      Send
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    );
  }
}

export default Contact;
