import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './client.scss';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const accopian = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/accopian.svg`;
// Const nnr = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/nnr.svg`;
// const shipsights = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/shipsights.svg`;
const ipaas = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/ipaas.svg`;
const gtc = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/gtc.svg`;
const simpliship = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/simpliship.svg`;
const msfp = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/msfp.svg`;
const iwtv = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/iwtv.svg`;
// Const dispatchhealth = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/dispatch-health.svg`;
const hbp = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/hbp.svg`;
const redrook = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/redrook-main.svg`;
const shangy = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/shangy.svg`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClientProps {}

/**
 * @param props
 */
export function Client(props: ClientProps) {
  return (
    <div className="client-wrapper">
      <Container>
        <div className="client-container">
          <Row className="items-end">
            <Col md="5">
              <div className="client-content">
                <h3 className="title">
                  <span>CLIENTS</span>A long-lasting partnership.
                </h3>
                <p>
                  Join hands in our global community of customers around the
                  globe who selected Snyder Technologies as their go-to solution
                  provider in the IT arena.
                </p>
                <button className="btn-border" type="button">
                  <a href="/contact">Start a Project</a>
                </button>
              </div>
            </Col>
            <Col md="7">
              <div className="client-logos">
                <div className="logo">
                  <img src={gtc} alt="" />
                </div>
                {/*
                <div className="logo">
                  <img src={shipsights} alt="" />
                </div>
                */}
                <div className="logo">
                  <img src={ipaas} alt="" />
                </div>
                {/*
                <div className="logo">
                  <img src={nnr} alt="" />
                </div>
                 */}
                <div className="logo">
                  <img src={simpliship} alt="" />
                </div>
                <div className="logo">
                  <img src={accopian} alt="" />
                </div>
                <div className="logo">
                  <img src={iwtv} alt="" />
                </div>
                <div className="logo">
                  <img src={msfp} alt="" />
                </div>
                <div className="logo">
                  <img src={redrook} alt="" />
                </div>
                <div className="logo">
                  <img src={hbp} alt="" />
                </div>
                <div className="logo">
                  <img src={shangy} alt="" />
                </div>
                {/*
                <div className="logo">
                  <img src={dispatchhealth} alt="" />
                </div>
                */}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Client;
