import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ServicesBenefits from '../../../components/services-benefits/services-benefits';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/devops.png`;
const BenefitsImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/devops-imgs.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}services/devops`;
const pageDescription =
  'A better way to build and deploy high-perfomance applications faster and easier.';
const pageTitle = 'DevOps';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Services | ${pageTitle} | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={pageDescription} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Services | ${pageTitle} | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="services-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Services"
            subheading=""
            heading={pageTitle}
            content={pageDescription}
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Gain a competitive advantage by adopting effective
                    practices.
                  </h3>
                  <Row className="justify-content-between">
                    <Col>
                      <p>
                        The ability to develop, test, and deploy applications
                        faster without compromising the quality gives a
                        competitive advantage that distinguishes you from
                        others. DevOps helps you to gain this competitive
                        advantage by integrating development and operations and
                        automating the workflows, testing, and infrastructure.
                        It enables your team to work based on Agile methodology
                        that encourages developers to write and test codes in
                        small chunks instead of writing large blocks of code.
                        Our DevOps automation services give you access to new
                        tools and prepare you to compete in the fast-paced
                        market.
                      </p>
                    </Col>
                  </Row>
                  <ServicesBenefits
                    image={BenefitsImage}
                    imageAlt={pageTitle}
                    listItems={[
                      'Reduces the transfer time from the development stage to the production stage.',
                      'Ensures high uptime of servers and supports scalability.',
                      'Enables teams to collaborate seamlessly and share responsibilities.',
                      'Enables engineers to write small pieces of code, integrate, test, and manage.',
                      'Allows your team to create configuration management code for the operations.',
                      'Offers tools and solutions for source control management.',
                    ]}
                  />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
