export default class custom {
  public static get siteTitle() {
    return 'Snyder Technologies | Digital Transformation Partner';
  }

  public static get siteName() {
    return 'Snyder Tech';
  }

  public static get siteDescription() {
    return 'Snyder Technologies, A competitive advantage that prepares you for the future.';
  } // Website description used for RSS feeds/meta description tag.

  public static get siteRss() {
    return '/rss.xml';
  } // Path to the RSS file.

  public static get siteFBAppID() {
    return '';
  } // FB Application ID for using app insights

  public static get googleAnalyticsID() {
    return 'UA-20754570-1';
  } // GA tracking ID.
}
