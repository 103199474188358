import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './future-cards.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FutureCardsProps {}

/**
 * @param props
 */
export function FutureCards(props: FutureCardsProps) {
  return (
    <div className="future-cards">
      <Container>
        <Row className="items-center">
          <Col lg="6" className="order-2-m">
            <div className="cards-container">
              <Row className="items-center">
                <Col md="6">
                  <div className="card-theme">
                    <i>
                      <svg
                        width="61"
                        height="41"
                        viewBox="0 0 61 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <ellipse
                          cx="50.2713"
                          cy="10.188"
                          rx="9.99783"
                          ry="10"
                          fill="#28B6EA"
                        />
                        <rect
                          x="0.282227"
                          y="10.188"
                          width="49.9892"
                          height="30"
                          fill="#004C6C"
                        />
                      </svg>
                    </i>
                    <h3>ROI-centric Approach</h3>
                    <p>
                      We help our clients to streamline their operations,
                      efficiently manage their data, and accelerate their
                      bottom-line results at the intersection of next generation
                      technology and change.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="card-theme">
                    <i>
                      <svg
                        width="45"
                        height="42"
                        viewBox="0 0 45 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <ellipse
                          cx="34.7068"
                          cy="29.1885"
                          rx="9.99783"
                          ry="10"
                          fill="#28B6EA"
                        />
                        <rect
                          width="29.9951"
                          height="29.9984"
                          transform="matrix(0.865978 0.500081 -0.499919 0.866072 15.2207 0.698242)"
                          fill="#004C6C"
                        />
                      </svg>
                    </i>
                    <h3>Agile Methodology</h3>
                    <p>
                      We believe in developing solutions that ensure strategic
                      and timely execution, deep collaboration with the client,
                      and continuous improvement.
                    </p>
                  </div>
                  <div className="card-theme">
                    <i>
                      <svg
                        width="42"
                        height="42"
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <ellipse
                          cx="31.2166"
                          cy="10.188"
                          rx="9.99783"
                          ry="10"
                          fill="#28B6EA"
                        />
                        <path
                          d="M41.2147 0.187988L0.223633 41.188H41.2147V0.187988Z"
                          fill="#004C6C"
                        />
                      </svg>
                    </i>
                    <h3>24/7 Working Method</h3>
                    <p>
                      We are the company that will go the extra mile for you.
                      Your business never stops running and neither do we. Our
                      cross-cultural and multi-disciplinary team around the
                      world will offer unparalleled IT and software services
                      around-the-clock.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg="6">
            <div className="futer-content">
              <h3 className="title">
                <span>Our ideas are big. Our work is impactful.</span>A
                competitive advantage that helps you pave the way forward
              </h3>
              <p>
                Snyder Technologies is a global leader in IT services and
                software development. Equipped with highly experienced,
                cross-functional teams and a wide array of custom solutions, we
                aim to strengthen our clients with every engagement and prepare
                them with all the tools they need to succeed.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FutureCards;
