import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ServicesBenefits from '../../../components/services-benefits/services-benefits';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/disaster-recovery.png`;
const BenefitsImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/disaster-recovery-imgs.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/services/disaster-recovery`;
const pageDescription =
  'Protect your applications and data despite unexpected incidents.';
const pageTitle = 'Disaster Recovery';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Services | ${pageTitle} | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={pageDescription} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Services | ${pageTitle} | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="services-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Services"
            subheading=""
            heading={pageTitle}
            content={pageDescription}
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    A set of cloud-based services that help you regain access to
                    your infrastructure.
                  </h3>
                  <Row className="justify-content-between">
                    <Col>
                      <p>
                        The best way to ensure business continuity is to be
                        prepared for unexpected incidents, where failing to do
                        so would lead us into great adversity. Snyder
                        Technologies offers a set of cloud-based services that
                        enable you to protect your applications and data during
                        disasters such as natural disasters, human errors,
                        system failures, and cyberattacks. Since Data Recovery
                        as a Service (DRaaS) is a cloud-based solution, you
                        don’t need to have your own infrastructure resources or
                        manage the recovery all by yourself. Also, DRaaS
                        provides standby computing capacity for the effective
                        recovery of your data and applications.
                      </p>
                    </Col>
                  </Row>
                  <ServicesBenefits
                    image={BenefitsImage}
                    imageAlt={pageTitle}
                    listItems={[
                      'Cost-efficient compare to traditional recovery solutions.',
                      'You pay only for the recovery resources you use.',
                      'You don’t need to manage and maintain your own data center.',
                      'Offers highly-secured data backup storage.',
                      'Automation facilitates easy configuration and immediate recovery.',
                    ]}
                  />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
