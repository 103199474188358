import React, { Component } from 'react';
import './services-benefits.module.scss';
import { Row, Col, Image } from 'react-bootstrap';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/software-development.png`;

export interface ServicesBenefitsProps {
  image?: string;
  imageAlt?: string;
  listItems?: ['11', '22'];
}

class ServicesBenefits extends Component<ServicesBenefitsProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { image, imageAlt, listItems = [] } = this.props;

    return (
      <div className="list-container">
        <Row>
          <Col lg="6" sm="12" className="services-benefits-img">
            <img src={`${image}`} alt={`${imageAlt}`} />
          </Col>
          <Col lg="6" sm="12">
            <ul className="services-keypoints">
              {listItems.map((value) => (
                <li key={value}>{value}</li>
              ))}
            </ul>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ServicesBenefits;
