import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/wireless-connection.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/collaboration/virtual-connection`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Collaboration | Virtual Connection | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Collaboration | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="COLLABORATION"
            subheading=""
            heading="Virtual Connection"
            content="Scale-up your business by replacing traditional networking systems with virtual networks."
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Reduce your capital and maintenance costs, and boost
                    engagement and profitability.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        In the age of information, the cost of running an
                        enterprise is huge if you don’t keep up with new
                        technologies. Traditional networking systems not only
                        require space and a lot of hardware to implement, but
                        the capital expenditure is also high, with the
                        maintenance costs surging day-by-day. We at Snyder
                        Technologies build solutions for virtual connection that
                        enables seamless communication between computers and
                        servers and reduces the implementation and maintenance
                        costs.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        Unlike the traditional networking systems that require
                        physical networking tools such as hardware and cables,
                        the virtual connection solutions we offer comprise a
                        virtualized version of networking tools that will enable
                        your servers and data centers to operate across multiple
                        locations. This gives your network administrators an
                        advantage to upgrade or modify the network even without
                        the need to replace the systems. Also, you can enable
                        remote work in your organization by distributing your
                        workloads across your networking systems at different
                        locations in a more secure way.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Market Need"
                          listItems={[
                            'Virtually connected systems and servers',
                            'Easy network configuration and connectivity',
                            'Robust software application for control',
                            'Connectivity between LAN and global network',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Solution"
                          listItems={[
                            'Low capital costs and maintenance costs',
                            'Increased collaboration and productivity',
                            'Network flexibility and business scalability',
                            'Easy configuration and network segmentation',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
