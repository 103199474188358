import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/cyber-security.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/infrastructure-modernization/cybersecurity-identity`;
const pageOgImage = `${SAPPS_WEB_CDN_FILES_URL}images/snydertech-og-02.jpg`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Infrastructure Modernization | Cybersecurity Identity | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Infrastructure Modernization | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content={pageOgImage} />
        <meta name="twitter:image" content={pageOgImage} />
        <meta property="og:image:alt" content="Snyder Technologies, Together, let's reimagine the future of your business." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Infrastructure Modernization | ${custom.siteTitle}`} />
        <meta name="twitter:description" content={Strings.aboutUs} />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="OPERATIONAL EXCELLENCE"
            subheading=""
            heading="Cyber Security & Identity"
            content="Protect your organization from cyber security threats."
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <h3>Identity and Access Management</h3>
                      <p>
                        In this age of information, no matter what your line
                        of business is, ignoring technology makes it obsolete.
                        Technology has not only changed the way
                        businesses are operated but also changed the way
                        customers perceive them. Today, the majority of
                        interactions happen online. Organizations
                        desperately need to manage and protect their data.
                      </p>
                      <p>
                        The cybersecurity solutions we offer enable you to
                        identify, organize, and protect unstructured and
                        sensitive data that is spread across your organization.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <h3>Cyber Security</h3>
                      <p>
                        Our approach to cybersecurity and identity management is
                        extensive and rigorous. Our cybersecurity
                        solutions not only cover databases, cloud,
                        applications, and servers, but also the strategies
                        and tools for identity access management.</p>
                      <p>By controlling who has access to what in your organization
                        and managing the processes of identification, authentication,
                        and authorization through policies, strategies, and tools,
                        We focus on optimizing your operations and building
                        memorable experiences with peace of mind.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Proactive monitoring and management system',
                            'Endpoint security for remote access',
                            'Automated authentication and authorization',
                            'Identification of vulnerable accounts',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Reliable and easy-to-manage solutions',
                            'Greater visibility over the accounts',
                            'Comprehensive access governance',
                            'Protection from data breaches and threats',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
