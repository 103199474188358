import React from 'react';
import './job-description.scss';
import { Container, Row, Col } from 'react-bootstrap';
import './job-project-manager-clinical-research-india-2021.module.scss';
import JobForm from './jobs-form/job-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface JobProjectManagerClinicalResearchIndia2021Props {}

/**
 * @param props
 */
export function JobProjectManagerClinicalResearchIndia2021(
  props: JobProjectManagerClinicalResearchIndia2021Props
) {
  return (
    <section className="career-desc--page-content">
      <div className="career-desc--job-description">
        <Container>
          <div className="job-content">
            <Row>
              <Col md="12">
                <h3>Job Description</h3>
                <p>
                  The Project Manager (PM) is responsible for the management of
                  all aspects of Clinical Trial Team activities for assigned
                  project(s). The PM is accountable for achieving successful
                  delivery of clinical team activities at the project level by
                  meeting company and regulatory requirements according to time,
                  quality/scope and budget constraints.{' '}
                </p>
              </Col>
              <Col md="12">
                <h3>Job Responsibilities</h3>
                <p>
                  To perform this job successfully, an individual must be able
                  to perform the following:
                </p>
                <ul>
                  <li>
                    Proactively manage project level operational aspects of
                    Clinical Trial Team (CTT) including management of trial
                    timeline, budget, resources and vendors.
                  </li>
                  <li>
                    Provide efficient updates on trial progress to the Clinical
                    Program Director (CPD) and/or Senior Director of Clinical
                    Operations (DCO), with respect to vendor selection, project
                    plans, trial budget and timeline management, quality
                    standards and risk mitigation.
                  </li>
                  <li>
                    Ensure effective project plans are in place and operational
                    for each trial and work proactively with the Clinical Trial
                    Team (CTT) to set priorities in accordance with applicable
                    project plans, company standard operational procedures,
                    ICH/GCP guidelines and regulatory requirements.
                  </li>
                  <li>
                    Ensure potential study risks are escalated to the attention
                    of the CPD when appropriate.
                  </li>
                  <li>
                    Review and approve site visit reports; ensure tracking,
                    follow up and resolution of site issues have been completed
                    in a timely manner.
                  </li>
                  <li>
                    Monitor the quality of vendor deliverables, address quality
                    issues with the appropriate team member and identify
                    opportunities to improve training, execution and quality
                    control across the clinical team
                  </li>
                  <li>
                    Review and approve vendor invoices in collaboration with the
                    Accounting team to ensure investigator payments occur in a
                    timely manner
                  </li>
                  <li>
                    Review and approve vendor responses to quality assurance
                    audits for appropriateness, timeliness and accordance with
                    company SOPs and regulatory requirements.
                  </li>
                  <li>
                    Ensure all project level study documentation is filed in the
                    TMF in accordance with company SOPs/all regulatory
                    requirements and provide oversight to the clinical team
                    regarding TMF filing, maintenance and archival procedures
                  </li>
                  <li>
                    Effectively provide support to Clinical Site Manager(s) in
                    the conduct of the trials
                  </li>
                </ul>
              </Col>
              <Col md="12" className="mt-4">
                <h3>Skills & Qualifications</h3>
                <ul>
                  <li>
                    Five plus years of experience in Clinical Research domain
                  </li>
                  <li>
                    Minimum 3 years of clinical operations experience as PM with
                    increasing levels of responsibility, in the Pharmaceutical,
                    Biotechnology, Medical Device or CRO industry is required.
                  </li>
                  <li>
                    Bachelor&apos;s degree in Life Sciences, Nursing Licensure
                    or Pharmacy, at minimum
                  </li>
                  <li>
                    Read, write and speak fluent English,excellent verbal and
                    written communication skills
                  </li>
                  <li>
                    Must have a thorough knowledge of clinical research
                    concepts, practices, and FDA regulations and ICH Guidelines
                    regarding drug development phases, clinical research and
                    data management methods
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <JobForm />
    </section>
  );
}

export default JobProjectManagerClinicalResearchIndia2021;
