import React from 'react';
import './banner.scss';
import Carousel from 'react-bootstrap/Carousel';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const homeBannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/home-banner.jpg`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BannerProps {}

/**
 * @param props
 */
export function Banner(props: BannerProps) {
  return (
    <div
      className="carousel-slider"
      style={{ backgroundImage: `url(${homeBannerImage})` }}
    >
      <Carousel fade speed={10_000}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={homeBannerImage}
            alt="Snyder Tech"
          />
          <Carousel.Caption>
            <div className="container">
              <div className="content">
                <h3>Reimagining business for</h3>
                <p>12+ years</p>
                <span>
                  offering a competitive edge through{' '}
                  <strong>Digital Transformation.</strong>
                </span>
              </div>
            </div>
          </Carousel.Caption>
          <Carousel.Caption className="cap-footer">
            <div className="container">
              <div className="content-footer">
                <div className="cf-col">
                  <strong>70+</strong>
                  EMPLOYEES
                </div>
                <div className="cf-col">
                  <strong>4+</strong>
                  LOCATION
                </div>
                <div className="cf-col">
                  <strong>12+</strong>
                  YEARS
                </div>
                <div className="cf-col">
                  <strong>200+</strong>
                  PRODUCTS
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={homeBannerImage}
            alt="Snyder Tech"
          />
          <Carousel.Caption>
            <div className="container">
              <div className="content">
                <h3>Spreading a positive impact across</h3>
                <p>100+ Customers</p>
                <span>
                  through <strong>Digital Transformation.</strong>
                </span>
              </div>
            </div>
          </Carousel.Caption>
          <Carousel.Caption className="cap-footer">
            <div className="container">
              <div className="content-footer">
                <div className="cf-col">
                  <strong>70+</strong>
                  EMPLOYEES
                </div>
                <div className="cf-col">
                  <strong>4+</strong>
                  LOCATION
                </div>
                <div className="cf-col">
                  <strong>12+</strong>
                  YEARS
                </div>
                <div className="cf-col">
                  <strong>200+</strong>
                  PRODUCTS
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={homeBannerImage}
            alt="Snyder Tech"
          />
          <Carousel.Caption>
            <div className="container">
              <div className="content">
                <h3>Redefining businesses from</h3>
                <p>4 Global Locations</p>
                <span>
                  through <strong>Digital Transformation.</strong>
                </span>
              </div>
            </div>
          </Carousel.Caption>
          <Carousel.Caption className="cap-footer">
            <div className="container">
              <div className="content-footer">
                <div className="cf-col">
                  <strong>70+</strong>
                  EMPLOYEES
                </div>
                <div className="cf-col">
                  <strong>4+</strong>
                  LOCATION
                </div>
                <div className="cf-col">
                  <strong>12+</strong>
                  YEARS
                </div>
                <div className="cf-col">
                  <strong>200+</strong>
                  PRODUCTS
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Banner;
