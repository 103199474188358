import React from 'react';
import './services.module.scss';
import { Container, Row, Col, Image, Tabs, Tab } from 'react-bootstrap';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ServicesProps {}

/**
 * @param props
 */
export function Services(props: ServicesProps) {
  return (
    <section className="generic--page-content">
      <div className="generic--section services--section">
        <Container>
          <div className="generic--container services--container">
            <h3>Creating values by facilitating the outcome you expect.</h3>
            <Row className="justify-content-between">
              <Col>
                <p>
                  IT services for us is more than delivering applications and
                  infrastructure solutions. Since our inception, we have created
                  values for our clients by not only solving their problems but
                  also facilitating their pursuit of excellence. Our approach
                  involves considering the significant elements of IT services
                  such as costs, technology, outcomes, and risks. This enables
                  us to understand your business better and come up with
                  solutions that best suit your business needs. From software
                  development to managed services to search engine optimization,
                  we offer a range of IT services that help you thrive as a
                  business.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Services;
