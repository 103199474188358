import React from 'react';
import { Col, Container, Image, Row, Tab, Tabs } from 'react-bootstrap';
import './careers.scss';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const equality = `${SAPPS_WEB_CDN_FILES_URL}images/equality.svg`;
const transperancy = `${SAPPS_WEB_CDN_FILES_URL}images/transperancy.svg`;
const flexibility = `${SAPPS_WEB_CDN_FILES_URL}images/flexibility.svg`;
const careerGrowth = `${SAPPS_WEB_CDN_FILES_URL}images/career-growth.svg`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CareerProps {}

/**
 * @param props
 */
export function Careers(props: CareerProps) {
  return (
    <section className="career--page-content">
      <div className="career--work-culture">
        <div className="work-culture-content">
          <Container>
            <Row>
              <Col className="text-center">
                <p className="text-white">
                  Employees are the heart of Snyder Technologies.
                </p>
                <h2 className="text-white">Our Work Culture</h2>
              </Col>
              <Col md="12">
                <div className="working-point">
                  <ul>
                    <li>
                      <div className="culture-content">
                        <Image src={equality} />
                        <h3>Equality</h3>
                        <p>
                          Every employee in Snyder Technologies plays a vital
                          role in the organization. We have created an
                          environment that encourages collaborative idea
                          generation, continuous improvement, and long-lasting
                          relationships.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="culture-content">
                        <Image src={transperancy} />
                        <h3>Transparency</h3>
                        <p>
                          We have a highly-transperant communication channel
                          that keeps everyone informed.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="culture-content">
                        <Image src={flexibility} />
                        <h3>Flexibility</h3>
                        <p>
                          We offer our employees the flexibility to work
                          conveniently from anywhere, any time.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="culture-content">
                        <Image src={careerGrowth} />
                        <h3>Career Growth</h3>
                        <p>
                          We encourage our employees to pave their path forward
                          in the company through a track record of excellence,
                          enhanced skillsets and curiosity to grow and explore.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="career--oppurtunities">
        <Container>
          <Row>
            <Col>
              <h2>Openings</h2>
              <Tabs defaultActiveKey="All" id="uncontrolled-tab-example">
                <Tab eventKey="All" title="All">
                  <ul className="job-list">
                    <li>
                      <a
                        href="/careers/in/project-manager-clinical-research"
                        className="job-role"
                      >
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>Project Manager</h3>
                          <p>Clinical Research Domain</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/careers/in/it-manager" className="job-role">
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>Manager</h3>
                          <p>IT Infrastructure and Managed Services</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/careers/in/senior-it-engineer"
                        className="job-role"
                      >
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>Senior Engineer</h3>
                          <p>IT Infrastructure and Managed Services</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/careers/in/senior-sales-executive"
                        className="job-role"
                      >
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>Sr. Sales Executive</h3>
                          <p>IT Infrastructure and Managed Services</p>
                        </div>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" className="job-role">
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>Sales Executive</h3>
                          <p>IT Infrastructure and Managed Services</p>
                        </div>
                      </a>
                    </li> */}
                    <li>
                      <a href="/careers/in/developer-php" className="job-role">
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>PHP Developer</h3>
                          <p>Delhi, India</p>
                        </div>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" className="job-role">
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>Full Stack Developer</h3>
                          <p>Delhi, India</p>
                        </div>
                      </a>
                    </li> */}
                  </ul>
                </Tab>
                <Tab eventKey="USA" title="USA">
                  <div className="job-title">
                    <h3>We will soon start recruiting</h3>
                  </div>
                </Tab>
                {/*<Tab eventKey="Pakistan" title="Pakistan">
                  <div className="job-title">
                    <h3>We will soon start recruiting</h3>
                  </div>
                  </Tab>*/}
                <Tab eventKey="India" title="India">
                  <ul className="job-list">
                    <li>
                      <a
                        href="/careers/in/project-manager-clinical-research"
                        className="job-role"
                      >
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>Project Manager</h3>
                          <p>Clinical Research Domain</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/careers/in/it-manager" className="job-role">
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>Manager</h3>
                          <p>IT Infrastructure and Managed Services</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/careers/in/senior-it-engineer"
                        className="job-role"
                      >
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>Senior Engineer</h3>
                          <p>IT Infrastructure and Managed Services</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/careers/in/senior-sales-executive"
                        className="job-role"
                      >
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>Sr. Sales Executive</h3>
                          <p>IT Infrastructure and Managed Services</p>
                        </div>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" className="job-role">
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>Sales Executive</h3>
                          <p>IT Infrastructure and Managed Services</p>
                        </div>
                      </a>
                    </li> */}
                    <li>
                      <a href="/careers/in/developer-php" className="job-role">
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>PHP Developer</h3>
                          <p>Delhi, India</p>
                        </div>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" className="job-role">
                        <span className="shaft" />
                        <div className="job-title">
                          <h3>Full Stack Developer</h3>
                          <p>Delhi, India</p>
                        </div>
                      </a>
                    </li> */}
                  </ul>
                </Tab>
              </Tabs>
              <p>
                Send your resume to{' '}
                <a href="mailto:careers@snyder.tech">
                  <strong>careers@snyder.tech</strong>
                </a>
                , if you don’t find your suitable opening. We will get back to
                you!
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Careers;
