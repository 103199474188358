import React, { Component } from 'react';
import './msp-contact.scss';
import { Row, Col, Image, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { validateForm } from '../../assets/js/custom-validation';

const { SAPPS_WEB_API_URL } = process.env;
const { SAPPS_WEB_MAILTO } = process.env;
const { SAPPS_WEB_MARKETING_URL } = process.env;
const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const stEmailLogoColored = `${SAPPS_WEB_CDN_FILES_URL}images/logo-email.png`;
export interface MspContactProps {
  title?: string;
  description?: string;
}

class MspContact extends Component<MspContactProps> {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      companyName: '',
      companyEmail: '',
      teamSize: '',
      phoneNumber: '',
      country: '',
      zipCode: '',
      agree: false,
      mailSent: false,
      isValidated: false,
    };
  }
  handleContactFormSubmit(event) {
    event.preventDefault();
    // Reset State
    this.setState({ error: null, mailSent: false });
    const { formEl } = this;
    const templateData = `
      <!doctype html>
      <html lang='en'>
      <head>
          <meta charset='UTF-8'><meta name='viewport' content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'>
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>New Project Enquiry</title>
          <style>
              .container {width: 100%; max-width: 1000px; margin: 0 auto; padding: 0 15px}
              table { width: 100%; }
              .enrollingTemplate th p {margin: 0; text-align: left; font-weight: normal}
              .enrollingTemplate h2 {margin: 50px 0 25px}
              .enrollingTemplate tbody p {margin: 0; line-height: 23px}
              .enrollingTemplate tbody p+p {margin: 15px 0 0;}
              .enrollingTemplate ul {padding-left: 15px}
              .enrollingTemplate ul li {margin: 10px 0 0}
              .enrollingTemplate ul li ul {padding-left: 25px; list-style: disc}
              .enrollingTemplate ul li ul li {margin: 5px 0 0}
              .d-flex {display: flex; margin: 50px 0 25px; width: 100%}
              .double-img img { margin: 15px; max-width: 40%; max-height: 150px }
              p { padding-bottom: 15px; }
              .vcolor{ color: #28b6ea; }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="enrollingTemplate">
                  <p>Dear Snyder Team,<br><br>You are receiving this because someone has posted a New Project Inquiry from the website. <a href="${SAPPS_WEB_MARKETING_URL}">${SAPPS_WEB_MARKETING_URL}</a></p>
                  <table border=1>
                      <tbody>
                          <tr><td><b>First Name*</b></td><td><span class="vcolor">[FirstName]</span></td></tr>
                          <tr><td><b>Last Name</b></td><td><span class="vcolor">[LastName]</span></td></tr>
                          <tr><td><b>Company Name*</b></td><td><span class="vcolor">[CompanyName]</span></td></tr>
                          <tr><td><b>Company Email Address*</b></td><td><span class="vcolor">[CompanyEmail]</span></td></tr>
                          <tr><td><b>Phone Number</b></td><td><span class="vcolor">[TeamSize]</span></td></tr>
                          <tr><td><b>Country</b></td><td><span class="vcolor">[PhoneNumber]</span></td></tr>
                          <tr><td><b>Country</b></td><td><span class="vcolor">[Country]</span></td></tr>
                          <tr><td><b>Country</b></td><td><span class="vcolor">[ZipCode]</span></td></tr>
                      </tbody>
                  </table>
                  <p>Regards, Snyder Team<br><br><img src="${stEmailLogoColored}" width="15%" alt="Snyder Technologies"/></p>
              </div>
          </div>
      </body>
      </html>`;
    // If form validate
    if (validateForm(formEl)) {
      const html = templateData
        .replace('[FirstName]', this.state.firstName)
        .replace('[LastName]', this.state.lastName)
        .replace('[CompanyName]', this.state.companyName)
        .replace('[CompanyEmail]', this.state.companyEmail)
        .replace('[TeamSize]', this.state.teamSize)
        .replace('[PhoneNumber]', this.state.phoneNumber)
        .replace('[Country]', this.state.country)
        .replace('[ZipCode]', this.state.zipCode);

      const data = {
        eSAPPS_WEB_MAILTO: SAPPS_WEB_MAILTO,
        emailSubject: 'New Project Inquiry - Snyder Marketing Website',
        emailTemplate: html,
        parameterDetails: JSON.stringify({
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          companyName: this.state.companyName,
          email: this.state.companyEmail,
          teamSize: this.state.teamSize,
          phone: this.state.phoneNumber,
          country: this.state.country,
          zipCode: this.state.zipCode,
          referralType: 2,
        }),
      };

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${SAPPS_WEB_API_URL}/contact`,
          headers: { 'content-type': 'application/json' },
          data,
        })
          .then((result) => {
            if (result.data.message == 'Email has been sent successfully.') {
              this.setState({
                firstName: '',
                lastName: '',
                companyName: '',
                companyEmail: '',
                teamSize: '',
                phoneNumber: '',
                country: '',
                zipCode: '',
                agree: true,
                mailSent: true,
                isValidated: false,
              });
              window.location.reload();
            } else {
              this.setState({ error: result.data.error });
            }
          })
          .catch((error) => {
            this.setState({ error: error.message });
          });
      });
    }

    this.setState({ isValidated: true });

    return false;
  }
  render() {
    const { title, description } = this.props;
    const classNames = [];
    if (this.state.isValidated) {
      classNames.push('was-validated');
    }
    return (
      <section className="mspContactContainer">
        <section className="container">
          <section className="mspContactInnerSec vectorShapSp">
            <svg
              className="topLeft"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M40 40L3.49691e-06 40L40 0L40 40Z" fill="#0E89B5" />
            </svg>
            <svg
              className="bottomLeft"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 40.0005H40L0 0.000488281L0 40.0005Z" fill="#0E89B5" />
            </svg>
            <svg
              className="bottomRight"
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M72 72L6.29444e-06 72L72 0L72 72Z" fill="#0E89B5" />
            </svg>
            <section className="mspContactContentSec">
              <h4>{title}</h4>
              <section className="mspFormSec">
                <Form
                  method="post"
                  action="#"
                  ref={(form) => {
                    this.formEl = form;
                  }}
                  className={classNames}
                  noValidate
                >
                  {this.state.mailSent && (
                    <div className="alert-success alert text-center mb-5">
                      Thank you for contacting us.
                    </div>
                  )}
                  {this.state.error && (
                    <div className="alert-danger error alert text-center  mb-5">
                      {this.state.error}
                    </div>
                  )}
                  <Row>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          required
                          value={this.state.firstName}
                          onChange={(e) => {
                            this.setState({ firstName: e.target.value });
                          }}
                        ></Form.Control>
                        <div className="invalid-feedback text-left" />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          required
                          value={this.state.lastName}
                          onChange={(e) => {
                            this.setState({ lastName: e.target.value });
                          }}
                        ></Form.Control>
                        <div className="invalid-feedback text-left" />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="email"
                          placeholder="Company Email"
                          required
                          value={this.state.companyEmail}
                          onChange={(e) => {
                            this.setState({ companyEmail: e.target.value });
                          }}
                        ></Form.Control>
                        <div className="invalid-feedback text-left" />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Company Name"
                          required
                          value={this.state.companyName}
                          onChange={(e) => {
                            this.setState({ companyName: e.target.value });
                          }}
                        ></Form.Control>
                        <div className="invalid-feedback text-left" />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Team Size"
                          required
                          value={this.state.teamSize}
                          onChange={(e) => {
                            this.setState({ teamSize: e.target.value });
                          }}
                        ></Form.Control>
                        <div className="invalid-feedback text-left" />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Phone Number"
                          required
                          value={this.state.phoneNumber}
                          onChange={(e) => {
                            this.setState({ phoneNumber: e.target.value });
                          }}
                        ></Form.Control>
                        <div className="invalid-feedback text-left" />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Select
                          placeholder="Country"
                          className="form-control"
                          required
                          value={this.state.country}
                          onChange={(e) => {
                            this.setState({ country: e.target.value });
                          }}
                        >
                          <option>Select Country</option>
                        </Form.Select>
                        <div className="invalid-feedback text-left" />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Zip Code"
                          required
                          value={this.state.zipCode}
                          onChange={(e) => {
                            this.setState({ zipCode: e.target.value });
                          }}
                        ></Form.Control>
                        <div className="invalid-feedback text-left" />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group className="mb-3 text-left">
                        <div className="d-flex align-items-start flex-column flex-md-row">
                          <div className="mr-auto ">
                            <p className="mb-3">
                              I agree to the terms of Catapult’s Privacy/Cookie
                              Policy. *
                            </p>
                            <Form.Check
                              className="customFormCheck"
                              type="checkbox"
                              id="agreeCheck"
                              label="I Agree"
                              required
                              value={this.state.agree}
                              onChange={(e) => {
                                this.setState({ agree: e.target.value });
                              }}
                            />
                          </div>
                          <button
                            className="btn btn-white btn-sm mt-3 mt-md-0"
                            type="submit"
                            onClick={(e) => this.handleContactFormSubmit(e)}
                          >
                            Get started
                          </button>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default MspContact;
