import React from 'react';

import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Strings } from '../../../../languages/en';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import Partners from '../../../components/partners/partners';
import ListCard from '../../../components/list-card/list-card';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/operational-excellence.png`;
const respCol = `${SAPPS_WEB_CDN_FILES_URL}images/responsibility-col.png`;
const datapCol = `${SAPPS_WEB_CDN_FILES_URL}images/data-col.png`;
const userExpCol = `${SAPPS_WEB_CDN_FILES_URL}images/user-experience-col.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/operational-excellence`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Operational Excellence | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Solutions"
            subheading=""
            heading="Operational Excellence"
            content="Streamline your operations and supply chain with the industry’s modern tools."
            innerImg={bannerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <Row className="justify-content-between">
                    <Col>
                      <h3>
                        Bringing together your business operations leads to
                        excellence and innovation
                      </h3>
                      <p>
                        Consistently focusing on operations, customer service,
                        and technology is a notable factor that distinguishes
                        businesses that value innovation, from others. It would
                        be so difficult for an organization to serve its
                        customers with outdated technology, disjunctive systems,
                        and manual processes. Snyder Technologies offers you a
                        comprehensive set of solutions that enable you to bring
                        together the business operations that will give you
                        greater control and the capability to nurture innovative
                        practices.
                      </p>
                      <p>
                        Ours are customized solutions that you can incorporate
                        into your operational ecosystem without needing to
                        completely replace your systems. Our solutions also
                        enable you to gain insights from your customers through
                        real-time data, so that you can refine your
                        methodologies and strategies and make better decisions.
                        By departing from outdated systems and hierarchies and
                        creating an environment where your teams are encouraged
                        to collaborate and come up with new ideas, you can build
                        a more transparent and proactive work culture that
                        promotes innovation.
                      </p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={userExpCol}
                        cardTitle="Finance & Operations"
                        cardLink="/solutions/operational-excellence/finance-operations-erp"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={respCol}
                        cardTitle="Field Service"
                        cardLink="/solutions/operational-excellence/field-service"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={datapCol}
                        cardTitle="Managed Services"
                        cardLink="/solutions/operational-excellence/managed-services"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                  </Row>

                  <Partners />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
