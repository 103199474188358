import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/responsibility.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/experience/customer-engagement-crm`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Experience | Customer Engagement | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="EXPERIENCE"
            subheading=""
            heading="Customer Engagement | CRM"
            content="Enhance your customer service by improving the relationships with your customers."
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Manage the interactions with your customers in a better way
                    to drive your sales.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        No business exists without customers. That being said,
                        managing customer relationships is as imperative as
                        focusing on innovation. Snyder Technologies offers you
                        solutions for better customer engagement that will help
                        you to manage interactions with your customers and
                        potential customers. We help you to build lasting
                        customer relationships by enhancing your communication
                        and optimize your processes through better data
                        organization.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        We make this happen by combining the strategies of
                        Customer Relationship Management (CRM) with CRM system
                        –software that comes with dedicated applications for
                        workflow management, customer contact management, sales
                        management, etc. The advantage of bringing in a CRM
                        system is that it enables you to focus on engagement
                        with individual customers. Moreover, CRM has evolved
                        into a lot more than just a sales and marketing tool.
                        You can now incorporate it into your business to manage
                        your supply-chain management and operations.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Customer acquisition and retention',
                            'Tracking of leads and customer engagement',
                            'Centralized management for marketing and sales',
                            'Sales performance analytics for optimization',
                            'Automated assignments for sales teams',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Better data organization',
                            'Enhanced communication and follow-ups',
                            'Better clarity of sales goals',
                            'Accurate data for sales insights',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
