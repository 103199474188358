import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './list-card.module.scss';
import { Link } from 'gatsby';

export interface ListCardProps {
  cardTitle?: string;
  cardImg?: string;
  cardLink?: string;
  cardLinkTitle?: string;
}

class ListCard extends Component<ListCardProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { cardTitle, content, cardImg, cardLink, cardLinkTitle } = this.props;

    return (
      <div className="card-with-border">
        <Image src={cardImg} />
        <h5>{cardTitle}</h5>
        {content}
        {cardLinkTitle ? (
          <Link to={cardLink}>{cardLinkTitle}</Link>
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}

export default ListCard;
