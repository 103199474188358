import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const mediaMarketing = `${SAPPS_WEB_CDN_FILES_URL}images/media-marketing.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/information/marketing-enablement`;
const pageOgImage = `${SAPPS_WEB_CDN_FILES_URL}images/snydertech-og-02.jpg`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Information | Marketing Enablement | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Marketing Enablement | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content={pageOgImage} />
        <meta name="twitter:image" content={pageOgImage} />
        <meta property="og:image:alt" content="Snyder Technologies, Together, let's reimagine the future of your business." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Marketing Enablement | ${custom.siteTitle}`} />
        <meta name="twitter:description" content={Strings.aboutUs} />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Solutions"
            subheading=""
            heading="Marketing Enablement"
            content="We equip your marketing ecosystems with the right technologies and strategies."
            innerImg={mediaMarketing}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Marketing, in the digital age, is more than just preparation
                    for sales.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        Innovation is the cornerstone of every visionary organization and enables marketing
                        to be the essential driver of business growth. Today the complexity and cost of
                        marketing has made it increasingly hard for businesses to differentiate themselves.
                        The team at Snyder Technologies helps our clients by identifying the right data
                        solutions that deliver visibility about their current market state, future
                        opportunities, and most importantly, consumer behavior.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        This visibility enables your decision makers to convert real-time data into actionable
                        insights and implement them across multiple platforms and ad campaigns. Our AI-powered
                        solutions, based on analytics, will allow you to automate campaigns and optimize your
                        marketing models to drive sales and enhance the customer experience.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Content production and implementation',
                            'Data analytics and decision making',
                            'Campaign automation',
                            'Marketing and sales optimization',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Efficiency and resiliency',
                            'Agile marketing models',
                            'Strategy optimization',
                            'Sales acceleration',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
