import React from 'react';
import './about-us.scss';
import { Container, Row, Col, Image, Tabs, Tab } from 'react-bootstrap';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const ceoUsa = `${SAPPS_WEB_CDN_FILES_URL}images/people/300x300/ben-snyder-300x300.jpg`;
const headofManagedServicesUsa = `${SAPPS_WEB_CDN_FILES_URL}images/people/300x300/joe-marks-300x300.jpg`;
const directorofClientSuccessUsa = `${SAPPS_WEB_CDN_FILES_URL}images/people/300x300/adam-russek-300x300.jpg`;
const executiveDirectorApac = `${SAPPS_WEB_CDN_FILES_URL}images/people/300x300/saami-abbas-300x300.jpg`;
const headofDevelopmentApac = `${SAPPS_WEB_CDN_FILES_URL}images/people/300x300/muhammad-zubair-300x300.jpg`;
const headofDesignApac = `${SAPPS_WEB_CDN_FILES_URL}images/people/300x300/sriram-karuthan-300x300.jpg`;
const headofBrandStrategyApac = `${SAPPS_WEB_CDN_FILES_URL}images/people/300x300/vinod-parameswaran-300x300.jpg`;
const directorPk = `${SAPPS_WEB_CDN_FILES_URL}images/people/300x300/sohail-ashraf-300x300.jpg`;
const directorSalesOperationsIn = `${SAPPS_WEB_CDN_FILES_URL}images/people/300x300/rohit-gulati-300x300.jpg`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AboutUsProps {}

/**
 * @param props
 */
export function AboutUs(props: AboutUsProps) {
  return (
    <section className="aboutus--page-content">
      <div className="aboutus--section">
        <Container>
          <Row className="justify-content-between">
            <Col>
              <h3>About Us</h3>
              <p>
                We are a team of digital transformation specialists helping
                businesses achieve their maximum potential through digital
                solutions. At the intersection of next generation technology,
                inspiration and change, the solutions we offer help our clients
                to streamline their operations and prepare for the demands of
                the future.
              </p>
              <p>
                We offer professional consulting and B2B enterprise application
                development services for a range of business operations
                including business process management, customer resource
                management, enterprise resource planning, security, automation,
                and more.
              </p>
              <p>
                We are experts at seamlessly integrating technlogy, strategy and
                implementation to successfully deliver even the most complex projects
                on time and under budget.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="aboutus--whyus">
        <div className="whyus-content">
          <Container>
            <Row className="justify-content-between">
              <Col lg="4" md="5">
                <h3 className="multi-level-heading small-underline">
                  <small>Why Snyder Technologies?</small>
                  Because you deserve the best every step of the way.
                </h3>
              </Col>
              <Col lg="6" md="7">
                <div className="whyus-feature">
                  <ul>
                    <li>
                      <h3>Care & Commitment </h3>
                      <p>
                        We are as meticulous as we are fast-paced. We are
                        committed to you and your vision, providing an
                        unparalleled experience that values people and
                        connection first.
                      </p>
                    </li>
                    <li>
                      <h3>Never Settle </h3>
                      <p>
                        We are pioneers, scientists, and artists with curious
                        minds and a passion for our profession. Continuous
                        learning propels us forward ensuring there is no
                        challenge we cannot collectively solve.
                      </p>
                    </li>
                    <li>
                      <h3>Inclusiveness </h3>
                      <p>
                        ”Can-do” attitude has no face. We are an organization
                        that cultivates talent from across the globe and prides
                        ourselves on our differences across borders. Together we
                        strive to make a positive impact on people, companies,
                        and the world.
                      </p>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="aboutus--team">
        <Container>
          <Row>
            <Col lg="8" md="10" className="mx-auto">
              <div className="team-content text-center">
                <h5>OUR LEADERSHIP TEAM</h5>
                <h2 className="text-white">
                  We’re strategists, designers, developers, managers, &
                  researchers united by our pioneering spirit.
                </h2>
              </div>
            </Col>
            <Col md="12">
              <div className="team-members-list">
                <Tabs defaultActiveKey="All" id="uncontrolled-tab-example">
                  <Tab eventKey="All" title="All">
                    <ul className="team-list">
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image src={ceoUsa} alt="Benjamin Snyder" />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Benjamin Snyder</h4>
                              <p>CEO</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/benpsnyder/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={headofManagedServicesUsa}
                              alt="Joe Marks"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Joe Marks</h4>
                              <p>Head of Managed Services (USA)</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/josephnmarks/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={executiveDirectorApac}
                              alt="Saami Abbas"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Saami Abbas</h4>
                              <p>Executive Director (APAC)</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/saamiabbas"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                ></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={directorofClientSuccessUsa}
                              alt="Adam Russek"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Adam Russek</h4>
                              <p>Director of Client Success (USA)</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/adamrussek/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={headofDevelopmentApac}
                              alt="Muhammad	Zubair"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Muhammad Zubair</h4>
                              <p>Head of Development (APAC)</p>
                            </div>
                            {/* <a
                              href="https://www.linkedin.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                ></path>
                              </svg>
                            </a> */}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image src={directorPk} alt="Sohail	Ashraf" />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Sohail Ashraf</h4>
                              <p>Director (PK)</p>
                            </div>
                            {/* <a
                              href="https://www.linkedin.com/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                ></path>
                              </svg>
                            </a> */}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={directorSalesOperationsIn}
                              alt="Rohit Gulati"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Rohit Gulati</h4>
                              <p>Director, Sales & Operations (IN)</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/rohitgulati80"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={headofBrandStrategyApac}
                              alt="Vinodh Parameswaran"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Vinodh Parameswaran</h4>
                              <p>Head of Brand Strategy (APAC)</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/vinodh-parameswaran-091b1251"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                ></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={headofDesignApac}
                              alt="Sriram Karuthan"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Sriram Karuthan</h4>
                              <p>Head of Design (APAC)</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/sriram-kr"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Tab>
                  <Tab eventKey="USA" title="USA">
                    <ul className="team-list">
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image src={ceoUsa} alt="Benjamin Snyder" />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Benjamin Snyder</h4>
                              <p>CEO</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/benpsnyder/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={headofManagedServicesUsa}
                              alt="Joe Marks"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Joe Marks</h4>
                              <p>Head of Managed Services (USA)</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/josephnmarks/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={directorofClientSuccessUsa}
                              alt="Adam Russek"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Adam Russek</h4>
                              <p>Director of Client Success (USA)</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/adamrussek/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Tab>
                  <Tab eventKey="Asia-Pacific" title="Asia-Pacific">
                    <ul className="team-list">
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={executiveDirectorApac}
                              alt="Saami Abbas"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Saami Abbas</h4>
                              <p>Executive Director (APAC)</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/saamiabbas"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                ></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={headofDevelopmentApac}
                              alt="Muhammad	Zubair"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Muhammad Zubair</h4>
                              <p>Head of Development (APAC)</p>
                            </div>
                            {/* <a
                              href="https://www.linkedin.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                ></path>
                              </svg>
                            </a> */}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image src={directorPk} alt="Sohail	Ashraf" />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Sohail Ashraf</h4>
                              <p>Director (PK)</p>
                            </div>
                            {/* <a
                              href="https://www.linkedin.com/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                ></path>
                              </svg>
                            </a> */}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={directorSalesOperationsIn}
                              alt="Rohit Gulati"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Rohit Gulati</h4>
                              <p>Director, Sales & Operations (IN)</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/rohitgulati80"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={headofBrandStrategyApac}
                              alt="Vinodh Parameswaran"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Vinodh Parameswaran</h4>
                              <p>Head of Brand Strategy (APAC)</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/vinodh-parameswaran-091b1251"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                ></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="team">
                          <div className="team-img">
                            <Image
                              src={headofDesignApac}
                              alt="Sriram Karuthan"
                            />
                          </div>
                          <div className="team-content">
                            <div className="about-me">
                              <h4>Sriram Karuthan</h4>
                              <p>Head of Design (APAC)</p>
                            </div>
                            <a
                              href="https://www.linkedin.com/in/sriram-kr"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.1577 11.3677V18.3764H15.0943V11.8374C15.0943 10.1955 14.5076 9.07429 13.0363 9.07429C11.9135 9.07429 11.2466 9.82916 10.952 10.5601C10.845 10.8213 10.8174 11.1841 10.8174 11.5504V18.3761H6.75366C6.75366 18.3761 6.80821 7.3012 6.75366 6.15479H10.8177V7.88667C10.8096 7.90031 10.798 7.91364 10.7908 7.92667H10.8177V7.88667C11.3578 7.05573 12.3208 5.86781 14.48 5.86781C17.1534 5.86781 19.1577 7.61454 19.1577 11.3677ZM2.5026 0.263672C1.11255 0.263672 0.203125 1.17613 0.203125 2.37496C0.203125 3.54834 1.08619 4.48716 2.44927 4.48716H2.47563C3.89295 4.48716 4.7742 3.54834 4.7742 2.37496C4.74723 1.17613 3.89295 0.263672 2.5026 0.263672ZM0.444648 18.3764H4.50692V6.15479H0.444648V18.3764Z"
                                  fill="#333333"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default AboutUs;
