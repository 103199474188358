import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import './partners.module.scss';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const accopian = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/accopian.svg`;
// Const nnr = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/nnr.svg`;
// const shipsights = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/shipsights.svg`;
const ipaas = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/ipaas.svg`;
const gtc = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/gtc.svg`;
const simpliship = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/simpliship.svg`;
const msfp = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/msfp.svg`;
const iwtv = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/iwtv.svg`;
// Const dispatchhealth = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/dispatch-health.svg`;
const hbp = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/hbp.svg`;
const redrook = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/redrook-main.svg`;
const shangy = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/shangy.svg`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PartnersProps {}

/**
 * @param props
 */
export function Partners(props: PartnersProps) {
  return (
    <div className="partners-inner">
      <h3>Our Clientele</h3>
      <Row className="justify-content-center">
        <Col lg="3" md="4" sm="6" xs="6">
          <div className="client-log">
            <Image src={gtc} />
          </div>
        </Col>
        <Col lg="3" md="4" sm="6" xs="6">
          <div className="client-log">
            <Image src={ipaas} />
          </div>
        </Col>
        <Col lg="3" md="4" sm="6" xs="6">
          <div className="client-log">
            <Image src={simpliship} />
          </div>
        </Col>
        <Col lg="3" md="4" sm="6" xs="6">
          <div className="client-log">
            <Image src={accopian} />
          </div>
        </Col>
        <Col lg="3" md="4" sm="6" xs="6">
          <div className="client-log">
            <Image src={iwtv} />
          </div>
        </Col>
        <Col lg="3" md="4" sm="6" xs="6">
          <div className="client-log">
            <Image src={msfp} />
          </div>
        </Col>
        <Col lg="3" md="4" sm="6" xs="6">
          <div className="client-log">
            <Image src={redrook} />
          </div>
        </Col>
        <Col lg="3" md="4" sm="6" xs="6">
          <div className="client-log">
            <Image src={hbp} />
          </div>
        </Col>
        <Col lg="3" md="4" sm="6" xs="6">
          <div className="client-log">
            <Image src={shangy} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Partners;
