import React from 'react';
import Helmet from 'react-helmet';
import custom from '../../../gatsby-custom';
import Layout from '../../layout';
import Header from '../../components/header/header';
import Msp from '../../components/msp/msp-landing-content';
import Footer from '../../components/footer/footer';
import MspBanner from '../../components/msp-banner/msp-banner';
import MspEscalatingTension from '../../components/msp-escalating-tension/msp-escalating-tension';
import MspPlatformCovers from '../../components/msp-platform-covers/msp-platform-covers';
import MspServices from '../../components/msp-services/msp-services';
import MspContact from '../../components/msp-contact/msp-contact';

const { SAPPS_WEB_MARKETING_URL } = process.env;
const { SAPPS_WEB_CDN_FILES_SNYDERONE_URL } = process.env;
const pageUrl = `${SAPPS_WEB_MARKETING_URL}/msp`;
const bannerImage = `${SAPPS_WEB_CDN_FILES_SNYDERONE_URL}banner-one.jpg`;
const platformCover = `${SAPPS_WEB_CDN_FILES_SNYDERONE_URL}platform-cover.png`;

/* eslint-disable-next-line */
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Snyder One | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content="Snyder One" />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Contact | ${custom.siteTitle}`} />
        <meta property="og:description" content="Snyder One" />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
      </Helmet>
      <div className="banner-white contact">
        <Header />
        <MspBanner
          captionTitle="Comprehensive Business Continuity Solutions"
          captionDesc="SMBs of 10-100 users are today’s primary targets of Phishing, Ransomware and Data Exfiltration schemes.  "
          innerImg={bannerImage}
        />
        <MspEscalatingTension
          title="Escalating global tensions"
          description="With escalating global tensions, and 0-day threats on the rise, businesses have to take a pro-active approach to protect and secure Intellectual property and maintain business continuity. "
        />
        <MspPlatformCovers
          title="SnyderOne platform covers "
          description="All aspects of business continuity, security and pro-active protection. "
          innerImg={platformCover}
        />
        <MspServices title="SnyderOne is predictably priced per device, with no hidden fees.  " />
        <MspContact title="Contact us if want to implement our SnyderOne solution for your business! " />
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
