import React from 'react';
import './job-description.scss';
import { Container, Row, Col } from 'react-bootstrap';
import './job-developer-php-india-2021.module.scss';
import JobForm from './jobs-form/job-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface JobDeveloperPhpIndia2021Props {}

/**
 * @param props
 */
export function JobDeveloperPhpIndia2021(props: JobDeveloperPhpIndia2021Props) {
  return (
    <section className="career-desc--page-content">
      <div className="career-desc--job-description">
        <Container>
          <div className="job-content">
            <Row>
              <Col md="12">
                <h3>Job Description</h3>
                <p>
                  We are looking for a PHP Developer responsible for managing
                  back-end services and the interchange of data between the
                  server and the users. Your primary focus will be the
                  development of all server-side logic, definition and
                  maintenance of the central database, and ensuring high
                  performance and responsiveness to requests from the front-end.
                  You will also be responsible for integrating the front-end
                  elements built by your co-workers into the application.
                  Therefore, a basic understanding of front-end technologies is
                  necessary as well
                </p>
              </Col>
              <Col md="12">
                <h3>Responsibilities</h3>
                <ul>
                  <li>
                    Integration of user-facing elements developed by front-end
                    developers
                  </li>
                  <li>Build efficient, testable, and reusable PHP modules</li>
                  <li>
                    Solve complex performance problems and architectural
                    challenges
                  </li>
                  <li>
                    Integration of data storage solutions - may include
                    databases, key-value stores, blob stores, etc.
                  </li>
                </ul>
              </Col>
              <Col md="12" className="mt-4">
                <h3>Skills & Qualifications</h3>
                <ul>
                  <li>7-10yrs experience required</li>
                  <li>Strong knowledge of PHP web frameworks</li>
                  <li>
                    Proficient understanding of code versioning tools, such as
                    Git
                  </li>
                  <li>An understanding of branch strategies like git-flow</li>
                  <li>Comfortable working in the Linux command line</li>
                  <li>
                    Strong knowledge of the common PHP or web server exploits
                    and their solutions
                  </li>
                  <li>
                    Understanding fundamental design principles behind a
                    scalable application
                  </li>
                  <li>
                    User authentication and authorization between multiple
                    systems, servers, and environments
                  </li>
                  <li>
                    Integration of multiple data sources and databases into one
                    system
                  </li>
                  <li>
                    Highly organized, results-oriented and attentive to details;
                    self-motivated, proactive, independent and responsive –
                    requires little supervisory attention
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <JobForm />
    </section>
  );
}

export default JobDeveloperPhpIndia2021;
