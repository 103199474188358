import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ServicesBenefits from '../../../components/services-benefits/services-benefits';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/search-engine-optimization.png`;
const BenefitsImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/seo-imgs.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/services/search-engine-optimization`;
const pageDescription =
  'Focus on things that matter by outsourcing the Earn more attention by making your online presence more visible.';
const pageTitle = 'Search Engine Optimization';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Services | ${pageTitle} | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={pageDescription} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Services | ${pageTitle} | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="services-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Services"
            subheading=""
            heading={pageTitle}
            content={pageDescription}
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    We optimize your website in a way it receives more web
                    traffic.
                  </h3>
                  <Row className="justify-content-between">
                    <Col>
                      <p>
                        Technology has evolved, so has marketing. If having a
                        business website gives your organization an online
                        presence, engaging with your target audience online is
                        so significant for your success. We leverage the power
                        of Search Engine Optimization (SEO) to get web traffic
                        to your website. Our team of digital marketing
                        specialists has profound experience in building
                        strategies to increase the visibility of websites by
                        considering how a search engine works, what the audience
                        search for, the keywords used for the searches, and the
                        search engines preferred by the audience.
                      </p>
                    </Col>
                  </Row>
                  <ServicesBenefits
                    image={BenefitsImage}
                    imageAlt={pageTitle}
                    listItems={[
                      'Website assessment before optimization and testing.',
                      'Greater focus on organic traffic.',
                      'Cost-effective as you don’t need to pay for the ads.',
                      'Gains more traffic than pay-per-click.',
                      'Provides a competitive edge.',
                    ]}
                  />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
