import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/service-truck-img.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/operational-excellence/field-service`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Operational Excellence | Field Service | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="OPERATIONAL EXCELLENCE"
            subheading=""
            heading="Field Service"
            content="Adopt modern technologies to optimize your field services for an enhanced customer experience."
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Redefine your field service with an outcome-based service
                    model.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        Field service operations have long been an integral part
                        of the tech industry that involves offering repair and
                        preventive services by deploying field service
                        technicians. Until recently, field service involves a
                        lot of operational difficulties in managing the field
                        workforce. Traditionally, huge human labor was required
                        to log data and monitor the services. Unpredictable
                        changes happen all the time and it took a lot of time
                        and money to track and address them. But, with evolving
                        technology, organizations are transforming their costly
                        break-fix service model into an efficient outcome-based
                        model.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        Snyder Technologies helps you manage your field services
                        better by offering you a performant outcome-based
                        service model. It takes advantage of AI, machine
                        learning, IoT, and data analytics to help you
                        consistently oversee the performance and status of your
                        systems and equipment, collect and analyze real-time
                        data, and optimize your strategies to curb the problems
                        even before they occur. This way you can maximize
                        uptime, reduce outages, and increase your profitability.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Modernized, proactive, and predictive field service',
                            'Easy transition from a break-fix model to an outcome-based model',
                            'Cloud-based solution with predictive analytics',
                            'Connected equipment for accurate data collection',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Efficient mobilization of field technicians',
                            'Greater visibility over the operations',
                            'Maximum uptime and reduced outages',
                            'Increased capability and performance',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
