import React, { Component } from 'react';
import Helmet from 'react-helmet';
import './index.scss';
import favicon from '../../static/logos/favicon.png';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

// eslint-disable-next-line react/prefer-stateless-function
export class Index extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className="page-fixed wrapper" id="scroll">
        <Helmet
          link={[
            {
              rel: 'shortcut icon',
              type: 'image/png',
              href: `${favicon as string}`,
            },
          ]}
        />
        {children}
      </div>
    );
  }
}

export default Index;
