import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ServicesBenefits from '../../../components/services-benefits/services-benefits';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/it-security.png`;
const BenefitsImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/it-security-imgs.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/services/it-security`;
const pageDescription =
  'Govern the accesses to your systems, networks, and data with robust security solutions.';
const pageTitle = 'IT Security Services';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Services | ${pageTitle} | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={pageDescription} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Services | ${pageTitle} | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="services-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Services"
            subheading=""
            heading={pageTitle}
            content={pageDescription}
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Protect your organizational assets from unauthorized access.
                  </h3>
                  <Row className="justify-content-between">
                    <Col>
                      <p>
                        With increasing incidents of cyberattacks, ensuring the
                        safety of organizational networks and data has become
                        more desperate than ever. Snyder Technologies offers
                        solutions that amalgamate need-specific services and
                        products to secure your assets. This provides you a
                        centralized control to govern access to data, computers,
                        and networks within your organization. From internet
                        security to network security to cloud security to
                        application security, we cover every aspect of your IT
                        infrastructure to ensure maximum security. After all, a
                        single breach can cost you dear.
                      </p>
                    </Col>
                  </Row>
                  <ServicesBenefits
                    image={BenefitsImage}
                    imageAlt={pageTitle}
                    listItems={[
                      'Improves your vulnerability and configuration management',
                      'Saves your assets from ransomware, spyware, and other viruses',
                      'Enables prompt incidence response and auto auditions',
                      'Identifies vulnerabilities and anticipates highly advanced threats',
                      'Automation for risk and vulnerability management',
                    ]}
                  />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
