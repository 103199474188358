import React from 'react';
import './job-description.scss';
import { Container, Row, Col } from 'react-bootstrap';
import './job-senior-sales-executive-india-2021.module.scss';
import JobForm from './jobs-form/job-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface JobSeniorSalesExecutiveIndia2021Props {}

/**
 * @param props
 */
export function JobSeniorSalesExecutiveIndia2021(
  props: JobSeniorSalesExecutiveIndia2021Props
) {
  return (
    <section className="career-desc--page-content">
      <div className="career-desc--job-description">
        <Container>
          <div className="job-content">
            <Row>
              <Col md="12">
                <h3>Job Description</h3>
                <p>
                  The Senior Sales Executive (ITMS) is responsible for leading
                  sales pursuits with Fortune 1000 new logo clients, SMEs and
                  selected current clients. Someone who has experience in
                  selling Remote Infrastructure Management, Application
                  Outsourcing or Managed Services
                </p>
              </Col>
              <Col md="12">
                <h3>Responsibilities</h3>
                <ul>
                  <li>
                    Responsible for Ownership of the sales cycle from territory
                    plan and client development through close
                  </li>
                  <li>
                    Work closely with solution and technology team to position
                    Snyder Technologies to achieve desired outcomes
                  </li>
                  <li>Expertise in Microsoft Technologies, B2B clients</li>
                  <li>Cloud -Azure/ AWS exposure required</li>
                  <li>
                    Builds and orchestrates sales pipeline activity. Ensures
                    active nurturing of deals and movement of opportunities to
                    closure.
                  </li>
                  <li>
                    Develop opportunities, lead, collaborate and motivate sales
                    pursuit teams across the company and its strategic partners
                  </li>
                </ul>
              </Col>
              <Col md="12" className="mt-4">
                <h3>Skills & Qualification</h3>
                <ul>
                  <li>
                    Self-Starter, impact player with proactive and aggressive
                    attributes
                  </li>
                  <li>
                    Strong consultative selling skills at the CXO/MD/VP level.
                    Applies industry domain, technology and IT operations best
                    practice knowledge as part of consultative selling to create
                    interest and extract details about key requirements and
                    issues and develop attractive value propositions and custom
                    solution(s) for one strategic or several large, complex
                    opportunities.
                  </li>
                  <li>
                    Strong learning aptitude, abilities to learn new domains,
                    technology solutions, service offerings and managed services
                    concepts, and deliver on it.
                  </li>
                  <li>
                    Hunger to win and challenge the status quo based on Snyder’s
                    market leading and disruptive solutions/services.
                  </li>
                  <li>
                    Entrepreneur spirit with smart leadership qualities,
                    ownership, and accountability
                  </li>
                  <li>
                    Ability to work in a fast paced, unstructured, limited
                    governance environment without losing focus on results
                  </li>
                  <li>
                    Ability to strategize, plan and execute sales plan. This
                    includes understanding and leveraging the industry
                    ecosystem, identifying, and staying committed to
                    named/target accounts, building strong relationships and
                    focused account mapping, planning and execution.
                  </li>
                  <li>
                    4-6 years of industry experience with strong communications
                    skills.
                  </li>
                  <li>Management degree in sales or marketing is preferred.</li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <JobForm />
    </section>
  );
}

export default JobSeniorSalesExecutiveIndia2021;
