import React, { Component } from 'react';
import './footer.scss';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { validateForm } from '../../assets/js/custom-validation';

const { SAPPS_WEB_API_URL } = process.env;
const { SAPPS_WEB_MAILTO } = process.env;
const { SAPPS_WEB_MARKETING_URL } = process.env;
const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const footerBg = `${SAPPS_WEB_CDN_FILES_URL}images/footer-bg.png`;
const facebook = `${SAPPS_WEB_CDN_FILES_URL}images/fb.svg`;
const twitter = `${SAPPS_WEB_CDN_FILES_URL}images/tw.svg`;
const linkedin = `${SAPPS_WEB_CDN_FILES_URL}images/in.svg`;
const stEmailLogo = `${SAPPS_WEB_CDN_FILES_URL}images/logo-email.png`;
const logoDark = `${SAPPS_WEB_CDN_FILES_URL}images/logo-colored.svg`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FooterProps {}

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      emailId: '',
      mailSent: false,
      error: null,
      isValidated: false,
    };
  }

  handleNewsLetterFormSubmit(event) {
    event.preventDefault();
    // Reset State
    this.setState({ error: null, mailSent: false });
    const { formEl } = this;
    const templateData = `
      <!doctype html>
      <html lang='en'>
      <head>
          <meta charset='UTF-8'><meta name='viewport' content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'>
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>New Project Enquiry</title>
          <style>
              .container {width: 100%; max-width: 1000px; margin: 0 auto; padding: 0 15px}
              table { width: 100%; }
              .enrollingTemplate th p {margin: 0; text-align: left; font-weight: normal}
              .enrollingTemplate h2 {margin: 50px 0 25px}
              .enrollingTemplate tbody p {margin: 0; line-height: 23px}
              .enrollingTemplate tbody p+p {margin: 15px 0 0;}
              .enrollingTemplate ul {padding-left: 15px}
              .enrollingTemplate ul li {margin: 10px 0 0}
              .enrollingTemplate ul li ul {padding-left: 25px; list-style: disc}
              .enrollingTemplate ul li ul li {margin: 5px 0 0}
              .d-flex {display: flex; margin: 50px 0 25px; width: 100%}
              .double-img img { margin: 15px; max-width: 40%; max-height: 150px }
              p { padding-bottom: 15px; }
              .vcolor{ color: #28b6ea; }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="enrollingTemplate">
                  <p>Dear Snyder Team,<br><br>You are receiving this because someone subscribed from the website Newsletter. <a href="${SAPPS_WEB_MARKETING_URL}">${SAPPS_WEB_MARKETING_URL}</a></p>
                  <table border=1>
                      <tbody>
                        <tr><td><b>First Name*</b></td><td><span class="vcolor">[FirstName]</span></td></tr>
                        <tr><td><b>Last Name*</b></td><td><span class="vcolor">[LastName]</span></td></tr>
                        <tr><td><b>Your email address*</b></td><td><span class="vcolor">[EmailId]</span></td></tr>
                      </tbody>
                  </table>
                  <p>Regards, Snyder Team<br><br><img src="${stEmailLogo}" width="15%" alt="Snyder Technologies"/></p>
              </div>
          </div>
      </body>
      </html>`;
    // If form validate
    if (validateForm(formEl)) {
      const html = templateData
        .replace('[FirstName]', this.state.firstName)
        .replace('[LastName]', this.state.lastName)
        .replace('[EmailId]', this.state.emailId);

      const data = {
        eSAPPS_WEB_MAILTO: SAPPS_WEB_MAILTO,
        emailSubject: 'New Newsletter Subscriber - Snyder Marketing Website',
        emailTemplate: html,
      };

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${SAPPS_WEB_API_URL}/Referral/SubmitNewsLetterSubscriber`,
          headers: { 'content-type': 'application/json' },
          data,
        })
          .then((result) => {
            if (result.data.message) {
              this.setState({
                emailId: '',
                mailSent: true,
                isValidated: false,
              });
            } else {
              this.setState({ error: result.data.error });
            }
          })
          .catch((error) => {
            this.setState({ error: error.message });
          });
      });
    }

    this.setState({ isValidated: true });

    return false;
  }

  render() {
    if (typeof window !== 'undefined') {
      const setActive = (element, active) => {
        const formField = element.parentNode.parentNode;
        if (active) {
          formField.classList.add('form-field--is-active');
        } else {
          formField.classList.remove('form-field--is-active');
          element.value === ''
            ? formField.classList.remove('form-field--is-filled')
            : formField.classList.add('form-field--is-filled');
        }
      };

      Array.prototype.forEach.call(
        document.querySelectorAll('.inputMod'),
        (element) => {
          element.addEventListener('blur', () => {
            setActive(element, false);
          });

          element.addEventListener('focus', () => {
            setActive(element, true);
          });
        }
      );
    }

    const classNames = [];
    if (this.state.isValidated) {
      classNames.push('was-validated');
    }

    return (
      <footer>
        <div className="footer-banner">
          <div className="footer-banner-image">
            <img src={footerBg} alt="" />
          </div>
          <div className="footer-banner-content-wrap">
            <div className="container">
              <div className="footer-banner-content">
                <h3>
                  <span>Thinking of Digital Transformation?</span>
                  We combine best-practice methodology and experience and take
                  you on a journey.
                </h3>
                <a className="btn-border btn-orange" href="/contact">
                  Let&apos;s Talk
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-wrapper">
          <div className="container">
            <div className="footer-main">
              <div className="footer-desc">
                <div className="logo-footer">
                  <a href="/">
                    <img src={logoDark} alt="Snyder Technologies" />
                  </a>
                </div>
                <p>
                  <strong>
                    We Are a Client-Obsessed <br /> Digital Transformation
                    Partner
                  </strong>
                </p>
              </div>
              <div className="footer-menu-col">
                <div className="footer-menu">
                  <h5>Company</h5>
                  <ul>
                    <li>
                      <a href="/about-us">About us</a>
                    </li>
                    <li>
                      <a href="/careers">Careers</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                    {/*
                    <li>
                      <a href="#!" className="link-diabled">
                        Blogs
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="link-diabled">
                        Whitepapers
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="link-diabled">
                        Thought Leadership
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="link-diabled">
                        We Work With
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="link-diabled">
                        FAQs
                      </a>
                    </li>
                    */}
                    <li>
                      <a href="/terms-of-use">Terms of Use</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu">
                  <h5>Solutions</h5>
                  <ul>
                    <li>
                      <a href="/solutions/information">Information</a>
                    </li>
                    <li>
                      <a href="/solutions/experience">Experience</a>
                    </li>
                    <li>
                      <a href="/solutions/operational-excellence">
                        Operational Excellence
                      </a>
                    </li>
                    <li>
                      <a href="/solutions/innovation">Innovation</a>
                    </li>
                    <li>
                      <a href="/solutions/collaboration">Collaboration</a>
                    </li>
                    <li>
                      <a href="/solutions/infrastructure-modernization">
                        Infrastructure <br />
                        Modernization
                      </a>
                    </li>
                    {/*
                    <li>
                      <a href="#!" className="link-diabled">
                        Blogs
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="link-diabled">
                        Whitepapers
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="link-diabled">
                        Thought Leadership
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="link-diabled">
                        We Work With
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="link-diabled">
                        FAQs
                      </a>
                    </li>
                    */}
                  </ul>
                </div>
                <div className="footer-menu">
                  <h5>Technology</h5>
                  <ul>
                    <li>
                      <a href="/technology/microsoft-365">Microsoft 365</a>
                    </li>
                    <li>
                      <a href="/technology/microsoft-azure">Microsoft Azure</a>
                    </li>
                    <li>
                      <a href="/technology/microsoft-power-platform">
                        Microsoft Power Platform
                      </a>
                    </li>
                    <li>
                      <a href="/technology/microsoft-dynamics-365">
                        Microsoft Dyanmics 365
                      </a>
                    </li>
                    <li>
                      <a href="/technology/snyder-products">Snyder Products</a>
                    </li>
                    <li>
                      <a href="/technology/strategic-alliances">
                        Strategic Alliances
                      </a>
                    </li>
                    {/*
                    <li>
                      <a href="#!" className="link-diabled">
                        Blogs
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="link-diabled">
                        Whitepapers
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="link-diabled">
                        Thought Leadership
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="link-diabled">
                        We Work With
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="link-diabled">
                        FAQs
                      </a>
                    </li>
                    */}
                  </ul>
                </div>
                {/*
                <div className="footer-menu ">
                  <h5>Services</h5>
                  <div className="two-menu">
                    <div className="menu">
                      <ul>
                        <li>
                          <a href="#!" className="link-diabled">
                            IT Consulting
                          </a>
                        </li>
                        <li>
                          <a href="#!" className="link-diabled">
                            Managed IT Services
                          </a>
                        </li>
                        <li>
                          <a href="#!" className="link-diabled">
                            Cloud Services
                          </a>
                        </li>
                        <li>
                          <a href="#!" className="link-diabled">
                            IT Security Services
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="menu">
                      <ul>
                        <li>
                          <a href="#!" className="link-diabled">
                            Software Development
                          </a>
                        </li>
                        <li>
                          <a href="#!" className="link-diabled">
                            Mobile Apps
                          </a>
                        </li>
                        <li>
                          <a href="#!" className="link-diabled">
                            Web Development
                          </a>
                        </li>
                        <li>
                          <a href="#!" className="link-diabled">
                            DevOps
                          </a>
                        </li>
                        <li>
                          <a href="#!" className="link-diabled">
                            AI & ML
                          </a>
                        </li>
                        <li>
                          <a href="#!" className="link-diabled">
                            IoT
                          </a>
                        </li>
                        <li>
                          <a href="#!" className="link-diabled">
                            UX/UI Design
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                */}
              </div>
              <div className="footer-form">
                {/*
                <h4>
                  Subscribe to our newsletter. <span />
                </h4>
                <form
                  method="post"
                  action="#"
                  ref={(form) => {
                    this.formEl = form;
                  }}
                  className={classNames}
                  noValidate
                >
                  {this.state.mailSent && (
                    <div className="alert-success alert text-center mb-5">
                      Thank You For Subscribing!
                    </div>
                  )}
                  {this.state.error && (
                    <div className="alert-danger error alert text-center  mb-5">
                      {this.state.error}
                    </div>
                  )}
                  <div className="form-group">
                    <label>
                      Stay informed about where IT is headed towards.
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      placeholder="First Name*"
                      className="form-control"
                      required
                      value={this.state.firstName}
                      whenChange={(e) => {
                        this.setState({ firstName: e.target.value });
                      }}
                    />
                    <div className="invalid-feedback" />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Last Name*"
                      className="form-control"
                      required
                      value={this.state.lastName}
                      whenChange={(e) => {
                        this.setState({ lastName: e.target.value });
                      }}
                    />
                    <div className="invalid-feedback" />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="emailId"
                      placeholder="Your Email Address*"
                      required
                      value={this.state.emailId}
                      className="form-mod form-control"
                      whenChange={(e) => {
                        this.setState({ emailId: e.target.value });
                      }}
                    />
                    <div className="invalid-feedback" />
                  </div>
                  <Button
                    variant="border"
                    type="submit"
                    onClick={(e) => this.handleNewsLetterFormSubmit(e)}
                  >
                    Subscribe
                  </Button>
                </form>*/}
                <ul className="social">
                  {/*
                  <li>
                    <a
                      href="https://www.facebook.com/snydertechnolgies"
                      target="_blank"
                    >
                      <img src={facebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/snyder_tech" target="_blank">
                      <img src={twitter} alt="" />
                    </a>
                  </li>
                  */}
                  <li>
                    <a
                      href="https://www.linkedin.com/company/snydertechnologies"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-copyright">
              &copy; Snyder Technologies. All Rights Reserved.
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
