import React from 'react';
import './index.module.scss';
import '../index.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ListCard from '../../../components/list-card/list-card';
import { Strings } from '../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/partnerships/microsoft-silver-partner-logo-2021.png`;
const pageTitle = 'Strategic Partnerships';
const pageDescription =
  'Snyder works with our strategic partners to create industry-leading platforms that enable digital reinvention and position you to thrive in the modern era.';
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/technology/strategic-alliances`;

const acronis = `${SAPPS_WEB_CDN_FILES_URL}images/partnerships/acroniscyberprotect-logo.png`;
const threecx = `${SAPPS_WEB_CDN_FILES_URL}images/partnerships/3cx-logo.png`;
const ninjarmm = `${SAPPS_WEB_CDN_FILES_URL}images/partnerships/ninjarmm-logo.png`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Technology | ${pageTitle} | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={pageDescription} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Technology | ${pageTitle} | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="technology" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Technology"
            subheading=""
            heading="Strategic Alliances"
            content={pageDescription}
            innerImg={bannerImage}
          />
          <section className="generic--page-content ">
            <div className="generic--section technology--section no-pad-bottom">
              <Container>
                <div className="generic--container technology--container no-after">
                  <Row className="justify-content-between">
                    <Col>
                      <h3>Microsoft Silver Partner</h3>
                      <p>
                        As an annually certified{' '}
                        <strong>Microsoft Silver Partner</strong>, we are
                        continually aiming to advance enterprise-level
                        innovations to all our clients. Additionally, we are an
                        authorized{' '}
                        <strong>Cloud Solution Provider (CSP)</strong> allowing
                        Snyder to directly sell cloud solutions to our
                        customers, including volume and yearly discounts on
                        licensing.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
          <section className="generic--page-content bg-blue pad-mob">
            <div className="generic--section">
              <Container>
                <div className="container-white text-center">
                  <Row className="justify-content-between">
                    <Col lg="8" className="offset-md-2">
                      <h3>Our Partners</h3>
                      <p>
                        Each year Microsoft evaluates our staff, our project
                        history, and our customer references. These come
                        together as the basis for Microsoft’s competency
                        designations. We are proud that our staff maintains
                        relevant certifications, validating our knowledge and
                        expertise. At Snyder we are dedicated to continually
                        staying up to date on the latest Microsoft product
                        innovations and technologies. As your Microsoft
                        solutions provider, we gain full understanding of new
                        products before they are released and safely provide
                        innovation to organizations we support.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
          <section className="generic--page-content">
            <div className="generic--section overlap-top">
              <Container>
                <div className="generic--container">
                  <Row>
                    <Col lg="4" md="4" sm="6" xl="4">
                      <ListCard
                        cardImg={acronis}
                        cardTitle="Acronis Cyber PRotect"
                        content={
                          <p className="text-center">
                            Modernize your cybersecurity and backup with
                            integrated cyber protection. Our backup solution
                            powered by Acronis Cyber Protect is the only
                            solution that natively integrates cybersecurity,
                            data protection and management to protect endpoints,
                            systems and data. Integration and automation provide
                            unmatched protection – increasing productivity while
                            decreasing TCO. With on-site and cloud-based storage
                            your data is encrypted, and geo-redundant.
                          </p>
                        }
                      />
                    </Col>
                    <Col lg="4" md="4" sm="6" xl="4">
                      <ListCard
                        cardImg={ninjarmm}
                        cardTitle="Ninja Remote Monitoring and Management"
                        content={
                          <p className="text-center">
                            Endpoint Data at your fingertips. Our Ninja product
                            provides intuitive insight, and endpoint management
                            software. Built for small to medium businesses with
                            an exceptional user experience and all the support
                            you need to deliver fast and effective IT
                            management. Maximize uptime, keep employees happy
                            and ticket volumes low by identifying and
                            remediating issues before end users report them.
                            Create custom alerts and get notified when endpoint
                            issues arise so you can proactively address problems
                            before end users notice them. There’s even an app!
                          </p>
                        }
                      />
                    </Col>
                    <Col lg="4" md="4" sm="6" xl="4">
                      <ListCard
                        cardImg={threecx}
                        cardTitle="3CX"
                        content={
                          <p className="text-center">
                            Choosing a phone system for your business requires
                            careful consideration. You want it to serve your
                            business well, now and into the future without
                            breaking the bank in the process. 3CX is an
                            open-platform office phone system that runs on
                            premise on Windows or Linux, with the option to
                            migrate to cloud with a simple backup and restore.
                            Save money and deploy on an existing server or opt
                            for a low-cost mini-PC appliance or Raspberry Pi.
                            Build your own 3CX solution with your choice of IP
                            phones and SIP trunks. Offer a true remote working
                            experience to any employee with an internet
                            connection, and see productivity increase with
                            advanced call center features, video conferencing
                            and more.
                          </p>
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
