import React, { Component } from 'react';
import './msp-banner.scss';
import { Image } from 'react-bootstrap';

export interface MspBannerProps {
  innerImg?: string;
  captionTitle?: string;
  captionDesc?: string;
}

class MspBanner extends Component<MspBannerProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { captionTitle, captionDesc, innerImg } = this.props;

    return (
      <section className="mspBannerContainer">
        <section className="container">
          <section className="mspbannerInnerSec">
            <section className="mspBannerContentSec">
              <h2>{captionTitle}</h2>
              <p>{captionDesc}</p>
              <a className="btn btn-blue" href="javascript:void(0);">
                Contact Us
              </a>
            </section>
            <section className="mspBannerimgSec vectorShapSp">
              <Image src={innerImg} />
              <svg
                className="rightBottom"
                width="72"
                height="72"
                viewBox="0 0 72 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M72 72H0L72 0V72Z" fill="#137091" />
              </svg>
              <svg
                className="topRight"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M40 40H0L40 0V40Z" fill="#1AA6D7" />
              </svg>
              <svg
                className="leftBottom"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 40H40L0 0V40Z" fill="#1AA6D7" />
              </svg>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default MspBanner;
