import React from 'react';
import './solutions.module.scss';
import { Container, Row, Col, Image, Tabs, Tab } from 'react-bootstrap';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SolutionsProps {}

/**
 * @param props
 */
export function Solutions(props: SolutionsProps) {
  return (
    <section className="generic--page-content">
      <div className="generic--section solutions--section">
        <Container>
          <div className="generic--container solutions--container">
            <h3>
              Customized application development tailored for your business
              needs.
            </h3>
            <Row className="justify-content-between">
              <Col>
                <p>
                  A robust application for your business isn’t just a one-time
                  requirement but a commitment that continues throughout your
                  journey. Because every organization is unique and keeps on
                  evolving. While it might be easier to choose off-the-self
                  packages to meet your immediate needs, it has limitations to
                  meet the requirements of your continuously-evolving business.
                  That’s why Snyder Technologies brings you more customized
                  application development services to support and scale up your
                  business.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Solutions;
