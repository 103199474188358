/**
 * @description Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    const hash_ = hash.replace('#', '');
    setTimeout(() => {
      document.getElementById(hash_).focus();
    }, 50);
  }
};
