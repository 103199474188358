import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/data-analytics.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/information/data-analytics`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Information | Data & Analytics | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Solutions"
            subheading=""
            heading="Data & Analytics"
            content="Making Artificial Intelligence technologies more efficient by unlocking the power of analytics."
            innerImg={bannerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                  Leverage analytics to better understand your data and provide greater insight for critical decisions.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        Today businesses generate a huge amount of data, most of which remains uncaptured, or unused and
                        unstructured. Your business generates billions of insightful data points from customer transactions,
                        to network devices, vendor requests - all of which can be leveraged to understand more about the
                        health of your company. We help business leaders organize and understand their data to make better
                        tactical and strategic decisions. Streamline your supply chain by leveraging emerging AI and
                        automation technologies.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        The team at Snyder has been helping organizations gain a competitive advantage for more 20+ years and
                        we’ve learned a lot in that time. We can help you break away from your competition using advanced
                        analytics and analysis that will result in better business decisions across your company.
                        Gain knowledge and visibility from your data that can be used to create more tailored products and
                        service offerings - drastically increasing the value of the overall customer experience.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Audience research and analysis',
                            'Data generation and examination',
                            'Testing and validation',
                            'Insight generation and application',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Stategies to organize data',
                            'Analytics insights',
                            'Streamlined operations',
                            'Enhanced experience',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
