import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/processing-img.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/operational-excellence/finance-operations-erp`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Operational Excellence | Finance & Operations | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="OPERATIONAL EXCELLENCE"
            subheading=""
            heading="Finance & Operations | ERP"
            content="Unify your business operations for maximum productivity and profitability."
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Bringing all your processes inside a single-window gives
                    your greater clarity and control.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        For an enterprise to successfully run its business, it
                        should be able to manage all of its processes. While
                        each department has its own systems customized for its
                        specific functionality, managing them could be
                        challenging. Snyder Technologies offers a centralized
                        cloud-based ERP solution, powered by Microsoft Dynamics
                        365 Unified Operations that will enable you to manage
                        and integrate all the processes needed to run your
                        business such as operations, planning, inventory
                        purchase, and management, marketing, sales, human
                        resources, finance, and much more.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        Leveraging cloud-based ERP solution from industry
                        experts leads to the digital transformation of your
                        business that not only streamline your processes but
                        also prepare you for the future demands. We are an
                        official Microsoft partner, with more than a decade of
                        experience and expertise in implementing Microsoft
                        solutions for businesses around the world. We help
                        businesses migrate to the cloud, which significantly
                        increased productivity, stability, and profitability.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Centralized control of business processes',
                            'Digital transformation via cloud solutions',
                            'Streamlined and automated operations',
                            'Remote access to information and systems',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Seamless integration and communication',
                            'Modern ERP features and tools',
                            'Increased productivity and consistency',
                            'Post-implementation support',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
