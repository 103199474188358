import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/user-experience.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/experience/user-experience`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Experience | User Experience | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="EXPERIENCE"
            subheading=""
            heading="User Experience"
            content="Offer your users an intuitive design that wins their loyalty."
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Enhance the way your audience interact with your products
                    and services
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        Building a beautiful and intuitive user experience
                        requires a deep understanding of the users. By knowing
                        how their typical day would be, their needs, and
                        limitations, you can come up with strategies to build
                        design solutions that make lasting impressions. Because
                        researches show that UX and UI design have a significant
                        impact on the users’ experience. That’s why our UX
                        researchers and designers help you to build elegant and
                        functional experiences that distinguish your products
                        and services.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        Our approach to designing a user experience is
                        comprehensive. We research your audience and understand
                        their needs before we come up with strategies, keeping
                        in mind your business goals. While optimizing the
                        experience will enhance the user interaction, it also
                        leads to greater customer retention and brand awareness.
                        Establishing a definitive design system will navigate
                        the organizations in development, without the need to
                        start from scratch every time. We bring together
                        world-class user experience insights and top-notch
                        software development practices to help your business to
                        scale up.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Market Need"
                          listItems={[
                            'Deep understanding of users',
                            'World-class design practices',
                            'Design strategies aligned with business goals',
                            'Design system for effective development',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Solution"
                          listItems={[
                            'Customer retention and brand awareness',
                            'Seamless transfer between design and development',
                            'Streamlined processes and workflows',
                            'Reduction in development costs',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
