import React, { useRef } from 'react';
import './index.module.scss';
import './index.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import EmailCampaignsForm from '../../../components/email-campaigns-form/email-campaigns-form';

const { SAPPS_WEB_MARKETING_URL } = process.env;
const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const pageUrl = `${SAPPS_WEB_MARKETING_URL}/campaigns/it-solutions`;
const headingBg = `${SAPPS_WEB_CDN_FILES_URL}images/itmsp-usa/itmsp-usa-landing-header.png`;
const accopian = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/accopian.svg`;
const ipaas = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/ipaas.svg`;
const gtc = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/gtc.svg`;
const simpliship = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/simpliship.svg`;
const msfp = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/msfp.svg`;
const iwtv = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/iwtv.svg`;
const hbp = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/hbp.svg`;
const redrook = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/redrook-main.svg`;
const shangy = `${SAPPS_WEB_CDN_FILES_URL}images/client-logos/shangy.svg`;
const disasterRecovery = `${SAPPS_WEB_CDN_FILES_URL}images/marketing-landing-page/icons/disaster-recovery.png`;
const itConsulting = `${SAPPS_WEB_CDN_FILES_URL}images/marketing-landing-page/icons/it-consulting.png`;
const itSecurityServices = `${SAPPS_WEB_CDN_FILES_URL}images/marketing-landing-page/icons/it-security-services.png`;
const managedItServices = `${SAPPS_WEB_CDN_FILES_URL}images/marketing-landing-page/icons/managed-it-services.png`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  /**
   * @param e
   */
  function handleOnClick(e) {
    e.preventDefault();
    document.getElementById('lp_first_name').focus();
  }

  return (
    <Layout>
      <Helmet title={`IT Solutions and Consulting | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta
          name="description"
          content="Expert solutions and consulting for your IT strategy Snyder is ready to assist you. Microsoft Certified Silver Partner Achieve more with innovative Office apps, intelligent cloud services, and world-class security."
        />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`IT Solutions and Consulting | ${custom.siteTitle}`}
        />
        <meta
          property="og:description"
          content="Expert solutions and consulting for your IT strategy Snyder is ready to assist you. Microsoft Certified Silver Partner Achieve more with innovative Office apps, intelligent cloud services, and world-class security."
        />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
      </Helmet>
      <div className="banner-white contact">
        <Header />
        <div className="home itmsp-usa-header">
          <div className="container" id="itmsp-usa--header">
            <div className="row">
              <div className="col cta-pitch">
                <h2>
                  Expert solutions and consulting
                  <br />
                  for your IT strategy
                </h2>
                <h3>Snyder is ready to assist you</h3>
                <ul>
                  <li>
                    Microsoft Certified Silver Partner
                    <br />
                    <span className="pitch-detail">
                      Achieve more with innovative Office apps, intelligent
                      cloud services, and world-class security - We are your
                      "go-to" for all things Microsoft!
                    </span>
                  </li>
                  <li>
                    Avoid Disaster and recover from the unavoidable
                    <br />
                    <span className="pitch-detail">
                      Back-up your critical data to a secure location - our
                      solution offers flexibilty and control of frequency,
                      storage costs, and data recovery.
                    </span>
                  </li>
                  <li>
                    Communications and Remote Work
                    <br />
                    <span className="pitch-detail">
                      Support in-office and remote communications with VoIP
                      phone systems Microsoft Teams and other creative WFH
                      solutions to keep you connected and productive regardless
                      of your location!
                    </span>
                  </li>
                  <li>
                    Remote Management & Automation
                    <br />
                    <span className="pitch-detail">
                      Monitor and manage devices and services from Windows, Mac,
                      networking hardware, web servers, email servers, DNS
                      records and more with a proactive approach that makes IT
                      management easier.
                    </span>
                  </li>
                </ul>
              </div>
              <EmailCampaignsForm />
            </div>
          </div>
        </div>
        <div className="container-fluid client-examples">
          <div className="container">
            <div className="row">
              <div className="col mb-3">
                <h3>Some of Our Amazing Clients</h3>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row partner-logos">
              <div className="partnerLogoBlock">
                <img src={gtc} alt="Glemser" />
              </div>
              <div className="partnerLogoBlock">
                <img src={accopian} alt="Acopian" />
              </div>
              <div className="partnerLogoBlock">
                <img src={iwtv} alt="IWTV" />
              </div>
              <div className="partnerLogoBlock">
                <img src={hbp} alt="Historic Bethlehem" />
              </div>
              <div className="partnerLogoBlock">
                <img src={shangy} alt="Shangy" />
              </div>
            </div>
          </div>
        </div>
        <div className="aboutus--section">
          <Container>
            <Row className="justify-content-between text-center">
              <Col>
                <h3>IT Services</h3>
              </Col>
            </Row>
            <div className="list-container serviceBlockHolder">
              <div className="serviceBlock">
                <div className="list-card">
                  <div className="lc-title border-bottom-0 lp-title">
                    <a href="/services/it-security" target="_blank">
                      <img
                        src={itSecurityServices}
                        alt="IT Security Services"
                      />
                      IT Security Services
                    </a>
                    <p>
                      Protect your applications, infrastructure, and data from
                      cyber threats.
                    </p>
                  </div>
                </div>
              </div>
              <div className="serviceBlock">
                <div className="list-card">
                  <div className="lc-title border-bottom-0 dr-box lp-title">
                    <a href="/services/disaster-recovery" target="_blank">
                      <img src={disasterRecovery} alt="Disaster Recovery" />
                      Disaster Recovery
                    </a>
                    <p>
                      Regain access and control over your systems after
                      unfavorable events.
                    </p>
                  </div>
                </div>
              </div>
              <div className="serviceBlock">
                <div className="list-card">
                  <div className="lc-title border-bottom-0 lp-title">
                    <a href="/services/managed-it-services" target="_blank">
                      <img src={managedItServices} alt="Managed IT Services" />
                      Managed IT Services
                    </a>
                    <p>
                      Keep your systems up with proactive remote monitoring and
                      management solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="serviceBlock">
                <div className="list-card">
                  <div className="lc-title border-bottom-0 lp-title">
                    <a href="/services/it-consulting" target="_blank">
                      <img src={itConsulting} alt="IT Consulting" />
                      IT Consulting
                    </a>
                    <p>
                      Get expert advice and strategies to make your business
                      more successful.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Row className="justify-content-between text-center mt-5 mb-4">
              <Col>
                <button
                  type="submit"
                  className="btn-danger btn-lg btn"
                  onClick={handleOnClick}
                >
                  Talk to an Expert
                </button>
              </Col>
            </Row>
            <Row className="justify-content-between text-center">
              <Col>
                <hr />
                <h3 className="mt-4 mb-4">Who We Are</h3>
                <p>
                  We are a team of digital transformation specialists helping
                  businesses achieve their maximum potential through digital
                  solutions. At the intersection of next generation technology,
                  inspiration and change, the solutions we offer help our
                  clients to streamline their operations and prepare for the
                  demands of the future market.
                </p>
                <p>
                  We offer professional consulting and B2B enterprise
                  application development services for a range of business
                  operations including business process management, customer
                  resource management, enterprise resource planning, security,
                  automation, among many others.
                </p>
                <p>
                  Our expertise lies in our technology strategy and
                  implementation. We believe in beautifully architected
                  technical solutions delivered by highly experienced,
                  cross-functional teams from analytical, creative, and
                  technical backgrounds to reach shared goals.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
