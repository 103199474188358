import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Strings } from '../../../languages/en';
import custom from '../../../gatsby-custom';
import Layout from '../../layout';
import Header from '../../components/header/header';
import Services from '../../components/services/services';
import Footer from '../../components/footer/footer';
import BannerInner from '../../components/banner-inner/banner-inner';

const { SAPPS_WEB_MARKETING_URL } = process.env;
const pageUrl = `${SAPPS_WEB_MARKETING_URL}/services`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Services | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Services | ${custom.siteTitle}`} />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Services"
            subheading=""
            heading="Services"
            content="Snyder Technologies helps you to attain your business goals through customer-centric IT services."
          />
          <Services />
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
