import React from 'react';

import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Strings } from '../../../../languages/en';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import Partners from '../../../components/partners/partners';
import ListCard from '../../../components/list-card/list-card';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/cloud-hosting-cuate.png`;
const migrationCol = `${SAPPS_WEB_CDN_FILES_URL}images/migration-col.png`;
const cyberSecCole = `${SAPPS_WEB_CDN_FILES_URL}images/cyber-security-col.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/infrastructure-modernization`;
const pageOgImage = `${SAPPS_WEB_CDN_FILES_URL}images/snydertech-og-02.jpg`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Infrastructure Modernization| ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content={pageOgImage} />
        <meta name="twitter:image" content={pageOgImage} />
        <meta property="og:image:alt" content="Snyder Technologies, Together, let's reimagine the future of your business." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Information | ${custom.siteTitle}`} />
        <meta name="twitter:description" content={Strings.aboutUs} />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="SOLUTIONS"
            subheading=""
            heading="Infrastructure Modernization"
            content="Shifting your IT infrastructure to the cloud has a lot of benefits."
            innerImg={bannerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <Row className="justify-content-between">
                    <Col>
                      <h3>
                        Prepare for the future by choosing cloud-based
                        infrastructe solutions.
                      </h3>
                      <p>
                        With organizations increasingly seeking a competitive edge,
                        relying on traditional IT infrastructure won’t help you to
                        stay ahead. Cloud computing offers modern businesses
                        flexibility, efficiency, scalability, security and increased
                        collaboration. Most importantly, cloud computing will reduce
                        the cost of managing and maintaining your IT systems,
                        reducing both capital expenditures and annual budgets.
                      </p>
                      <p>
                        Cloud computing allows employees to be more flexible in their
                        work practices. For example, you have the ability to access
                        data from home, on holiday, or via the commute to and from work
                        (providing you have an internet connection.) If you need access
                        to your data while you are off-site, you can connect to your
                        virtual office, quickly and easily.
                      </p>
                      <p>
                        Collaboration in a cloud environment gives your business the
                        ability to communicate and share more easily outside of the
                        traditional methods. If you are working on a project across
                        different locations, you could use cloud computing to give
                        employees, contractors and third parties access to the same
                        files. You could also choose a cloud computing model that makes
                        it easy for you to share your records with your advisers
                        (such as an accountant, adviser or regulator.)
                      </p>
                      <p>
                        Often businesses must scale operations up or down and your infrastructure
                        and storage needs must quickly adjust to suit your situation.
                        Cloud based infrastructure enables flexibility as your needs change.
                        Rather than purchasing and installing expensive upgrades yourself, your
                        cloud computer service provider can handle this for you. Using the cloud
                        frees up your time so you can get on with running your business.
                      </p>
                      <p>
                        Most significantly, cloud hosting comes with an established infrastructure
                        with multiple layer security; data, network, application and physical security.
                        Cloud service providers also provide secure and encrypted solutions,
                        backup recovery, firewalls, identity management and data isolation and
                        storage segregation. The team at Snyder can help you plan and execute the
                        move to cloud based infrastructure to ensure your company stays competitive.
                      </p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={migrationCol}
                        cardTitle="Cloud Migration & Enablement"
                        cardLink="/solutions/infrastructure-modernization/cloud-migration-enablement"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={cyberSecCole}
                        cardTitle="Cybersecurity & Identity"
                        cardLink="/solutions/infrastructure-modernization/cybersecurity-identity"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                  </Row>

                  <Partners />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
