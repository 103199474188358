import React from 'react';

import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import { Strings } from '../../../../languages/en';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import Partners from '../../../components/partners/partners';
import ListCard from '../../../components/list-card/list-card';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/connecting-teams-cuate.png`;
const workplaceCol = `${SAPPS_WEB_CDN_FILES_URL}images/workplace-col.png`;
const wirelesspCol = `${SAPPS_WEB_CDN_FILES_URL}images/wireless-connection-col.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/collaboration`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Solutions | Collaboration| ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="SOLUTIONS"
            subheading=""
            heading="Collaboration"
            content="Facilitate the interaction between your teams for better coordination and productivity"
            innerImg={bannerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <Row className="justify-content-between">
                    <Col>
                      <h3>
                        Collaboration – a strategy to work toward a common goal.
                      </h3>
                      <p>
                        With organizations increasingly adapting to remote work
                        and a hybrid workforce, the need for robust
                        collaborative technology has become desperate. While
                        collaboration platforms enabled the possibility of
                        remote work, organizations should focus on long-term
                        solutions that will not just serve as a medium to
                        message and share documents, but facilitates productive
                        interactions that lead to excellence in innovation.
                        Snyder Technologies offers a comprehensive collaboration
                        suite that helps your teams to work remotely, without
                        compromising productivity.
                      </p>
                      <p>
                        We know every organization is unique, and so does their
                        work culture. That’s why we always start by
                        understanding your team before figuring out the best
                        solution. We focus on transforming your organization
                        into a modern workplace by providing virtual
                        connectivity, powered by state-of-the-art collaboration
                        tools. This includes cloud storage, private and group
                        messaging, file-sharing software, video conferencing,
                        and online meetings, along with third-party application
                        support. With industries best collaboration suites the
                        work in your organization becomes uninterrupted.
                      </p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={workplaceCol}
                        cardTitle="Modern Workplace"
                        cardLink="/solutions/collaboration/modern-workplace"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={wirelesspCol}
                        cardTitle="Virtual Connection"
                        cardLink="/solutions/collaboration/virtual-connection"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                  </Row>

                  <Partners />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
