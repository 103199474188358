import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ServicesBenefits from '../../../components/services-benefits/services-benefits';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/software-development.png`;
const BenefitsImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/software-development-imgs.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/services/software-development`;
const pageDescription =
  'Transform your business into a futuristic one by utilizing the power of software.';
const pageTitle = 'Software Development';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Services | ${pageTitle} | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={pageDescription} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Services | ${pageTitle} | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="services-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Services"
            subheading=""
            heading={pageTitle}
            content={pageDescription}
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>Building robust software to elevate your business.</h3>
                  <Row className="justify-content-between">
                    <Col>
                      <p>
                        Technology has enabled businesses to reach their
                        audience on a global scale and it’s not an exaggeration
                        to say that software has played a significant role in
                        making that happen. Snyder Technologies’ software
                        development service aims to conceive, design, develop,
                        deploy and support robust software based on agile
                        methodology, specific to your business needs. We focus
                        on developing application software that helps you get
                        things done. From enterprise software to business
                        application suite to database management software to CRM
                        to web and mobile apps, we have a range of software
                        development offerings under our belt.
                      </p>
                    </Col>
                  </Row>
                  <ServicesBenefits
                    image={BenefitsImage}
                    imageAlt={pageTitle}
                    listItems={[
                      'Helps you to offer better customer service.',
                      'Enhances the business processes and workflows.',
                      'Easy integration with existing technology and cloud platforms.',
                      'Streamlines supply chain and inventory management.',
                      'Real-time information makes reporting better.',
                    ]}
                  />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
