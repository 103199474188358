import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const science = `${SAPPS_WEB_CDN_FILES_URL}images/science.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/information/data-science`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Information | Data Science | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Solutions"
            subheading=""
            heading="Data Science"
            content="Identify patterns in data, make predictions and better decisions with the help of AI and machine learning."
            innerImg={science}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Data – the most valuable resource for Artificial
                    Intelligence.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                      Data science is the process of using tools and techniques to draw actionable
                      information out of huge volumes of noisy data.  It is also the engine driving
                      some of the greatest innovations of our lifetime.  Artificial Intelligence (AI),
                      machine learning, and automation are all driven by the art of data science.
                      Enterprises are embracing AI applications and leveraging a variety of data types
                      (structured, unstructured, and semi-structured) for integrated processes across
                      all lines of business and industries.  Developing a strong data analytics
                      practice can help your leadership team answer questions like:
                      </p>
                      <ul>
                        <li>How will social distancing affect sales?</li>
                        <li>How will supply chain disruptions affect inventory?</li>
                        <li>How will remote working change staffing needs?</li>
                        <li>How will the rapid growth in online transactions affect logistics planning?</li>
                      </ul>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        Anticipating the answers to questions like these has become essential to every
                        organization’s success. To adapt in real-time to these emerging trends in economic
                        development, organizations need to update their data science strategy with practical
                        data science technologies.  However, although organizations understand the
                        importance and potential impact of AI, they often struggle to move from pilot
                        to production. At Snyder, we build data science solutions that help businesses
                        understand these questions and many more, from customers, to vendors to consultants
                        and everyone in between. Our team of data scientists, business analysts, engineers,
                        and programmers will deliver the tools and technologies that not only support your
                        existing systems and applications, but will integrate seamlessly with evolving
                        technologies to deliver success.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col
                        lg="6"
                        sm="12"
                        className="d-flex align-items-stretch"
                      >
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Data gathering and organization',
                            'Algorithms and statistical methods to identify patterns',
                            'Insight generation and application',
                            'Prediction and decision making',
                          ]}
                        />
                      </Col>
                      <Col
                        lg="6"
                        sm="12"
                        className="d-flex align-items-stretch"
                      >
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Precise prediction for better decision',
                            'Enhanced optimization',
                            'Enhanced customer experience',
                            'Reliability and security',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
