import React, { Component } from 'react';
import './banner-inner.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerInner = `${SAPPS_WEB_CDN_FILES_URL}images/innerBanner1.png`;
export interface BannerInnerProps {
  pageName?: string;
  heading?: string;
  subheading?: string;
  content?: string;
  location?: string;
  experience?: string;
  innerImg?: string;
}

class BannerInner extends Component<BannerInnerProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { pageName, heading, subheading, content, innerImg } = this.props;

    return (
      <section className="inner-page--banner">
        <div className="inner-page--content">
          <Container>
            <Row className="align-items-center">
              <Col lg="7" xl="6" md="9">
                <h5>{pageName}</h5>
                <h4>{subheading} </h4>
                <h2>{heading}</h2>
                <p>{content}</p>
                {/* <ul className="for-job">
                  <li>
                    <p>
                      <span>Location</span>
                      {location}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Experience</span>
                      {experience}
                    </p>
                  </li>
                </ul> */}
              </Col>
              <Col lg="5" xl="6" md="3" className="banner-inner-lg-image">
                <Image src={innerImg} />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
}

export default BannerInner;
