/**
 * @param formElement
 */
export function validateForm(formElement) {
  const formLength = formElement.length;

  if (formElement.checkValidity() === false) {
    for (let i = 0; i < formLength; i++) {
      const element = formElement[i];
      const errorLabel = element.parentNode.querySelector('.invalid-feedback');

      if (errorLabel && element.nodeName.toLowerCase() !== 'button') {
        errorLabel.textContent = !element.validity.valid
          ? element.validationMessage
          : '';
      }
    }

    return false;
  }

  for (let i = 0; i < formLength; i++) {
    const element = formElement[i];
    const errorLabel = element.parentNode.querySelector('.invalid-feedback');
    if (errorLabel && element.nodeName.toLowerCase() !== 'button') {
      errorLabel.textContent = '';
    }
  }

  return true;
}
