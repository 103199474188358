import React from 'react';

import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerInnerImg = `${SAPPS_WEB_CDN_FILES_URL}images/gadget.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/information/data-analytics`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Innovation | Connected Products | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Solutions"
            subheading=""
            heading="Connected Products"
            content="Leverage the power of connectivity to streamline your operations and scale your business."
            innerImg={bannerInnerImg}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Smart equipment = greater efficiency and more control over
                    your data.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="6">
                      <p>
                        With the arrival of the Internet of Things (IoT),
                        workplaces are evolving significantly. Smart devices
                        with seamless connectivity and integration are helping
                        businesses improve their operational efficiency,
                        product/service quality, and the way they interact with
                        their customers. Connected devices enable organizations
                        to store, manage, and secure their data in such a way
                        that helps them to take action that positively impact
                        their business,
                      </p>
                    </Col>
                    <Col md="6">
                      <p>
                        We offer a range of IoT-based solutions that include
                        Azure IoT Edge, IoT Hub, Stream Analytics, Functions,
                        Containers, and HD Insights that help you manage and
                        secure your data. With an end-to-end framework that
                        promises better connectivity, we help you improve the
                        uptime of your devices and the quality of your
                        products/services.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Secured data access',
                            'Easy data discovery and maintenance',
                            'Seamless interaction',
                            'Better connectivity',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Access to real-time data',
                            'Predictive analysis of risks',
                            'Efficient management of data security',
                            'Automated access control and alert system',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
