import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ServicesBenefits from '../../../components/services-benefits/services-benefits';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/ux-ui-design.png`;
const BenefitsImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/ux-ui-design-imgs.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/services/ux-ui-design`;
const pageDescription =
  'Design thinking always starts with users’ needs rather than the product’s features.';
const pageTitle = 'UX/UI Design';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Services | ${pageTitle} | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={pageDescription} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Services | ${pageTitle} | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="services-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Services"
            subheading=""
            heading={pageTitle}
            content={pageDescription}
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>Making application more intuitive and efficient.</h3>
                  <Row className="justify-content-between">
                    <Col>
                      <p>
                        We believe that the success of an application is
                        determined by the fact how usable it is. While it’s
                        easier to focus on the features and the capability of
                        software solutions, taking into consideration their
                        usability will play a key role in whether or not it
                        solves the customer’s problem. After all, the software
                        isn’t built for machines, but humans. User-centric
                        design is a core part of our philosophy that reflects in
                        our practices and our products. UX/UI design solutions
                        that we offer always put the user in the first place
                        ensuring a seamless experience and customer retention.
                      </p>
                    </Col>
                  </Row>
                  <ServicesBenefits
                    image={BenefitsImage}
                    imageAlt={pageTitle}
                    listItems={[
                      'We think like a user while we start off designing an experience.',
                      'We understand your audience’s demographics, needs, and problems.',
                      'Well-experienced team of designers, strategists, and researchers.',
                      'Equipped with the latest design tools, strategies, and dedicated facilities.',
                      'Ensured user acquisition, engagement, productivity, and retention.',
                      'Design practices based on time-tested design principles and research.',
                    ]}
                  />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
