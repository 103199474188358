import React from 'react';
import Helmet from 'react-helmet';
import custom from '../../../gatsby-custom';
import Layout from '../../layout';
import Header from '../../components/header/header';
import Careers from '../../components/careers/careers';
import Footer from '../../components/footer/footer';
import BannerInner from '../../components/banner-inner/banner-inner';
// import Layout from '@website-src/layout';
// import Header from '@website-src/components/Header/Header';
// import Career from '@website-src/components/Career/Career';
// import Footer from '@website-src/components/footer/footer';
// import BannerInner from '@website-src/components/BannerInner/BannerInner';

const { SAPPS_WEB_MARKETING_URL } = process.env;
const pageUrl = `${SAPPS_WEB_MARKETING_URL}/careers`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Carees | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta
          name="description"
          content="Carees, In search of great minds looking for a home."
        />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Carees | ${custom.siteTitle}`} />
        <meta
          property="og:description"
          content="Carees, In search of great minds looking for a home."
        />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="CAREERS"
            heading="In search of great minds looking for a home."
          />
          <Careers />
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
