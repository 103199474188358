import React from 'react';
import './job-description.scss';
import { Container, Row, Col } from 'react-bootstrap';
import './job-senior-it-engineer-india-2021.module.scss';
import JobForm from './jobs-form/job-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface JobSeniorItEngineerIndia2021Props {}

/**
 * @param props
 */
export function JobSeniorItEngineerIndia2021(
  props: JobSeniorItEngineerIndia2021Props
) {
  return (
    <section className="career-desc--page-content">
      <div className="career-desc--job-description">
        <Container>
          <div className="job-content">
            <Row>
              <Col md="12">
                <h3>Job Description</h3>
                <p>
                  The Senior Engineer (ITMS) is responsible for providing Tier-2
                  Technical support to a wide range of internal and external
                  clients across many industries, including logistic, telecom,
                  healthcare, and financial services{' '}
                </p>
              </Col>
              <Col md="12">
                <h3>Responsibilities</h3>
                <ul>
                  <li>
                    Experience working with Active Directory (AD) and Microsoft
                    Windows Servers.
                  </li>
                  <li>
                    Networks (VPN connectivity, IP addressing, routers,
                    switches, circuits, etc.)
                  </li>
                  <li>
                    Experience working with Linux and VMware. Working knowledge
                    of to take snap shots of application, image deployment.
                  </li>
                  <li>
                    Good understanding of the OSI model, TCP/IP fundamentals and
                    computer networking.
                  </li>
                  <li>
                    Hands on experience on troubleshooting of layer 1
                    communications, Able to identify network issues on switches
                    & routers, including QoS configurations
                  </li>
                  <li>
                    Prior knowledge administering and troubleshooting Microsoft
                    Skype for Business /Office365 environment.
                  </li>
                  <li>
                    Good knowledge of security concepts such as ACLs, NAT,
                    Firewalls.{' '}
                  </li>
                  <li>Good understanding of various monitoring tools</li>
                  <li>
                    Exposure to troubleshooting tools such as Wireshark and
                    other industry standard log analyzers.
                  </li>
                  <li>
                    Experience working with APIs, Python, and network automation
                    tools. (Desirable but not mandatory)
                  </li>
                  <li>
                    Understanding of SLAs/KPIs, Event/Incident Management
                    process.
                  </li>
                  <li>
                    Knowledge of ITIL framework, Attention to details and
                    adherence to processes (Service Desk, ITIL, process rigor)
                  </li>
                </ul>
              </Col>
              <Col md="12" className="mt-4">
                <h3>Skills & Qualification</h3>
                <ul>
                  <li>
                    Bachelor’s Degree in Computer Science, Engineering, related
                    discipline, or equivalent experience.
                  </li>
                  <li>
                    Minimum 4 years’ experience with device, network, and
                    application monitoring in an IT/NOC (managed services)
                    environment and proven record of accomplishment.
                  </li>
                  <li>
                    Certifications desired: CCNA, CCNP, RHCHA, MCSA, MCSE,
                    VMWare or equivalent
                  </li>
                  <li>Systems: Windows, UNIX, Linux, Server HW, VMWare</li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <JobForm />
    </section>
  );
}

export default JobSeniorItEngineerIndia2021;
