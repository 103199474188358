import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const inforRetrieval = `${SAPPS_WEB_CDN_FILES_URL}images/information-retrieval.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/information/information-retrieval`;
const pageOgImage = `${SAPPS_WEB_CDN_FILES_URL}images/snydertech-og-02.jpg`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Information Retrieval | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information Retrieval | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content={pageOgImage} />
        <meta name="twitter:image" content={pageOgImage} />
        <meta property="og:image:alt" content="Snyder Technologies, Together, let's reimagine the future of your business." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Information Retrieval | ${custom.siteTitle}`} />
        <meta name="twitter:description" content={Strings.aboutUs} />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Solutions"
            subheading=""
            heading="Information Retrieval"
            content="Build a system for literature search that saves your time and energy."
            innerImg={inforRetrieval}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Enhance your knowledge management with a definitive
                    retrieval model.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        Information Retrieval (IR) can provide organizations with
                        immediate value and provides a means to get at information
                        that already exists in electronic formats. Everyone is
                        familiar with search engines, which rely on IR to identify
                        items requested in a search function.  IR finds its role in
                        a spectrum of applications ranging from industry-specific digital
                        libraries such as scientific papers and e-books to media and
                        medical searches.  IR uses intelligent algorithm-based processes
                        that analyze your data to enable the identification of documents
                        required by your team.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        Information Retrieval solutions offered by Snyder Technologies
                        include a comprehensive IR model that enables you to retrieve textual
                        information, with the help of indexing algorithms such as
                        ‘word indexing’ and ‘concept indexing’.  The result is users are able
                        to quickly access multiple databases, using multiple keywords/concepts
                        at the same time, increasing the capability of your team to adhere to
                        retention policies and other compliance related requirements. And of
                        course we’ll help you to analyze the queries raised by users to
                        constantly enhance your capabilities.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Market Need"
                          listItems={[
                            'Document indexing',
                            'Word indexing',
                            'Concept indexing',
                            'Data mining',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Solution"
                          listItems={[
                            'More precise results',
                            'Knowledge management capabilities',
                            'Seamless frontend integrations',
                            'Query analytics for enhancing the experience',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
