import axios from 'axios';
import React, { Component } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { validateForm } from '../../../assets/js/custom-validation';
import './job-form.module.scss';

const { SAPPS_WEB_API_URL } = process.env;
const { SAPPS_WEB_MAILTO } = process.env;
const { SAPPS_WEB_MARKETING_URL } = process.env;
const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const stEmailLogo = `${SAPPS_WEB_CDN_FILES_URL}images/logo-email.png`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface JobFormProps {}

class JobForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobPosition: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailId: '',
      currentCompanyName: '',
      noticePeriod: '',
      mailSent: false,
      error: null,
      isValidated: false,
    };
  }

  handleJobFormSubmit(event) {
    event.preventDefault();
    // Reset State
    this.setState({ error: null, mailSent: false });
    const { formEl } = this;
    const templateData = `
      <!doctype html>
      <html lang='en'>
      <head>
          <meta charset='UTF-8'><meta name='viewport' content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'>
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>New Project Enquiry</title>
          <style>
              .container {width: 100%; max-width: 1000px; margin: 0 auto; padding: 0 15px}
              table { width: 100%; }
              .enrollingTemplate th p {margin: 0; text-align: left; font-weight: normal}
              .enrollingTemplate h2 {margin: 50px 0 25px}
              .enrollingTemplate tbody p {margin: 0; line-height: 23px}
              .enrollingTemplate tbody p+p {margin: 15px 0 0;}
              .enrollingTemplate ul {padding-left: 15px}
              .enrollingTemplate ul li {margin: 10px 0 0}
              .enrollingTemplate ul li ul {padding-left: 25px; list-style: disc}
              .enrollingTemplate ul li ul li {margin: 5px 0 0}
              .d-flex {display: flex; margin: 50px 0 25px; width: 100%}
              .double-img img { margin: 15px; max-width: 40%; max-height: 150px }
              p { padding-bottom: 15px; }
              .vcolor{ color: #28b6ea; }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="enrollingTemplate">
                  <p>Dear Snyder Team,<br><br>You are receiving this because someone has applied for a job on the website. <a href="${SAPPS_WEB_MARKETING_URL}">${SAPPS_WEB_MARKETING_URL}</a></p>
                  <table border=1>
                    <tbody>
                      <tr><td><b>Applyin for?*</b></td><td><span class="vcolor">[ApplyinFor]</span></td></tr>
                      <tr><td><b>First Name*</b></td><td><span class="vcolor">[FirstName]</span></td></tr>
                      <tr><td><b>Last Name</b></td><td><span class="vcolor">[LastName]</span></td></tr>
                      <tr><td><b>Phone Number</b></td><td><span class="vcolor">[PhoneNumber]</span></td></tr>
                      <tr><td><b>Email Address*</b></td><td><span class="vcolor">[Email]</span></td></tr>
                      <tr><td><b>Current Company*</b></td><td><span class="vcolor">[CompanyName]</span></td></tr>
                      <tr><td><b>Notice Period*</b></td><td><span class="vcolor">[NoticePeriod]</span></td></tr>
                    </tbody>
                  </table>
                  <p>Regards, Snyder Team<br><br><img src="${stEmailLogo}" width="15%" alt="Snyder Technologies"/></p>
              </div>
          </div>
      </body>
      </html>`;
    // If form validate
    if (validateForm(formEl)) {
      const html = templateData
        .replace('[ApplyinFor]', this.state.jobPosition)
        .replace('[FirstName]', this.state.firstName)
        .replace('[LastName]', this.state.lastName)
        .replace('[PhoneNumber]', this.state.phoneNumber)
        .replace('[Email]', this.state.emailId)
        .replace('[CompanyName]', this.state.currentCompanyName)
        .replace('[NoticePeriod]', this.state.noticePeriod);

      const data = {
        eSAPPS_WEB_MAILTO: SAPPS_WEB_MAILTO,
        emailSubject: 'New Job Application - Snyder Marketing Website',
        emailTemplate: html,
      };

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${SAPPS_WEB_API_URL}/Referral/SubmitTarget`,
          headers: { 'content-type': 'application/json' },
          data,
        })
          .then((result) => {
            if (result.data.message) {
              this.setState({
                jobPosition: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                emailId: '',
                currentCompanyName: '',
                noticePeriod: '',
                mailSent: true,
                isValidated: false,
              });
            } else {
              this.setState({ error: result.data.error });
            }
          })
          .catch((error) => {
            this.setState({ error: error.message });
          });
      });
    }

    this.setState({ isValidated: true });

    return false;
  }

  render() {
    if (typeof window !== 'undefined') {
      const setActive = (element, active) => {
        const formField = element.parentNode.parentNode;
        if (active) {
          formField.classList.add('form-field--is-active');
        } else {
          formField.classList.remove('form-field--is-active');
          element.value === ''
            ? formField.classList.remove('form-field--is-filled')
            : formField.classList.add('form-field--is-filled');
        }
      };

      Array.prototype.forEach.call(
        document.querySelectorAll('.inputMod'),
        (element) => {
          element.addEventListener('blur', () => {
            setActive(element, false);
          });

          element.addEventListener('focus', () => {
            setActive(element, true);
          });
        }
      );
    }

    const classNames = [];
    if (this.state.isValidated) {
      classNames.push('was-validated');
    }

    return (
      <div className="career-desc--job-description">
        <Container>
          <div className="job-content">
            <Form
              method="post"
              action="#"
              ref={(form) => {
                this.formEl = form;
              }}
              className={classNames}
              noValidate
            >
              {this.state.mailSent && (
                <div className="alert-success alert text-center mb-5">
                  Thanks for showing the interest in working with us!
                </div>
              )}
              {this.state.error && (
                <div className="alert-danger error alert text-center  mb-5">
                  {this.state.error}
                </div>
              )}
              <Row>
                <Col md="6">
                  <h3>Applying for?*</h3>
                  <Form.Group>
                    <Form.Label className="d-none">Job Position</Form.Label>
                    <Form.Control
                      as="select"
                      required
                      whenChange={(e) => {
                        this.setState({ jobPosition: e.target.value });
                      }}
                    >
                      <option value="">Select Job Position</option>
                      <option value="Project Manager - Clinical Research (IN)">
                        Project Manager - Clinical Research (IN)
                      </option>
                      <option value="Manager - ITMS (IN)">
                        Manager - ITMS (IN)
                      </option>
                      <option value="Sr. Engineer - ITMS (IN)">
                        Sr. Engineer - ITMS (IN)
                      </option>
                      <option value="Sr. Sales Executive - ITMS (IN)">
                        Sr. Sales Executive - ITMS (IN)
                      </option>
                      <option value="PHP Developer (IN)">
                        PHP Developer (IN)
                      </option>
                    </Form.Control>
                    <div className="invalid-feedback" />
                  </Form.Group>
                </Col>
                {/* <Col md="6">
                  <h3>Attach your Résumé</h3>
                  <Form.Group className="attchment">
                    <i className="fa fa-paperclip" />
                    <Form.File
                      id="exampleFormControlFile1"
                      label="Attach your Résumé/CV"
                    />
                  </Form.Group>
                </Col> */}
              </Row>
              <Row>
                <Col md="12">
                  <h3>Personal Information</h3>
                </Col>
                <Col md="6">
                  <Form.Group controlId="f_name">
                    <Form.Label className="d-none">First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name*"
                      required
                      value={this.state.firstName}
                      whenChange={(e) => {
                        this.setState({ firstName: e.target.value });
                      }}
                    />
                    <div className="invalid-feedback" />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="l_name">
                    <Form.Label className="d-none">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      value={this.state.lastName}
                      whenChange={(e) => {
                        this.setState({ lastName: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="phone">
                    <Form.Label className="d-none">Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Phone Number"
                      value={this.state.phoneNumber}
                      whenChange={(e) => {
                        this.setState({ phoneNumber: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="email">
                    <Form.Label className="d-none">Email ID</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email Address*"
                      required
                      value={this.state.emailId}
                      whenChange={(e) => {
                        this.setState({ emailId: e.target.value });
                      }}
                    />
                    <div className="invalid-feedback" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <h3>Current Status</h3>
                </Col>
                <Col md="6">
                  <Form.Group controlId="company">
                    <Form.Label className="d-none">Current Company</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Current Company*"
                      required
                      value={this.state.currentCompanyName}
                      whenChange={(e) => {
                        this.setState({ currentCompanyName: e.target.value });
                      }}
                    />
                    <div className="invalid-feedback" />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="notice">
                    <Form.Label className="d-none">Notice Period</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Notice Period*"
                      required
                      value={this.state.noticePeriod}
                      whenChange={(e) => {
                        this.setState({ noticePeriod: e.target.value });
                      }}
                    />
                    <div className="invalid-feedback" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    variant="border"
                    type="button"
                    onClick={(e) => this.handleJobFormSubmit(e)}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}

export default JobForm;
