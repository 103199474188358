import React, { Component } from 'react';
import './email-campaigns-form.module.scss';
import { validateForm } from '../../assets/js/custom-validation';
import axios from 'axios';

const { SAPPS_WEB_API_URL } = process.env;
const { SAPPS_WEB_EMAIL_CAMP_MAILTO } = process.env;
const { SAPPS_WEB_MARKETING_URL } = process.env;
const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const stEmailLogo = `${SAPPS_WEB_CDN_FILES_URL}images/logo-email.png`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EmailCampaignsFormProps {}

class EmailCampaignsForm extends Component {
  formEl: HTMLFormElement;
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      emailId: '',
      phoneNumber: '',
      companyName: '',
      teamSize: '',
      mailSent: false,
      error: null,
      isValidated: false,
    };
  }

  handleNewsLetterFormSubmit(event) {
    event.preventDefault();
    // Reset State
    this.setState({ error: null, mailSent: false });
    const { formEl } = this;
    const templateData = `
    <!doctype html>
    <html lang='en'>
    <head>
        <meta charset='UTF-8'><meta name='viewport' content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'>
        <meta http-equiv="X-UA-Compatible" content="ie=edge">
        <title>New Project Enquiry</title>
        <style>
            .container {width: 100%; max-width: 1000px; margin: 0 auto; padding: 0 15px}
            table { width: 100%; }
            .enrollingTemplate th p {margin: 0; text-align: left; font-weight: normal}
            .enrollingTemplate h2 {margin: 50px 0 25px}
            .enrollingTemplate tbody p {margin: 0; line-height: 23px}
            .enrollingTemplate tbody p+p {margin: 15px 0 0;}
            .enrollingTemplate ul {padding-left: 15px}
            .enrollingTemplate ul li {margin: 10px 0 0}
            .enrollingTemplate ul li ul {padding-left: 25px; list-style: disc}
            .enrollingTemplate ul li ul li {margin: 5px 0 0}
            .d-flex {display: flex; margin: 50px 0 25px; width: 100%}
            .double-img img { margin: 15px; max-width: 40%; max-height: 150px }
            p { padding-bottom: 15px; }
            .vcolor{ color: #28b6ea; }
        </style>
    </head>
    <body>
        <div class="container">
            <div class="enrollingTemplate">
                <p>Dear Snyder Team,<br><br>You are receiving this because someone has inquire from Marketing Email Compaign ITMSP Landing Page Form on the website. <a href="${SAPPS_WEB_MARKETING_URL}">${SAPPS_WEB_MARKETING_URL}</a></p>
                <table border=1>
                  <tbody>
                    <tr><td><b>First Name*</b></td><td><span class="vcolor">[FirstName]</span></td></tr>
                    <tr><td><b>Last Name*</b></td><td><span class="vcolor">[LastName]</span></td></tr>
                    <tr><td><b>Email Address*</b></td><td><span class="vcolor">[Email]</span></td></tr>
                    <tr><td><b>Phone Number*</b></td><td><span class="vcolor">[PhoneNumber]</span></td></tr>
                    <tr><td><b>Company Name</b></td><td><span class="vcolor">[CompanyName]</span></td></tr>
                    <tr><td><b>Team Size</b></td><td><span class="vcolor">[TeamSize]</span></td></tr>
                  </tbody>
                </table>
                <p>Regards, Snyder Team<br><br><img src="${stEmailLogo}" width="15%" alt="Snyder Technologies"/></p>
            </div>
        </div>
    </body>
    </html>`;
    // If form validate
    if (validateForm(formEl)) {
      const html = templateData
        .replace('[FirstName]', this.state.firstName)
        .replace('[LastName]', this.state.lastName)
        .replace('[Email]', this.state.emailId)
        .replace('[PhoneNumber]', this.state.phoneNumber)
        .replace('[CompanyName]', this.state.companyName)
        .replace('[TeamSize]', this.state.teamSize);

      const data = {
        eSAPPS_WEB_MAILTO: SAPPS_WEB_EMAIL_CAMP_MAILTO,
        emailSubject: 'New ITMSP Inquiry - Snyder Marketing Website',
        emailTemplate: html,
      };

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${SAPPS_WEB_API_URL}/Referral/MarketingEmailCompaignLead`,
          headers: { 'content-type': 'application/json' },
          data,
        })
          .then((result) => {
            if (result.data.message) {
              this.setState({
                firstName: '',
                lastName: '',
                emailId: '',
                phoneNumber: '',
                companyName: '',
                teamSize: '',
                mailSent: true,
                isValidated: false,
              });
            } else {
              this.setState({ error: result.data.error });
            }
          })
          .catch((error) => {
            this.setState({ error: error.message });
          });
      });
    }
    this.setState({ isValidated: true });
    return false;
  }

  render() {
    if (typeof window !== 'undefined') {
      const setActive = (element, active) => {
        const formField = element.parentNode.parentNode;
        if (active) {
          formField.classList.add('form-field--is-active');
        } else {
          formField.classList.remove('form-field--is-active');
          element.value === ''
            ? formField.classList.remove('form-field--is-filled')
            : formField.classList.add('form-field--is-filled');
        }
      };

      Array.prototype.forEach.call(
        document.querySelectorAll('.inputMod'),
        (element) => {
          element.addEventListener('blur', () => {
            setActive(element, false);
          });

          element.addEventListener('focus', () => {
            setActive(element, true);
          });
        }
      );
    }

    const classNames = [];
    if (this.state.isValidated) {
      classNames.push('was-validated');
    }

    return (
      <div className="col form-wrapper" id="itmsp-form">
        <div className="cta-wrapper">
          <h3>Get Started With Your IT Transformation</h3>
          <p>Fill out the form and a Snyder expert will reach out shortly.</p>
          <form
            method="post"
            action="#"
            ref={(form) => {
              this.formEl = form;
            }}
            className={classNames}
            noValidate
          >
            {this.state.mailSent && (
              <div className="alert-success alert text-center mb-5">
                Thank You! <br />A Snyder expert will reach out shortly.
              </div>
            )}
            {this.state.error && (
              <div className="alert-danger error alert text-center  mb-5">
                {this.state.error}
              </div>
            )}
            <div className="form-group">
              <input
                type="text"
                name="first_name"
                id="lp_first_name"
                placeholder="First Name*"
                className="form-control"
                required
                value={this.state.firstName}
                whenChange={(e) => {
                  this.setState({ firstName: e.target.value });
                }}
              />
              <div className="invalid-feedback" />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="last_name"
                placeholder="Last Name*"
                className="form-control"
                required
                value={this.state.lastName}
                whenChange={(e) => {
                  this.setState({ lastName: e.target.value });
                }}
              />
              <div className="invalid-feedback" />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                required
                value={this.state.emailId}
                placeholder="Email Address*"
                className="form-control"
                whenChange={(e) => {
                  this.setState({ emailId: e.target.value });
                }}
              />
              <div className="invalid-feedback" />
            </div>
            <div className="form-group">
              <input
                type="tel"
                name="phone"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                placeholder="Phone Number, Formatted 123-456-7890 *"
                className="form-control"
                required
                value={this.state.phoneNumber}
                whenChange={(e) => {
                  this.setState({ phoneNumber: e.target.value });
                }}
              />
              <div className="invalid-feedback" />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="company_name"
                placeholder="Company Name"
                className="form-control"
                value={this.state.companyName}
                whenChange={(e) => {
                  this.setState({ companyName: e.target.value });
                }}
              />
            </div>
            <div className="form-group">
              <select
                name="team_size"
                placeholder="Team Size"
                className="form-control"
                whenChange={(e) => {
                  this.setState({ teamSize: e.target.value });
                }}
              >
                <option defaultValue="Team Size">Team Size</option>
                <option value="1-10">1-10</option>
                <option value="11-50">11-50</option>
                <option value="51-100">51-100</option>
                <option value="100+">100+</option>
              </select>
            </div>
            <div className="form-submitter">
              <button
                type="submit"
                className="btn-danger btn-lg btn"
                onClick={(e) => this.handleNewsLetterFormSubmit(e)}
              >
                Talk to an Expert
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default EmailCampaignsForm;
