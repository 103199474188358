import React from 'react';
import './index.module.scss';
import '../index.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Strings } from '../../../../languages/en';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ListCard from '../../../components/list-card/list-card';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/ms-power-platform.png`;
const powerApps = `${SAPPS_WEB_CDN_FILES_URL}images/power-apps.png`;
const powerAutomate = `${SAPPS_WEB_CDN_FILES_URL}images/power-automate.png`;
const powerBI = `${SAPPS_WEB_CDN_FILES_URL}images/power-bi.png`;
const powerVirtual = `${SAPPS_WEB_CDN_FILES_URL}images/power-virtual-agents.png`;

const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/technology/microsoft-dynamics-365`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Technology | Microsoft Dynamics 365 | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Technology | Microsoft Dynamics 365 | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="technology" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Technology"
            subheading=""
            heading="Microsoft Power Platform"
            content="Unleash the power of your applications"
            innerImg={bannerImage}
          />
          <section className="generic--page-content ">
            <div className="generic--section technology--section no-pad-bottom">
              <Container>
                <div className="generic--container technology--container no-after">
                  <Row className="justify-content-between">
                    <Col>
                      <h3>What is Microsoft Power Platform?</h3>
                      <p>
                        Business intelligence is key to powering your company’s
                        digital transformation and improving productivity and
                        efficiency. And one of the best ways to fully leverage
                        your data is by customizing your cloud-based Dynamics
                        365, Azure, and Office 365 applications.
                      </p>
                      <p>
                        The Microsoft Power Platform is a set of low or no code
                        tools that allows you to automate processes and
                        workflows and improve connectivity and communications,
                        in house—without a lot of technical know-how. Through
                        familiar, intuitive technology, you can create custom
                        data-driven and task-specific apps to support faster
                        data collection, surface real-time insights, and empower
                        users to make more informed and actionable decisions.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
          <section className="generic--page-content bg-blue pad-mob">
            <div className="generic--section">
              <Container>
                <div className="container-white text-center">
                  <Row className="justify-content-between">
                    <Col lg="8" className="offset-md-2">
                      <h3>Microsoft Power Platform</h3>
                      <p>
                        Microsoft Power Platform is more than the sum of its
                        parts because when connected together, and to the cloud
                        and other business applications, you can securely build
                        and scale end-to-end business solutions.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
          <section className="generic--page-content">
            <div className="generic--section overlap-top">
              <Container>
                <div className="generic--container">
                  <Row>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={powerApps}
                        cardTitle="Power Apps "
                        content={
                          <p className="text-center">
                            Turn ideas into organizational solutions by enabling
                            everyone to build custom apps that solve business
                            challenges
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={powerAutomate}
                        cardTitle="Power Automate"
                        content={
                          <p className="text-center">
                            Boost business productivity to get more done by
                            giving everyone the ability to automate
                            organizational processes
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={powerBI}
                        cardTitle="Power BI"
                        content={
                          <p className="text-center">
                            Make informed, confident business decisions by
                            putting data-driven insights into everyone’s hands
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={powerVirtual}
                        cardTitle="Power Virtual Agents"
                        content={
                          <p className="text-center">
                            Create conversational experiences by easily building
                            and maintaining virtual agents—no coding required
                          </p>
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
