import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../layout';
import custom from '../../gatsby-custom';
import Header from '../components/header/header';
import { AnnouncementCallouts } from '../components/announcement-callouts/announcement-callouts';
import Footer from '../components/footer/footer';
import { Banner } from '../components/banner/banner';
import { FutureCards } from '../components/future-cards/future-cards';
import { Client } from '../components/client/client';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const { SAPPS_WEB_MARKETING_URL } = process.env;
const homeBannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/home-banner.jpg`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={custom.siteTitle}
        bodyAttributes={{ class: 'main-home-page' }}
      >
        {/* General tags */}
        <meta name="description" content={custom.siteDescription} />
        <meta name="image" content={homeBannerImage} />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={custom.siteTitle} />
        <meta property="og:description" content={custom.siteDescription} />
        <meta property="og:url" content={SAPPS_WEB_MARKETING_URL} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content={homeBannerImage} />
      </Helmet>
      <Header />
      <div className="home">
        <Banner />
        <FutureCards />
        <AnnouncementCallouts />
        <Client />
      </div>
      <Footer />
    </Layout>
  );
}

export default Index;
