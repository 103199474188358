import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/data.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/experience/application-development`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Experience | Application Development | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="EXPERIENCE"
            subheading=""
            heading="Application Development"
            content="Expand your capabilities with powerful applications built with modern technology."
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Customized application development tailored for your
                    business needs.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        A robust application for your business isn’t just a
                        one-time requirement but a commitment that continues
                        throughout your journey. Because every organization is
                        unique and keeps on evolving. While it might be easier
                        to choose off-the-self packages to meet your immediate
                        needs, it has limitations to meet the requirements of
                        your continuously-evolving business. That’s why Snyder
                        Technologies brings you more customized application
                        development services to support and scale up your
                        business.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        Our methodology is agile, transparent, and collaborative
                        throughout the stages of collecting requirements,
                        designing prototypes and experiences, development,
                        testing, implementation, and deployment. We offer
                        application development under various technologies and
                        mediums such as Rapid Application Development (RAD),
                        mobile application development, web application
                        development, enterprise application development, and
                        cloud-based applications. Our services don’t end with
                        the deployment but extend to the lifetime support of
                        applications to ensure stability and growth.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Responsive and performant applications',
                            'Agile methodology and cloud platforms',
                            'Experienced engineers and designers',
                            'Simultaneous development modules',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Need-specific solutions with enhanced capabilities',
                            'Sprint-based releases and consistent progression',
                            'Real-time testing via multiple mediums',
                            'On-time delivery and post-deployment support',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
