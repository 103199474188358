import React, { Component } from 'react';
import './msp-escalating-tension.scss';

export interface MspEscalatingTensionProps {
  title?: string;
  description?: string;
}

class MspEscalatingTension extends Component<MspEscalatingTensionProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, description } = this.props;

    return (
      <section className="mspEscalatingTensionContainer">
        <section className="container">
          <section className="mspEscalatingTensionInnerSec vectorShapSp">
            <svg
              className="topLeft"
              width="56"
              height="35"
              viewBox="0 0 56 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.3184 34.641L0.677345 14.641L55.3184 0L35.3184 34.641Z"
                fill="#0E89B5"
              />
            </svg>
            <svg
              className="bottomLeft"
              width="50"
              height="39"
              viewBox="0 0 50 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8567 38.8153L49.4937 28.4625L0.503906 0.178223L10.8567 38.8153Z"
                fill="#0E89B5"
              />
            </svg>
            <svg
              className="bottomRight"
              width="90"
              height="71"
              viewBox="0 0 90 71"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M70.4549 70.4114L0.908212 51.7764L89.0898 0.864746L70.4549 70.4114Z"
                fill="#0E89B5"
              />
            </svg>
            <section className="mspEscalatingTensionContentSec">
              <h4>{title}</h4>
              <p>{description}</p>
              <a className="btn btn-white" href="javascript:void(0);">
                Contact Us
              </a>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default MspEscalatingTension;
