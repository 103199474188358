import React from 'react';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import { JobDeveloperPhpIndia2021 } from '../../../../components/jobs/job-developer-php-india-2021';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
// import Layout from '@website-src/layout';
// import Header from '@website-src/components/Header/Header';
// import CareerDescription from '@website-src/components/Career/CareerDescription/CareerDescription';
// import Footer from '@website-src/components/footer/footer';
// import BannerInner from '@website-src/components/BannerInner/BannerInner';

const { SAPPS_WEB_MARKETING_URL } = process.env;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={custom.siteTitle}>
        {/* General tags */}
        <meta name="description" content={custom.siteDescription} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={custom.siteTitle} />
        <meta property="og:description" content={custom.siteDescription} />
        <meta property="og:url" content={SAPPS_WEB_MARKETING_URL} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            heading="Developer - PHP"
            location="Delhi, India"
            experience="2+ years"
          />
          <JobDeveloperPhpIndia2021 />
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
