import React from 'react';

import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import Partners from '../../../components/partners/partners';
import ListCard from '../../../components/list-card/list-card';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/team-cuate.png`;
const gadjetCol = `${SAPPS_WEB_CDN_FILES_URL}images/gadget-col.png`;
const automationCol = `${SAPPS_WEB_CDN_FILES_URL}images/automation-col.png`;

const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/information`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <div className="banner-white">
      <Header />
      <div className="home">
        <BannerInner
          pageName="Solutions"
          subheading=""
          heading="Innovation"
          content="Build an environment that makes innovation part of your organizational culture."
          innerImg={bannerImage}
        />
        <section className="generic--page-content">
          <div className="generic--section">
            <Container>
              <div className="generic--container">
                <Row className="justify-content-between">
                  <Col>
                    <h3>
                      Right people enabled by the right technology bring ideas
                      to life.
                    </h3>
                    <p>
                      Innovation, in the age of information, has become
                      essential for organizations that strive to move toward the
                      frontiers. It distinguishes the companies that value their
                      people from others that don’t. Because ideas always start
                      with people and technology enables them to bring ideas to
                      life. Building an organizational culture that nurtures
                      talent and new ideas requires the right environment that
                      promotes agile methodology, collaboration, and
                      peer-learning.
                    </p>
                    <p>
                      We help you to build such a culture by creating a platform
                      that gives your people a collaborative environment and
                      allows them to try new ideas. While this will enable them
                      to build solutions for your business, it also encourages
                      them to keep up their learning, adapt to new technologies,
                      and constantly refine their working methodologies and
                      strategies.
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg="3" md="4" sm="6" xl="3">
                    <ListCard
                      cardImg={automationCol}
                      cardTitle="Automation as a Service"
                      cardLink="/solutions/innovation/automation-as-a-service"
                      cardLinkTitle="Learn more"
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6" xl="3">
                    <ListCard
                      cardImg={gadjetCol}
                      cardTitle="Connected Products"
                      cardLink="/solutions/innovation/connected-products"
                      cardLinkTitle="Learn more"
                    />
                  </Col>
                </Row>

                <Partners />
              </div>
            </Container>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Index;
