import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';
import custom from '../../gatsby-custom';
import Layout from '../layout';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
// import Layout from '@website-src/layout';
// import Header from '@website-src/components/Header/Header';
// import Footer from '@website-src/components/footer/footer';

const { SAPPS_WEB_MARKETING_URL } = process.env;

// eslint-disable-next-line react/prefer-stateless-function
class Index extends Component {
  render() {
    return (
      <Layout>
        <Helmet title={custom.siteTitle}>
          {/* General tags */}
          <meta name="description" content={custom.siteDescription} />
          <meta name="image" content="Snyder Technologies" />
          {/* OpenGraph tags */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={custom.siteTitle} />
          <meta property="og:description" content={custom.siteDescription} />
          <meta property="og:url" content={SAPPS_WEB_MARKETING_URL} />
          <meta property="og:site_name" content={custom.siteName} />
          <meta property="og:image" content="Snyder Technologies" />
        </Helmet>
        <Header />
        <div className="home">
          <Container>
            <div className="small-container">
              <div className="initialContent">
                <h2 className="text-uppercase">404 error not found!</h2>
              </div>
            </div>
          </Container>
        </div>
        <Footer />
      </Layout>
    );
  }
}

export default Index;
