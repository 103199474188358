import React from 'react';
import './job-description.scss';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './job-it-manager-india-2021.module.scss';
import JobForm from './jobs-form/job-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface JobItManagerIndia2021Props {}

/**
 * @param props
 */
export function JobItManagerIndia2021(props: JobItManagerIndia2021Props) {
  return (
    <section className="career-desc--page-content">
      <div className="career-desc--job-description">
        <Container>
          <div className="job-content">
            <Row>
              <Col md="12">
                <h3>Job Description</h3>
                <p>
                  In this role, you will handle and manage all aspects of IT
                  (Information Technology) Network Infrastructure. The Manager-
                  ITMS is going to handle core networking, LAN, WAN, VOIP,
                  Wireless Network, SD WAN, & Network Security across including
                  International businesses.{' '}
                </p>
              </Col>
              <Col md="12" className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                  <li>
                    Candidate needs to be proficient with Cloud based technology
                    Architectures, Design &amp; Deployment. Hand-on with Azure
                    Cloud and/or AWSdeployments is desired
                  </li>
                  <li>
                    Worked on infrastructure optimizations, cloud setups,
                    infrastructure as a service and platform as a service.
                  </li>
                  <li>
                    Candidates need to have experience in end user computing,
                    Servers &amp; Wireless Networks, Firewalls across diverse
                    levels of organizations.
                  </li>
                  <li>
                    Expert in Microsoft technologies: Active Directory,
                    Office365
                  </li>
                  <li>
                    Knowledge of IT concepts and basic operating principles of
                    voice &amp; data communications and troubleshooting
                    information systems hardware and software.
                  </li>
                  <li>
                    Ability to gather and analyze facts, draw conclusions,
                    define problems, and suggest solutions.
                  </li>
                  <li>
                    Ability to communicate effectively: build consensus,
                    facilitate working sessions, and negotiate solutions and
                    alternatives.
                  </li>
                  <li>
                    Ability to conduct training and informational sessions.
                  </li>
                </ul>
              </Col>
              <Col md="12">
                <h3>Skills & Qualification</h3>
                <ul>
                  <li>
                    Hands-on with AWS &amp; Azure cloud setup including
                    architecting, Designing &amp; Deployment at least 1 project
                  </li>
                  <li>
                    Experience with Azure and AWS core concepts is required as
                    well as hands on with private or public cloud infrastructure
                    &amp; managing PaaS and IaaS offerings
                  </li>
                  <li>
                    Domain knowledge &amp; hand-on experience of client &amp;
                    server operating systems, Voice and Networking,
                    Virtualization, EMC and/or NetApp Enterprise-level SANs,
                    Server infrastructures, WAN.
                  </li>
                </ul>
                <ul>
                  <li>
                    Hands-on with Windows Server OS, Active Directory,
                    SharePoint, File &amp; Print Servers, Hyper-V, VDI, Wired
                    &amp; Wireless networks troubleshooting, On Prem Skype for
                    Business/Microsoft Team, Firewall &amp; backbone Network
                    setup
                  </li>
                  <li>
                    Execute and manage deliverables pertaining to IT System
                    audit &amp; compliance
                  </li>
                  <li>
                    Flexibility to adapt to rapidly changing conditions and
                    priorities, and to redirect teams toward new objectives.
                  </li>
                  <li>
                    Good understanding of IT solutions to identify and define
                    project requirements, scope and objectives.
                  </li>
                  <li>
                    Maintain Contract Execution Tracking Log, monitor and track
                    projects progress and handle any issues that arise.{' '}
                  </li>
                  <li>
                    Collect and evaluate project data necessary to meet project
                    reporting and evaluation requirements and adhere to
                    deadlines.
                  </li>
                  <li>
                    Hands-on with AV/ATP solutions, Information Security
                    operational aspects, MIS & reporting, Incident management
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <JobForm />
    </section>
  );
}

export default JobItManagerIndia2021;
