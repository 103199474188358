import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/migration.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/infrastructure-modernization/cloud-migration-enablement`;
const pageOgImage = `${SAPPS_WEB_CDN_FILES_URL}images/snydertech-og-02.jpg`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Infrastructure Modernization | Cloud Migration Enablement | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Cloud Migration Enablement | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content={pageOgImage} />
        <meta name="twitter:image" content={pageOgImage} />
        <meta property="og:image:alt" content="Snyder Technologies, Together, let's reimagine the future of your business." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Cloud Migration Enablement | ${custom.siteTitle}`} />
        <meta name="twitter:description" content={Strings.aboutUs} />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="INFRASTRUCTURE MODERNIZATION"
            subheading=""
            heading="Cloud Migration Enablement"
            content="Bid adieu to on-premises servers by taking your work to the cloud."
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Everything you need to run your business is now available on the cloud.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        Cloud computing has changed the way organizations build and deliver
                        their products and services.  It has also positively impacted the
                        way companies manage their IT resources.
                        Shifting your operations from on-premises infrastructure to the cloud
                        will increase your corporate efficiency, reduce both operational and
                        capital expenditures, and free up valuable resources. It also enables
                        you to enhance customer experience, and more importantly, you don’t
                        need to rebuild or modify your data centers as your needs evolve.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        Snyder Technologies partners with Microsoft Azure to provide everything
                        you need to take your business to the cloud including virtual servers,
                        collaboration tools, analytics, storage, databases and AI solutions.
                      </p>
                      <p>
                        Most importantly you only pay for the parts you use – something you
                        can’t expect in a traditional infrastructure.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Transition from physical infrastructure to cloud',
                            'IaaS, PaaS, and SaaS service models',
                            'Secured platform with over 200 products and services',
                            'Customized solutions for hybrid cloud',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Reduced capital expenses and operational costs',
                            'On-demand cloud services at high speed',
                            'Highly scalable cloud services based on your needs',
                            'Seamless collaboration and increased productivity',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
