import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ServicesBenefits from '../../../components/services-benefits/services-benefits';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/it-consulting.png`;
const BenefitsImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/it-consulting-imgs.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/services/it-consulting`;
const pageDescription =
  'We help you accelerate your business growth by identifying your needs.';
const pageTitle = 'IT Consulting';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Services | ${pageTitle} | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={pageDescription} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Services | ${pageTitle} | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="services-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Services"
            subheading=""
            heading={pageTitle}
            content={pageDescription}
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Have your technology assessed and empower yourself with
                    powerful IT solutions.
                  </h3>
                  <Row className="justify-content-between">
                    <Col>
                      <p>
                        Whether you’re new to IT solutions or in need of
                        upgrading your existing technology, identifying your
                        needs and coming up with the best IT strategies
                        guarantees a competitive edge. Snyder Technologies
                        analyzes your business, existing technology, risks
                        involved in your organization’s security, evaluates the
                        risks and possibilities, and recommends the best
                        possible solutions for your business to thrive. We help
                        you create a technology plan and enhance your existing
                        technology through various services. With our expertise
                        in cloud solutions, such as iPaas and MS Azure, we
                        enable you to be prepared for the future.
                      </p>
                    </Col>
                  </Row>
                  <ServicesBenefits
                    image={BenefitsImage}
                    imageAlt={pageTitle}
                    listItems={[
                      'In-depth expertise in IT solutions and a large portfolio of products and services.',
                      'Holistic approach in analysis, troubleshooting, and revival.',
                      'Extended assistantship in managing cloud technology.',
                      'More reasonable than having a dedicated resource to manage your IT.',
                    ]}
                  />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
