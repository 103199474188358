import React from 'react';
import './index.module.scss';
import '../index.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Strings } from '../../../../languages/en';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ListCard from '../../../components/list-card/list-card';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/office.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/information/data-science`;
const teams = `${SAPPS_WEB_CDN_FILES_URL}images/microsoft/microsoft-teams.png`;
const sharepoint = `${SAPPS_WEB_CDN_FILES_URL}images/microsoft/microsoft-sharepoint.png`;
const outlook = `${SAPPS_WEB_CDN_FILES_URL}images/microsoft/microsoft-outlook.png`;
const oneDrive = `${SAPPS_WEB_CDN_FILES_URL}images/microsoft/microsoft-oneDrive.png`;
const powerPoint = `${SAPPS_WEB_CDN_FILES_URL}images/microsoft/microsoft-powerpoint.png`;
const word = `${SAPPS_WEB_CDN_FILES_URL}images/microsoft/microsoft-word.png`;
const excel = `${SAPPS_WEB_CDN_FILES_URL}images/microsoft/microsoft-excel.png`;
const oneNote = `${SAPPS_WEB_CDN_FILES_URL}images/microsoft/microsoft-oneNote.png`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Technology | Microsoft 365 | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Technology | Microsoft 365 | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="technology" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Technology"
            subheading=""
            heading="Microsoft 365"
            content="An extensive range of applications and services from Microsoft that enable flexibility, high productivity and security."
            innerImg={bannerImage}
          />
          <section className="generic--page-content ">
            <div className="generic--section technology--section no-pad-bottom">
              <Container>
                <div className="generic--container technology--container no-after">
                  <Row className="justify-content-between">
                    <Col>
                      <h3>What is Microsoft 365?</h3>
                      <p>
                        Microsoft 365 is designed to help you achieve more with
                        innovative Office apps, intelligent cloud services, and
                        world-class security. With a subscription to Microsoft
                        365, you can get:
                      </p>
                      <ul>
                        <li>
                          The latest Office apps, like Word, Excel, Powerpoint,
                          and Outlook
                        </li>
                        <li>
                          The ability to install on PCs, Macs, tablets, and
                          phones
                        </li>
                        <li>Cloud Storage via OneDrive</li>
                        <li>Feature updates and upgrades not available</li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
          <section className="generic--page-content bg-blue pad-mob">
            <div className="generic--section">
              <Container>
                <div className="container-white text-center">
                  <Row className="justify-content-between">
                    <Col lg="8" className="offset-md-2">
                      <h3>Microsoft 365 Products</h3>
                      <p>
                        Turn ideas into solutions with services to build,
                        deploy, and manage applications—in the cloud, onprem,
                        and at the edge—using the tools and frameworks of your
                        choice.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
          <section className="generic--page-content">
            <div className="generic--section overlap-top">
              <Container>
                <div className="generic--container">
                  <Row>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={teams}
                        cardTitle="Microsoft Teams"
                        content={
                          <p className="text-center">
                            A modern business communication platform for chat,
                            video conferencing, file storage, and integration.
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={sharepoint}
                        cardTitle="Microsoft Sharepoint"
                        content={
                          <p className="text-center">
                            Share and manage content, knowledge, and
                            applications to empower teamwork, quickly find
                            information, and seamlessly collaborate
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={outlook}
                        cardTitle="Microsoft Outlook"
                        content={
                          <p className="text-center">
                            Work efficiently with email, calendar, contacts,
                            tasks and more — together in one place.
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={oneDrive}
                        cardTitle="Microsoft OneDrive"
                        content={
                          <p className="text-center">
                            Connect all your files in Office 365 so you can
                            securely share and work together from anywhere
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={oneNote}
                        cardTitle="Microsoft OneNote"
                        content={
                          <p className="text-center">
                            Take digital notes that help you organize your
                            thoughts, make revisions, and collaborate with
                            others.
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={powerPoint}
                        cardTitle="Microsoft PowerPoint"
                        content={
                          <p className="text-center">
                            Create beautiful and compelling presentations with
                            the latest tools and features
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={word}
                        cardTitle="Microsoft Word"
                        content={
                          <p className="text-center">
                            Collaborate in real time, transform documents into
                            web pages, and create powerful and accurate writing
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={excel}
                        cardTitle="Microsoft Excel"
                        content={
                          <p className="text-center">
                            Get a better picture of your data and generate
                            insights to help you make better decisions
                          </p>
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
