import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import './key-points.module.scss';

export interface KeyPointsProps {
  listTitle?: string;
  listItems?: ['11', '22'];
}
class KeyPoints extends Component<KeyPointsProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { listTitle, listItems = [] } = this.props;

    return (
      <div className="list-card">
        <div className="lc-title">{listTitle}</div>
        <ul>
          {listItems.map((value) => (
            <li key={value}>{value}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default KeyPoints;
