import React from 'react';
import './index.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Strings } from '../../../../languages/en';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';

import BannerInner from '../../../components/banner-inner/banner-inner';
import Partners from '../../../components/partners/partners';
import ListCard from '../../../components/list-card/list-card';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/security-cuate.png`;
const dataAnalyticsCol = `${SAPPS_WEB_CDN_FILES_URL}images/data-analytics-col.png`;
const scienceticsCol = `${SAPPS_WEB_CDN_FILES_URL}images/science-col.png`;
const mediaMarketingCol = `${SAPPS_WEB_CDN_FILES_URL}images/social-media-marketing-col.png`;
const informatioRetrievalCol = `${SAPPS_WEB_CDN_FILES_URL}images/information-retrieval-col.png`;

const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/information`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Solutions | Information | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Solutions"
            subheading=""
            heading="Information"
            content="Unify and organize your data to gain insights and make decisions"
            innerImg={bannerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>Information - the DNA of modern oganizations</h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        Discover, manage, examine and gain insights from one of
                        your most valuable resources – data. Organizing the
                        information that is spread across your organization
                        helps you to identify useful patterns in the behaviors
                        of your audience, market trends, and recognize
                        opportunities for exponential growth. With unified and
                        organized data, you can envision the future of your
                        organization and build strategies to equip yourself to
                        meet the future demands of the market and move towards
                        your goals faster.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        Snyder Technologies helps you make the most out of your
                        data. Our AI and machine learning capabilities
                        facilitate data analytics that reveal patterns with
                        which you can regulate your operations, services and
                        execution. With the help of data science, we will help
                        you to optimize your business processes from marketing
                        campaigns to sales funnels with foundations built
                        sturdily on organized, unified data.
                      </p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={dataAnalyticsCol}
                        cardTitle="Data & Analytics"
                        cardLink="/solutions/information/data-analytics"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={scienceticsCol}
                        cardTitle="Data Science"
                        cardLink="/solutions/information/data-science"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={mediaMarketingCol}
                        cardTitle="Marketing Enablement"
                        cardLink="/solutions/information/marketing-enablement"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                    <Col lg="3" md="4" sm="6" xl="3">
                      <ListCard
                        cardImg={informatioRetrievalCol}
                        cardTitle="Information Retrieval"
                        cardLink="/solutions/information/information-retrieval"
                        cardLinkTitle="Learn more"
                      />
                    </Col>
                  </Row>

                  <Partners />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
