import React from 'react';
import Helmet from 'react-helmet';
import { Strings } from '../../../languages/en';
import custom from '../../../gatsby-custom';
import Layout from '../../layout';
import Header from '../../components/header/header';
import About from '../../components/about-us/about-us';
import Footer from '../../components/footer/footer';
import BannerInner from '../../components/banner-inner/banner-inner';

const { SAPPS_WEB_MARKETING_URL } = process.env;
const pageUrl = `${SAPPS_WEB_MARKETING_URL}/about-us`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`About Us | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`About Us | ${custom.siteTitle}`} />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="About"
            subheading="We are a client-obsessed"
            heading="Digital Transformation partner."
            content="In the age of information, we understand the difficult challenges and forces
              that businesses are up against. With our clients, we will connect and create a road
              map for success, providing highly curated solutions that turn their visions into a
              reality."
          />
          <About />
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
