import React from 'react';
import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/workplace.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/collaboration/modern-workplace`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Collaboration | Modern Workplace | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Collaboration | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Collaboration"
            subheading=""
            heading="Modern Workplace"
            content="Transform yourself into a future-ready organization by adopting modern technologies."
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    A set of intelligent tools to promote collaboration and
                    productivity
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        With technology evolving rapidly, the way organizations
                        work has gone through a lot of changes. A hybrid
                        workforce and the concept of remote work have enabled
                        businesses to operate from anywhere. In such a scenario,
                        an efficient work platform has become indispensable for
                        organizations to stay competitive. Technologies such as
                        AI, automation, and collaborative tools allow employees
                        to communicate, share files, and work together
                        seamlessly while providing robust security.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        Snyder Technologies offers best-in-class solutions to
                        modernize your workplace to get the most out of your
                        work. We believe that any great work requires great
                        collaboration. And, for collaboration need to be
                        productive, you need the right environment. We help you
                        to come up with strategies to build the right work
                        environment by offering you cloud-enabled collaboration
                        tools that are powerful as well as scalable.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Virtual connections via cloud technology',
                            'Easy implementation and management',
                            'Easy third party integration and support',
                            'Enhanced data security and migration',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Easy migration to the cloud',
                            'Increased collaboration and productivity',
                            'Modern apps and productivity tools',
                            'Reduced costs and increased efficiency',
                            'Data insights for workflow enhancement',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
