import React from 'react';
import './index.module.scss';
import '../index.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Strings } from '../../../../languages/en';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ListCard from '../../../components/list-card/list-card';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/azure.png`;
const azureAD = `${SAPPS_WEB_CDN_FILES_URL}images/azure-active-directory.png`;
const azureAPI = `${SAPPS_WEB_CDN_FILES_URL}images/API-management-services.png`;
const azureArc = `${SAPPS_WEB_CDN_FILES_URL}images/front-doors.png`;
const azureBlob = `${SAPPS_WEB_CDN_FILES_URL}images/blob-block.png`;
const azureCosmos = `${SAPPS_WEB_CDN_FILES_URL}images/azure-cosmos-DB.png`;
const dataFactory = `${SAPPS_WEB_CDN_FILES_URL}images/data-factory.png`;
const dataLake = `${SAPPS_WEB_CDN_FILES_URL}images/data-lake-storage-gen.png`;
const dataBricks = `${SAPPS_WEB_CDN_FILES_URL}images/databricks.png`;
const azureDevOps = `${SAPPS_WEB_CDN_FILES_URL}images/azure-devOps.png`;
const IoTHub = `${SAPPS_WEB_CDN_FILES_URL}images/IoT-hub.png`;
const containerServ = `${SAPPS_WEB_CDN_FILES_URL}images/container-services.png`;
const machineLearning = `${SAPPS_WEB_CDN_FILES_URL}images/machine-learning-service-alt.png`;
const notificationHub = `${SAPPS_WEB_CDN_FILES_URL}images/notification-hubs.png`;
const searchServices = `${SAPPS_WEB_CDN_FILES_URL}images/search-services.png`;
const azureSentinel = `${SAPPS_WEB_CDN_FILES_URL}images/azure-sentinel.png`;
const streamAnalytics = `${SAPPS_WEB_CDN_FILES_URL}images/stream-analytics-jobs.png`;
const azureSynapse = `${SAPPS_WEB_CDN_FILES_URL}images/azure-synapse-analytics.png`;
const visualStudio = `${SAPPS_WEB_CDN_FILES_URL}images/visual-studio-blue.png`;
const sqlServer = `${SAPPS_WEB_CDN_FILES_URL}images/SQL-server.png`;
const vm = `${SAPPS_WEB_CDN_FILES_URL}images/vm.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/technology/microsoft-azure`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Technology | Microsoft Azure | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Technology | Microsoft Azure | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="technology" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Technology"
            subheading=""
            heading="Microsoft Azure"
            content="Prioritize your cloud enablement objectives and speed adoption of the right cloud services for your business"
            innerImg={bannerImage}
          />
          <section className="generic--page-content ">
            <div className="generic--section technology--section no-pad-bottom">
              <Container>
                <div className="generic--container technology--container no-after">
                  <Row className="justify-content-between">
                    <Col>
                      <h3>What is Microsoft Azure?</h3>
                      <p>
                        Microsoft Azure is a highly capable, secure, and
                        ever-evolving set of cloud services to help your
                        enterprise achieve growth at scale while simultaneously
                        solving complex business challenges. Build, manage, and
                        deploy applications the way you want using the tools and
                        technologies you love with a platform built for both
                        power and speed. With hybrid cloud capabilities, Azure
                        meets you wherever you are on your digital journey and
                        gives you peace of mind with unmatched security and
                        compliance. From DevOps to advanced analytics to IoT,
                        Azure remains the only cloud solution agile enough to
                        meet each of your enterprise’s unique needs.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
          <section className="generic--page-content bg-blue pad-mob">
            <div className="generic--section">
              <Container>
                <div className="container-white text-center">
                  <Row className="justify-content-between">
                    <Col lg="8" className="offset-md-2">
                      <h3>Microsoft Azure Products</h3>
                      <p>
                        Turn ideas into solutions with services to build,
                        deploy, and manage applications—in the cloud, on-prem,
                        and at the edge—using the tools and frameworks of your
                        choice.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
          <section className="generic--page-content">
            <div className="generic--section overlap-top">
              <Container>
                <div className="generic--container">
                  <Row>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={azureAD}
                        cardTitle="Azure Active Directory"
                        content={
                          <p className="text-center">
                            Synchronize onprem directories and enable single
                            sign-on
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={azureAPI}
                        cardTitle="Azure API Management"
                        content={
                          <p className="text-center">
                            Easily build and consume cloud APIs
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={azureArc}
                        cardTitle="Azure Arc"
                        content={
                          <p className="text-center">
                            Bring Azure services and management to any
                            infrastructure
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={azureBlob}
                        cardTitle="Azure Blob Storage"
                        content={
                          <p className="text-center">
                            REST-based object storage for unstructured data
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={azureCosmos}
                        cardTitle="Azure Cosmos DB"
                        content={
                          <p className="text-center">
                            Globally distributed, multi-model database for any
                            scale
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={dataFactory}
                        cardTitle="Azure Data Factory"
                        content={
                          <p className="text-center">
                            Hybrid data integration at enterprise scale, made
                            easy
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={dataLake}
                        cardTitle="Azure Data Lake Analytics"
                        content={
                          <p className="text-center">
                            Data Lake Analytics distributed analytics service
                            that makes big data easy
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={dataBricks}
                        cardTitle="Azure Databricks"
                        content={
                          <p className="text-center">
                            Fast, easy, and collaborative Apache Spark-based
                            analytics platform
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={azureDevOps}
                        cardTitle="Azure DevOps"
                        content={
                          <p className="text-center">
                            Services for teams to share code, track work, and
                            ship software
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={IoTHub}
                        cardTitle="Azure IoT Hub"
                        content={
                          <p className="text-center">
                            Connect, monitor, and manage billions of IoT assets
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={containerServ}
                        cardTitle="Azure Kubernetes"
                        content={
                          <p className="text-center">
                            Simplify the deployment, management, and operations
                            of Kubernetes
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={machineLearning}
                        cardTitle="Azure Machine Learning"
                        content={
                          <p className="text-center">
                            Bring AI to everyone with an end-to-end, scalable,
                            and trusted platform with experimentation &amp;
                            model management
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={notificationHub}
                        cardTitle="Azure Notification Hub"
                        content={
                          <p className="text-center">
                            Send push notifications to any platform from any
                            backend
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={searchServices}
                        cardTitle="Azure Cognitive Search"
                        content={
                          <p className="text-center">
                            AI-powered cloud search service for mobile and web
                            app development
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={azureSentinel}
                        cardTitle="Azure Sentinel"
                        content={
                          <p className="text-center">
                            Put cloud-native SIEM and intelligent security
                            analytics to work to help protect your enterprise
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={streamAnalytics}
                        cardTitle="Azure Stream Analytics"
                        content={
                          <p className="text-center">
                            Real-time data stream processing from millions of
                            IoT devices
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={azureSynapse}
                        cardTitle="Azure Synapse"
                        content={
                          <p className="text-center">
                            Limitless analytics service with unmatched time to
                            insight (formerly SQL Data Warehouse)
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={visualStudio}
                        cardTitle="Visual Studio"
                        content={
                          <p className="text-center">
                            The powerful and flexible environment for developing
                            applications in the cloud
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={sqlServer}
                        cardTitle="Azure SQL Database"
                        content={
                          <p className="text-center">
                            Managed, intelligent SQL in the cloud
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={vm}
                        cardTitle="Virtual Machines"
                        content={
                          <p className="text-center">
                            Provision Windows and Linux virtual machines in
                            seconds
                          </p>
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
