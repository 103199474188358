import React from 'react';
import './index.module.scss';
import '../index.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ListCard from '../../../components/list-card/list-card';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/syccure.png`;
const pageDescription =
  'Control who has access to what within your organization and enhance your network security.';
const pageTitle = 'Snyder Products';

const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/technology/snyder-products`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Technology | ${pageTitle} | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={pageDescription} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Technology | ${pageTitle} | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="technology" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Technology"
            subheading=""
            heading="Cyber Security with Syccure"
            content={pageDescription}
            innerImg={bannerImage}
          />
          <section className="generic--page-content ">
            <div className="generic--section technology--section no-pad-bottom">
              <Container>
                <div className="generic--container technology--container no-after">
                  <Row className="justify-content-between">
                    <Col>
                      <h3>
                        Filling the security void in hybrid virtual networks
                      </h3>
                      <p>
                        Snyder Technologies offers you an end-to-end set of
                        cyber security solutions in collaboration with Syccure
                        Inc. We address and fill the security gaps in
                        highly-sophisticated virtual networks through the
                        creation of a centralized governance over the accesses
                        and enforcement of security policies. This way we ensure
                        that every IP-enabled device in your organization is
                        encrypted and security is strengthened across every
                        layer of the networks. The SaaS-based cyber security
                        solutions we offer are on-demand and cost-effective, so
                        that you invest in the tools only you need the most.
                      </p>
                      <p>
                        Learn more about how to secure your virtual environment
                        in <a href="https://syccure.com">Syccure Inc.</a>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
