import React from 'react';

import './index.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <div>
      <h1>Welcome to index!</h1>
    </div>
  );
}

export default Index;
