import React from 'react';

import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/system-img.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/operational-excellence/managed-services`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Operational Excellence | Managed Services | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="OPERATIONAL EXCELLENCE"
            subheading=""
            heading="Managed Services"
            content="We take care of your IT infrastructure and systems so that you can focus on your business."
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Managed services – a proactive IT support round-the-clock.
                  </h3>
                  <Row className="justify-content-between">
                    <Col md="12" lg="6">
                      <p>
                        Since the emergence of Application Service Providers
                        (ASPs) in the 1990s, the idea of offering IT solutions
                        and support over the cloud has boomed and it never
                        stopped. While managed services initially started as
                        remote monitoring and management (RMM) of networks and
                        servers, it has evolved since then. Being a reliable
                        Microsoft managed services partner, Snyder Technologies
                        offers you end-to-end managed services for the entire
                        suite of Microsoft products. This includes services to
                        ERP, CRM, IoT, and enterprise mobility among others.
                      </p>
                    </Col>
                    <Col md="12" lg="6">
                      <p>
                        Managed services we offer for Microsoft products
                        comprise monitoring networks and servers, software and
                        hardware solutions, and specialized services such as
                        access governance and data storage. We also provide
                        remote security monitoring, risk assessment, and
                        performance monitoring services for both on-premises and
                        cloud-based IT systems that give you greater visibility
                        on your entire Microsoft ecosystem. By ensuring a safe,
                        secured, and seamless IT infrastructure, we help you
                        focus on your core business operations, without any
                        worries.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Proactive and subscription model',
                            'Solutions for vertical markets in various industries',
                            'Centralized control over the systems',
                            'Seamless integration with existing systems',
                          ]}
                        />
                      </Col>
                      <Col lg="6" sm="12">
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Round-the-clock support for entire solutions',
                            'Enhanced interfaces for better user experience',
                            'Reduced cost of ownership',
                            'Certified professionals and flexible teams',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
