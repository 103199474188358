import React from 'react';
import './index.module.scss';
import '../index.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Strings } from '../../../../languages/en';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ListCard from '../../../components/list-card/list-card';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerImage = `${SAPPS_WEB_CDN_FILES_URL}images/dynamics-365.png`;
const stockMarket = `${SAPPS_WEB_CDN_FILES_URL}images/stock-market.png`;
const sales = `${SAPPS_WEB_CDN_FILES_URL}images/sales.png`;
const support = `${SAPPS_WEB_CDN_FILES_URL}images/azure.png`;
const loudspeaker = `${SAPPS_WEB_CDN_FILES_URL}images/loudspeaker.png`;
const truck = `${SAPPS_WEB_CDN_FILES_URL}images/truck.png`;
const hr = `${SAPPS_WEB_CDN_FILES_URL}images/hr.png`;
const onlineShopping = `${SAPPS_WEB_CDN_FILES_URL}images/online-shopping.png`;

const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/technology/microsoft-dynamics-365`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Technology | Microsoft Dynamics 365 | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Technology | Microsoft Dynamics 365 | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="technology" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Technology"
            subheading=""
            heading="Microsoft Dynamics 365"
            content="Transform your business processes using intelligent applications that work seamlessly together in the cloud"
            innerImg={bannerImage}
          />
          <section className="generic--page-content ">
            <div className="generic--section technology--section no-pad-bottom">
              <Container>
                <div className="generic--container technology--container no-after">
                  <Row className="justify-content-between">
                    <Col>
                      <h3>What is Microsoft Dynamics 365?</h3>
                      <p>
                        Microsoft Dynamics 365 is a cloud-based solution that
                        unifies CRM and ERP capabilities into applications that
                        work seamlessly together and effectively connect your
                        enterprise — from marketing and sales to operations,
                        finance and customer relations. The solution transforms
                        operations by giving everyone—from the design team to
                        call center—access to the data collected in real time,
                        enabling you to build stronger relationships and make
                        better business decisions.
                      </p>
                      <p>Why D365?</p>
                      <ul>
                        <li>Achieve seamless connectivity</li>
                        <li>Realize valuable insights from data</li>
                        <li>Shorten sales cycle</li>
                        <li>Enhance social engagement</li>
                        <li>Improve customer service</li>
                        <li>Deploy and scale easily</li>
                        <li>Increase return on investment</li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
          <section className="generic--page-content bg-blue pad-mob">
            <div className="generic--section">
              <Container>
                <div className="container-white text-center">
                  <Row className="justify-content-between">
                    <Col lg="8" className="offset-md-2">
                      <h3>Microsoft Dynamics Products</h3>
                      <p>
                        Turn ideas into solutions with services to build,
                        deploy, and manage applications—in the cloud, onprem,
                        and at the edge—using the tools and frameworks of your
                        choice.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
          <section className="generic--page-content dynamics--page-content">
            <div className="generic--section overlap-top">
              <Container>
                <div className="generic--container">
                  <Row>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={stockMarket}
                        cardTitle="Finance & Operations "
                        content={
                          <p className="text-center">
                            Accelerates the speed of doing business by helping
                            enterprises make smart decisions, transform
                            processes &amp; enable global growth
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={sales}
                        cardTitle="Sales"
                        content={
                          <p className="text-center">
                            Provides insights to build strong relationships and
                            increase sales performance, all while maximizing
                            productivity
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={support}
                        cardTitle="Customer Service"
                        content={
                          <p className="text-center">
                            Helps brands build advocacy and loyalty by creating
                            effortless service experiences
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={loudspeaker}
                        cardTitle="Marketing"
                        content={
                          <p className="text-center">
                            Brings content, data, and processes together to
                            deliver engaging customer experiences across all
                            touchpoints
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={truck}
                        cardTitle="Field Service"
                        content={
                          <p className="text-center">
                            Empowers companies to improve customer satisfaction,
                            first-time fix rates, and resource productivity
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={hr}
                        cardTitle="Human Resources"
                        content={
                          <p className="text-center">
                            Allows human resources professionals to
                            strategically attract, engage, and optimize talent
                          </p>
                        }
                      />
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xl="4"
                      className="d-flex align-items-stretch"
                    >
                      <ListCard
                        cardImg={onlineShopping}
                        cardTitle="Commerce"
                        content={
                          <p className="text-center">
                            Helps retailers deliver exceptional customer
                            experiences and elevate their brand
                          </p>
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
