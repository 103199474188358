import React from 'react';

import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import custom from '../../../../../gatsby-custom';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import KeyPoints from '../../../../components/key-points/key-points';

import { Strings } from '../../../../../languages/en';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const bannerInnerImg = `${SAPPS_WEB_CDN_FILES_URL}images/automation.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/solutions/information/data-analytics`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet
        title={`Solutions | Innovation | Automation as a Service | ${custom.siteTitle}`}
      >
        {/* General tags */}
        <meta name="description" content={Strings.aboutUs} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Solutions | Information | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={Strings.aboutUs} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="solutions-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Solutions"
            subheading=""
            heading="Automation as a Service (AaaS)"
            content="Focus on what matters by deploying automation in your business."
            innerImg={bannerInnerImg}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>Automate Your Business Process Management</h3>
                  <Row className="justify-content-between">
                    <Col md="6">
                      <p>
                        Automation as a Service (AaaS) helps your organization
                        to evolve by improving the business process management.
                        How? It facilitates your operations by automating
                        repeated and time-consuming tasks so that your team can
                        focus on more complex tasks that require attention.
                        Organizations that bring in AaaS prepare themselves for
                        future industrial demands by letting their people focus
                        on innovation. It brings together a network of devices
                        that are powered by AI and Machine Learning that makes
                        your systems proactive, rather than reactive.
                      </p>
                    </Col>
                    <Col md="6">
                      <p>
                        AaaS also help you gather, manage, and secure the huge
                        amount of data you receive from IoT devices. Data
                        maintenance has always been a major concern and
                        efficient management of data leads to operational
                        efficiency. Because, the more the data, the better the
                        analysis, which in turn contributes to better decisions.
                        Our AaaS-based solutions ensure data security and
                        maintenance while enabling you to take actions that are
                        based on data.
                      </p>
                    </Col>
                  </Row>
                  <div className="list-container">
                    <Row>
                      <Col
                        lg="6"
                        sm="12"
                        className="d-flex align-items-stretch"
                      >
                        <KeyPoints
                          listTitle="Highlights"
                          listItems={[
                            'Data access control',
                            'Data maintenance and storage',
                            'Seamless integration',
                            'Proactive tools',
                          ]}
                        />
                      </Col>
                      <Col
                        lg="6"
                        sm="12"
                        className="d-flex align-items-stretch"
                      >
                        <KeyPoints
                          listTitle="Our Promises"
                          listItems={[
                            'Reduced maintenance costs',
                            'Automated business process management',
                            'Increased agility and delivery speed',
                            'Automated access control and alert system',
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
