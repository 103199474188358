import React from 'react';
import Helmet from 'react-helmet';
import custom from '../../../gatsby-custom';
import Layout from '../../layout';
import Header from '../../components/header/header';
import Contact from '../../components/contact/contact';
import Footer from '../../components/footer/footer';
import BannerInner from '../../components/banner-inner/banner-inner';
// import Layout from '@website-src/layout';
// import Header from '@website-src/components/Header/Header';
// import Contact from '@website-src/components/Contact/Contact';
// import Footer from '@website-src/components/footer/footer';
// import BannerInner from '@website-src/components/BannerInner/BannerInner';

const { SAPPS_WEB_MARKETING_URL } = process.env;
const pageUrl = `${SAPPS_WEB_MARKETING_URL}/contact`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Contact | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta
          name="description"
          content="Contact Us, Big things always start with a conversation. Let’s get to know each other, better."
        />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Contact | ${custom.siteTitle}`} />
        <meta
          property="og:description"
          content="Contact Us, Big things always start with a conversation. Let’s get to know each other, better."
        />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
      </Helmet>
      <div className="banner-white contact">
        <Header />
        <BannerInner
          pageName="CONTACT"
          heading="Big things always start
            with a conversation."
          content="Let’s get to know each other better."
        />
        <Contact />
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
