import React, { Component } from 'react';
import './careers-description.scss';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CareersDescriptionProps {}

class CareersDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="career-desc--page-content">
        <div className="career-desc--job-description">
          <Container>
            <div className="job-content">
              <Row>
                <Col md="12">
                  <h3>Job Responsibilities</h3>
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Tristique vitae dui.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pretium massa sollicitudin.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pretium massa sollicitudin.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Tristique vitae dui.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pretium massa sollicitudin.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pretium massa sollicitudin.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Tristique vitae dui.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pretium massa sollicitudin.
                    </li>
                  </ul>
                </Col>
                <Col md="12" className="mt-4">
                  <h3>Requirements</h3>
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Tristique vitae dui.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pretium massa sollicitudin.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pretium massa sollicitudin.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Tristique vitae dui.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pretium massa sollicitudin.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pretium massa sollicitudin.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Tristique vitae dui.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pretium massa sollicitudin.
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="career-desc--job-description">
          <Container>
            <div className="job-content">
              <Form>
                <Row>
                  <Col md="6">
                    <h3>Applying for?</h3>
                    <Form.Group>
                      <Form.Label className="d-none">iOS Developer</Form.Label>
                      <Form.Control as="select">
                        <option>iOS Developer</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <h3>Attach your Résumé</h3>
                    <Form.Group className="attchment">
                      <i className="fa fa-paperclip" />
                      {/*
                      <Form.File
                        id="exampleFormControlFile1"
                        label="Attach your Résumé/CV"
                      />
                      */}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <h3>Personal Information</h3>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="f_name">
                      <Form.Label className="d-none">First Name</Form.Label>
                      <Form.Control type="text" placeholder="First Name" />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="l_name">
                      <Form.Label className="d-none">Last Name</Form.Label>
                      <Form.Control type="text" placeholder="Last Name" />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="phone">
                      <Form.Label className="d-none">Phone Number</Form.Label>
                      <Form.Control type="number" placeholder="Phone Number" />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="email">
                      <Form.Label className="d-none">Email ID</Form.Label>
                      <Form.Control type="emial" placeholder="Email ID" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <h3>Current Status</h3>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="company">
                      <Form.Label className="d-none">
                        Current Company
                      </Form.Label>
                      <Form.Control type="text" placeholder="Current Company" />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="notice">
                      <Form.Label className="d-none">Notice Period</Form.Label>
                      <Form.Control type="emial" placeholder="Notice Period" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="border">Submit</Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Container>
        </div>
      </section>
    );
  }
}

export default CareersDescription;
