import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ServicesBenefits from '../../../components/services-benefits/services-benefits';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/website-development.png`;
const BenefitsImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/website-development-imgs.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/services/website-development`;
const pageDescription =
  'Give your business an online presence to serve your audience better.';
const pageTitle = 'Website Development';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Services | ${pageTitle} | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={pageDescription} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Services | ${pageTitle} | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="services-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Services"
            subheading=""
            heading={pageTitle}
            content={pageDescription}
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Reach your audience where they already spend their time –
                    the internet.
                  </h3>
                  <Row className="justify-content-between">
                    <Col>
                      <p>
                        Snyder Technologies helps you create an online presence
                        for your business through thoughtfully crafted websites
                        that are intuitive and reflect your brand ideology. We
                        believe that businesses have varying aspects of
                        hierarchy, organizational culture, and goals. Hence the
                        websites that showcase them can never have similar
                        characteristics, nor evoke the same feelings. And that’s
                        why we focus on the users who are going to view the
                        site. Clearly, the frequency at which your audience
                        engages with you online depends on how intuitive your
                        website is. And, we help you to give your users just
                        that engaging experience.
                      </p>
                    </Col>
                  </Row>
                  <ServicesBenefits
                    image={BenefitsImage}
                    imageAlt={pageTitle}
                    listItems={[
                      'SEO-based website development solutions.',
                      'Based on time-tested UX/UI design principles.',
                      'Built with both markup and programming languages.',
                      'Comes with e-commerce options and network security configuration.',
                    ]}
                  />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
