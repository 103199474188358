import React from 'react';
import './technology.module.scss';
import { Container, Row, Col, Image, Tabs, Tab } from 'react-bootstrap';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TechnologyProps {}

/**
 * @param props
 */
export function Technology(props: TechnologyProps) {
  return (
    <section className="generic--page-content">
      <div className="generic--section technlogy--section">
        <Container>
          <div className="generic--container technology--container">
            <h3>Accelerating your progress with modern technologies</h3>
            <Row className="justify-content-between">
              <Col>
                <p>
                  Building a well-integrated IT ecosystem is essential for
                  achieving business goals. It not only provides a unified
                  platform for operations but also enables seamless
                  communication and collaboration between your teams, leading to
                  innovation. We build and deliver application-specific
                  solutions for various needs in big data, IoT, CRM, ERP, among
                  others. We also support and manage a range of solutions from
                  Microsoft such as Microsoft Azure, Microsoft Power Platform,
                  Microsoft Power Dynamics 365, and Microsoft 365. By standing
                  on the shoulders of the giants, we promise our clients a more
                  secured and transformative ecosystem.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Technology;
