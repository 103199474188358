import React from 'react';
import { Container, Row } from 'react-bootstrap';
import './announcement-callouts.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AnnouncementCalloutsProps {}

/**
 * @param props
 */
export function AnnouncementCallouts(props: AnnouncementCalloutsProps) {
  return (
    <div className="announcementWrapper">
      <Container>
        <h3 className="title">
          <span>OUR SERVICES</span>
          With end-to-end IT solutions, together we can reimagine the future of
          your business.
        </h3>
        <div className="announcementHolder">
          <div className="announcementCalloutSec">
            <Row>
              <div className="col-announcement">
                <div className="announcementCallout">
                  <h6 className="heading-h6">IT Infrastructure Services</h6>
                  <ul>
                    <li>IT Consulting</li>
                    <li>Managed IT Services</li>
                    <li>IT Security Services</li>
                    <li>Cloud Services</li>
                  </ul>
                </div>
              </div>
              <div className="col-announcement">
                <div className="announcementCallout">
                  <h6>Product Development</h6>
                  <div className="two-list">
                    <ul>
                      <li>Software Development</li>
                      <li>Mobile Apps</li>
                      <li>Web Development</li>
                      <li>DevOps</li>
                      <li>AI & ML</li>
                    </ul>
                    <ul>
                      <li>IoT</li>
                      <li>UX/UI Design</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AnnouncementCallouts;
