import './index.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { from } from 'rxjs';
import custom from '../../../../gatsby-custom';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ServicesBenefits from '../../../components/services-benefits/services-benefits';

const { SAPPS_WEB_CDN_FILES_URL } = process.env;
const BannerInnerImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/managed-it-services.png`;
const BenefitsImage = `${SAPPS_WEB_CDN_FILES_URL}images/services/managed-it-services-imgs.png`;
const pageUrl = `${SAPPS_WEB_CDN_FILES_URL}/services/managed-it-services`;
const pageDescription =
  'Focus on things that matter by outsourcing the management of your IT systems.';
const pageTitle = 'Managed IT Services';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndexProps {}

/**
 * @param props
 */
export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`Services | ${pageTitle} | ${custom.siteTitle}`}>
        {/* General tags */}
        <meta name="description" content={pageDescription} />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Services | ${pageTitle} | ${custom.siteTitle}`}
        />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={custom.siteName} />
        <meta property="og:image" content="" />
        <body className="services-section" />
      </Helmet>
      <div className="banner-white">
        <Header />
        <div className="home">
          <BannerInner
            pageName="Services"
            subheading=""
            heading={pageTitle}
            content={pageDescription}
            innerImg={BannerInnerImage}
          />
          <section className="generic--page-content">
            <div className="generic--section solutions--section">
              <Container>
                <div className="generic--container solutions--container">
                  <h3>
                    Improve your operations and reduce cost by availing of
                    managed IT services.
                  </h3>
                  <Row className="justify-content-between">
                    <Col>
                      <p>
                        Focus on your core operations and work towards
                        innovation without being concerned about managing your
                        IT infrastructure. Snyder Technologies offers you
                        managed IT services on a proactive basis that, unlike a
                        break-fix model, remotely oversees your infrastructure,
                        anticipates needs, and identifies risks to improve your
                        operations, over the cloud. The remote monitoring and
                        management solutions we offer on a subscription-based
                        model significantly cuts your costs and cover a range of
                        need-specific solutions including remote firewall
                        administration, Security as a Service, business to
                        business integration, among others.
                      </p>
                    </Col>
                  </Row>
                  <ServicesBenefits
                    image={BenefitsImage}
                    imageAlt={pageTitle}
                    listItems={[
                      'Outsourcing your infrastructure management reduces your resource cost.',
                      'Proactive management and maintenance enable operational efficiency.',
                      'Helps you to automate repeated and manual operations.',
                      'Supports scalability and new technology integration.',
                      'Streamlines the workflow and improves predictability.',
                    ]}
                  />
                </div>
              </Container>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
